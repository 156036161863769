import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';

import loading from '../assets/loading.gif';

const StyledFigure = styled.figure`
  > img {
    max-width: 50%;
    display: block;
    margin: 0 auto;

    @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
      max-width: 200px;
    }
  }
`;

const Loading = ({ className }) => {
  const intl = useIntl();

  return (
    <StyledFigure className={className}>
      <img
        src={loading}
        className="not-branded"
        alt={intl.formatMessage({ id: 'common.loading' })}
      />
    </StyledFigure>
  );
};

export default Loading;
