import { useEffect, useRef } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { APP, PLATFORM_TYPE } from './constants';
import { useInitHotjar } from './utils/hooks/useInitHotjar';
import { useInitLivechat } from './utils/hooks/useInitLivechat';
import { compareVersion } from './utils/compareVersion';
import { insertParamUrl } from './utils/insertParamUrl';
import { deleteParamUrl } from './utils/deleteParamUrl';
import { getAgency } from './actions/agency';
import {
  initApplication,
  saveForceUpdateApp,
  savePlatform,
  showNPS,
  updatePrevPath
} from './actions/app';
import { saveReferral } from './Estimate/actions/estimate';
import { flattenMessages, translations } from './translations';
import GlobalStyle from './theme/GlobalStyle';
import defaultTheme from './theme/defaultTheme';
import LoadingLayout from './components/LoadingLayout';
import NpsRating from './components/NpsRating';
import SegmentConsentManager from './components/SegmentConsentManager';
import HelpModal from './components/HelpModal';

export const history = {
  push: () => {},
  replace: () => {},
  goBack: () => {},
  location: {}
};

const stripePromise = loadStripe(window.PUBLIC_KEY_STRIPE);

const App = () => {
  const { language, help: showHelp } = useSelector(state => state.app);
  const { isAuthenticated, isLoading } = useSelector(state => state.access);
  const { referral } = useSelector(state => state.estimate);
  const agency = useSelector(state => state.agency);

  const paramsRefs = useRef(null);
  const prevPathRef = useRef(window.location.pathname);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // we create history object
  history.push = (path, state) => navigate(path, { state });
  history.replace = (path, state) => navigate(path, { state, replace: true });
  history.goBack = () => navigate(-1);
  history.location = location;

  const loadingApp = isLoading || agency.isLoading;

  useEffect(() => {
    !isAuthenticated && dispatch(initApplication());
    const params = new URLSearchParams(window.location.search);
    let platform = params.get('so');
    if (platform) {
      switch (platform.toLowerCase()) {
        case PLATFORM_TYPE.ANDROID.toLowerCase():
          platform = PLATFORM_TYPE.ANDROID;
          break;
        case PLATFORM_TYPE.IOS.toLowerCase():
          platform = PLATFORM_TYPE.IOS;
          break;
        default:
          platform = PLATFORM_TYPE.WEB;
      }
      localStorage.setItem('platform', platform);
      deleteParamUrl('so');
    } else {
      platform = localStorage.getItem('platform') || PLATFORM_TYPE.WEB;
    }
    dispatch(savePlatform(platform));
    const agencyId = params.get('agencyId') || params.get('agency');
    agencyId && dispatch(getAgency(agencyId, params.get('agentId') || params.get('agent')));

    let referral = null;
    if (params.get('refer')) {
      referral = 'refer=' + params.get('refer');
    } else if (params.get('broadcast')) {
      referral = 'broadcast=' + params.get('broadcast');
    }
    const campaigns = params.get('campaigns');
    if (referral || campaigns) {
      dispatch(saveReferral(referral, campaigns));
    }

    let version = params.get('v');
    (version && localStorage.setItem('version', version)) ||
      (version = localStorage.getItem('version'));
    if (
      version &&
      APP[platform]?.version &&
      compareVersion(version, APP[platform].version) === -1
    ) {
      dispatch(saveForceUpdateApp(true));
    }

    const adtractionParam = params.get('at_gd');
    adtractionParam && localStorage.setItem('at_gd', adtractionParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    paramsRefs.current = [];
    agency?.id && (paramsRefs.current['agencyId'] = agency.id);
    agency?.agent && (paramsRefs.current['agentId'] = agency.id);
    referral?.id && (paramsRefs.current[referral.type] = referral.id);
    referral?.campaigns && (paramsRefs.current['campaigns'] = referral.campaigns.join(';'));
  }, [agency, referral]);

  useEffect(() => {
    const updateViewport = h => {
      document.querySelector(':root').style.setProperty('--viewport-height', `${h}px`);
    };
    window.addEventListener('resize', () => updateViewport(window.innerHeight));
    updateViewport(window.innerHeight);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isNps = location.pathname.split('/').pop() === 'nps';
    if (isNps) {
      dispatch(showNPS());
      history.replace(location.pathname.replace(/\/nps$/, ''));
      return;
    }

    document.body.scrollIntoView();
    if (paramsRefs.current) {
      const params = new URLSearchParams(window.location.search);
      Object.keys(paramsRefs.current).forEach(key => {
        if (!params.get(key)) {
          insertParamUrl(key, paramsRefs.current[key]);
        }
      });
    }

    prevPathRef.current &&
      prevPathRef.current !== window.location.pathname &&
      dispatch(updatePrevPath(prevPathRef.current));
    prevPathRef.current = window.location.pathname;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useInitHotjar();
  useInitLivechat();

  return (
    <IntlProvider locale={language} messages={flattenMessages(translations[language])}>
      {window.SEGMENT_ID && <SegmentConsentManager />}
      <ThemeProvider theme={{ ...defaultTheme, ...agency.customTheme }}>
        <GlobalStyle theme={{ ...defaultTheme, ...agency.customTheme }} />
        <Elements
          stripe={stripePromise}
          options={{
            locale: language,
            fonts: [
              {
                cssSrc: 'https://fonts.googleapis.com/css?family=Poppins'
              }
            ]
          }}
        >
          {loadingApp && <LoadingLayout />}
          {!loadingApp && <Outlet />}
          {!loadingApp && <NpsRating />}
          {showHelp && <HelpModal />}
        </Elements>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default App;
