import styled, { css } from 'styled-components/macro';

import { ReactComponent as Loader } from '../assets/icons/loader.svg';

const StyledButton = styled.button`
  position: relative;
  font-family: ${({ theme }) => theme.global.fontFamily};
  font-size: ${({ theme }) => theme.global.fontSize};
  line-height: ${({ theme }) => theme.global.lineHeight};
  color: ${({ theme, variant }) =>
    variant === 'danger' || variant === 'warning'
      ? theme.colors.errorText
      : variant === 'clean'
      ? theme.colors.text
      : variant === 'secondary'
      ? theme.buttons.secondary.color
      : theme.buttons.primary.color};

  background: ${({ theme, isLoading, variant }) =>
    isLoading && variant === 'primary'
      ? theme.colors.gray3
      : variant === 'clean' ||
        variant === 'danger' ||
        variant === 'secondary' ||
        variant === 'warning'
      ? theme.buttons.secondary.background
      : theme.buttons.primary.background};

  opacity: ${({ disabled, isLoading }) => (disabled && !isLoading ? 0.5 : 1)};
  cursor: ${({ disabled, isLoading }) => (disabled || isLoading ? 'not-allowed' : 'pointer')};
  border-radius: ${({ theme }) => theme.shapes.borderRadiusLarge};

  min-height: 57px;
  padding: ${({ theme }) => theme.spacings.small3};
  display: block;
  width: ${({ block }) => (block ? '100%' : 'auto')};
  text-transform: uppercase;
  transition: ${({ theme }) => theme.global.transitionM};

  ${({ theme, variant, isLoading }) =>
    isLoading && variant === 'primary'
      ? css`
          border: 2px solid ${theme.colors.gray3};
        `
      : variant === 'danger'
      ? css`
          border: 2px solid ${theme.colors.error};
        `
      : variant === 'secondary'
      ? css`
          border: 2px solid ${theme.buttons.secondary.color};
        `
      : css`
          border: 2px solid transparent;
        `};

  ${({ variant, theme }) =>
    variant !== 'danger' &&
    variant !== 'clean' &&
    variant !== 'secondary' &&
    variant !== 'warning' &&
    css`
      box-shadow: 0px 8px 9px ${theme.buttons.primary.background}40;
    `};

  ${({ isLoading }) =>
    isLoading &&
    css`
      span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 30px;
      }
    `};

  ${({ clean }) =>
    clean &&
    css`
      border: 0;
    `};

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
        &:not(:disabled):hover {
          background: ${({ theme }) => theme.buttons.primary.backgroundHover};
        }
    `};

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
        &:not(:disabled):hover {
          border-color: ${({ theme }) => theme.colors.text};
          color: ${({ theme }) => theme.colors.text};
        }
    `};

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    min-height: 63px;
    margin: auto;
    width: auto;
    min-width: ${({ theme }) => theme.global.maxDesktopWidth};
    font-size: 18px;
  }
`;
const StyledContent = styled.span``;

const Button = ({ children, disabled, isLoading = false, variant = 'primary', ...props }) => (
  <StyledButton {...props} disabled={disabled || isLoading} isLoading={isLoading} variant={variant}>
    <StyledContent>{isLoading ? <Loader /> : children}</StyledContent>
  </StyledButton>
);

export default Button;
