import { PETS, PRODUCT_TYPES } from '../constants';
import { ReactComponent as Car } from '../assets/icons/car.svg';
import { ReactComponent as FemaleDog } from '../assets/icons/dog_female.svg';
import { ReactComponent as FemaleDogSelected } from '../assets/icons/dog_female_selected.svg';
import { ReactComponent as MaleDog } from '../assets/icons/dog_male.svg';
import { ReactComponent as MaleDogSelected } from '../assets/icons/dog_male_selected.svg';
import { ReactComponent as FemaleCat } from '../assets/icons/cat_female.svg';
import { ReactComponent as FemaleCatSelected } from '../assets/icons/cat_female_selected.svg';
import { ReactComponent as MaleCat } from '../assets/icons/cat_male.svg';
import { ReactComponent as MaleCatSelected } from '../assets/icons/cat_male_selected.svg';
import { ReactComponent as House } from '../assets/home.svg';
import { ReactComponent as Life } from '../assets/icons/life.svg';
import { ReactComponent as SmallCar } from '../assets/icons/car_small.svg';
import { ReactComponent as SmallPet } from '../assets/icons/dog_fingerprint_small.svg';
import { ReactComponent as SmallHouse } from '../assets/icons/house_small.svg';
import { ReactComponent as SmallLife } from '../assets/icons/life_small.svg';

/**
 * The format of the policy object that is supposed to be passed to this function is
 * the reduced policy object that is returned by the API in the policy/proposal list
 *
 * @param {object} policy: policy object
 * @returns the options to get the icon of that policy
 */
export const getIconOptions = policy => {
  switch (policy?.business_line) {
    case PRODUCT_TYPES.PETS:
      return {
        type: policy.policy_extra?.pet?.type,
        sex: policy.policy_extra?.pet?.sex
      };
    default:
      return {};
  }
};

export const getPetIcon = (type, sex = PETS.SEX.MALE, selected = false) => {
  switch (true) {
    case type === PETS.TYPE.CAT && sex === PETS.SEX.FEMALE:
      return selected ? FemaleCatSelected : FemaleCat;
    case type === PETS.TYPE.CAT && sex === PETS.SEX.MALE:
      return selected ? MaleCatSelected : MaleCat;
    case type === PETS.TYPE.DOG && sex === PETS.SEX.FEMALE:
      return selected ? FemaleDogSelected : FemaleDog;
    case type === PETS.TYPE.DOG && sex === PETS.SEX.MALE:
    default:
      return selected ? MaleDogSelected : MaleDog;
  }
};

export const getPolicyIcon = (product, options = { small: false }) => {
  switch (product) {
    case PRODUCT_TYPES.PETS:
      const PetIcon = getPetIcon(options.type, options.sex);
      return !options.small ? <PetIcon /> : <SmallPet />;
    case PRODUCT_TYPES.VEHICLE:
      return !options.small ? <Car /> : <SmallCar />;
    case PRODUCT_TYPES.LIFE:
      return !options.small ? <Life /> : <SmallLife />;
    case PRODUCT_TYPES.HOME:
    default:
      return !options.small ? <House /> : <SmallHouse />;
  }
};
