import { POLICY_START_LIMIT, TIMES_IN_MS } from '../constants';
import { getStringAsDate, padTo2Digits } from '.';

export const getPolicyAge = date => {
  const policyDate = new Date(date);
  const now = new Date();
  const diff = Math.abs(now.getTime() - policyDate.getTime());

  return Math.floor(diff / (1000 * 60 * 60 * 24));
};

export const getValidFromDate = (date = new Date()) => {
  date.setDate(date.getDate() + 1);

  return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join(
    '-'
  );
};

export const isPolicyDateInvalid = (valid_from, businessLine) => {
  const date = getStringAsDate(valid_from);
  let isInvalid = 'policy.starting_date_error';

  if (date) {
    const time = date.getTime();
    const minTime = getStringAsDate(getValidFromDate());
    const maxTime = Date.now() + TIMES_IN_MS[POLICY_START_LIMIT[businessLine]];

    if (time >= minTime && time <= maxTime) {
      isInvalid = false;
    } else {
      isInvalid =
        time < minTime
          ? 'policy.starting_date_min_error'
          : `policy.starting_date_max_error_${POLICY_START_LIMIT[businessLine]?.toLowerCase()}`;
    }
  }

  return isInvalid;
};
