import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import loadable from '@loadable/component';

import App from '../App';
import { DEFAULT_STEP, PRODUCT_TYPES, PRODUCT_TYPE_LABELS } from '../constants';
import PATHS from './paths';

const Access = loadable(() => loadableRetry(() => import('../Access/containers/Access')));
const Base = loadable(() => loadableRetry(() => import('../containers/Base')));
// *** old Checkout ***
const Checkout = loadable(() => loadableRetry(() => import('../Checkout/containers/Checkout')));
const Confirmation = loadable(() =>
  loadableRetry(() => import('../Checkout/containers/Confirmation'))
);
const Sign = loadable(() => loadableRetry(() => import('../Checkout/containers/Sign')));
const Success = loadable(() => loadableRetry(() => import('../Checkout/containers/Success')));
const NeedHelpToCancel = loadable(() =>
  loadableRetry(() => import('../Checkout/containers/NeedHelpToCancel'))
);
const PetChip = loadable(() => loadableRetry(() => import('../Checkout/containers/PetChip')));
const CustomerAddress = loadable(() =>
  loadableRetry(() => import('../Checkout/containers/CustomerAddress'))
);
// *** new Checkout ***
const CheckoutLayout = loadable(() => loadableRetry(() => import('../CheckoutNew/containers/CheckoutLayout/CheckoutLayout')));
const CheckoutReviewDate = loadable(() => loadableRetry(() => import('../CheckoutNew/containers/CheckoutReviewDate/CheckoutReviewDate')));
const CheckoutReviewInfo = loadable(() => loadableRetry(() => import('../CheckoutNew/containers/CheckoutReviewInfo/CheckoutReviewInfo')));
const CheckoutNeedHelpToCancel = loadable(() => loadableRetry(() => import('../CheckoutNew/containers/CheckoutNeedHelpToCancel/CheckoutNeedHelpToCancel')));
const CheckoutCustomerAddressConfirm = loadable(() => loadableRetry(() => import('../CheckoutNew/containers/CheckoutCustomerAddressConfirm/CheckoutCustomerAddressConfirm')));
const CheckoutCustomerAddressNew = loadable(() => loadableRetry(() => import('../CheckoutNew/containers/CheckoutCustomerAddressNew/CheckoutCustomerAddressNew')));
const CheckoutPetChip = loadable(() => loadableRetry(() => import('../CheckoutNew/containers/CheckoutPetChip/CheckoutPetChip')));
// ********************
const Estimate = loadable(() => loadableRetry(() => import('../Estimate/containers/Estimate')));
const FastTrack = loadable(() => loadableRetry(() => import('../Estimate/containers/FastTrack')));
const Home = loadable(() => loadableRetry(() => import('../Home/containers/Home')));
const LoadingLayout = loadable(() => loadableRetry(() => import('../components/LoadingLayout')));
const Otp = loadable(() => loadableRetry(() => import('../Access/containers/Otp')));
const PageNotFound = loadable(() => loadableRetry(() => import('../containers/PageNotFound')));
const Policy = loadable(() => loadableRetry(() => import('../Policy/containers/Policy')));
const PolicySign = loadable(() => loadableRetry(() => import('../Policy/containers/PolicySign')));
const PolicyAddValuableItems = loadable(() =>
  loadableRetry(() => import('../Policy/containers/PolicyAddValuableItems'))
);
const PolicyCessionLetter = loadable(() =>
  loadableRetry(() => import('../Policy/containers/PolicyCessionLetter'))
);
const PolicyOppositionForm = loadable(() => import('../Policy/containers/PolicyOppositionForm'));
const PolicyMedicalHistory = loadable(() => import('../Policy/containers/PolicyMedicalHistory'));
const PolicyEdit = loadable(() => import('../Policy/containers/PolicyEdit'));
const PrivateRoute = loadable(() => loadableRetry(() => import('./PrivateRoute')));
const RedirectRoute = loadable(() => loadableRetry(() => import('./RedirectRoute')));
const Receipts = loadable(() => loadableRetry(() => import('../containers/Receipts')));
const ReceiptsDetail = loadable(() => loadableRetry(() => import('../containers/ReceiptsDetail')));
const Ngos = loadable(() => loadableRetry(() => import('../containers/Ngos')));
const VetsPhone = loadable(() => loadableRetry(() => import('../containers/VetsPhone/VetsPhone')));
const NgoDetail = loadable(() => loadableRetry(() => import('../containers/NgoDetail')));
const Welcome = loadable(() => loadableRetry(() => import('../containers/Welcome')));
const Claims = loadable(() => loadableRetry(() => import('../containers/Claims')));
const Settings = loadable(() => loadableRetry(() => import('../containers/Settings')));
const PaymentMethods = loadable(() => loadableRetry(() => import('../containers/PaymentMethods')));
const Referrals = loadable(() => loadableRetry(() => import('../containers/Referrals')));
const Invitations = loadable(() => loadableRetry(() => import('../containers/Invitations')));
const ClaimNew = loadable(() => loadableRetry(() => import('../Claim/containers/ClaimNew')));
const ClaimDetail = loadable(() => loadableRetry(() => import('../Claim/containers/ClaimDetail')));
const ClaimMultimedia = loadable(() =>
  loadableRetry(() => import('../Claim/containers/ClaimMultimedia'))
);
const ClaimDetailStep = loadable(() =>
  loadableRetry(() => import('../Claim/containers/ClaimDetailStep'))
);
const ClaimCall = loadable(() => loadableRetry(() => import('../Claim/containers/ClaimCall')));
const ClaimUrgent = loadable(() => loadableRetry(() => import('../Claim/containers/ClaimUrgent')));
const ClaimType = loadable(() => loadableRetry(() => import('../Claim/containers/ClaimType')));
const Help = loadable(() => loadableRetry(() => import('../containers/Help')));
const UpdateApp = loadable(() => loadableRetry(() => import('../containers/UpdateApp')));
const Maintenance = loadable(() => loadableRetry(() => import('../containers/Maintenance')));
const DownloadApp = loadable(() => loadableRetry(() => import('../containers/DownloadApp')));

const loadableRetry = componentImport => {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem('loadable-refreshed') || 'false');
    componentImport()
      .then(component => {
        window.sessionStorage.setItem('loadable-refreshed', 'false');
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem('loadable-refreshed', 'true');
          return window.location.reload();
        }
        reject(error);
      });
  });
};

const getRoutes = (maintenance, forceUpdateApp) => {
  const rootPath = { path: '/', element: <App /> };

  if (maintenance) {
    return [{
      ...rootPath,
      children: [
        { path: PATHS.maintenance, element: <Maintenance /> },
        { path: '*', element: <RedirectRoute to={PATHS.maintenance} /> },
      ]
    }];
  }

  if (forceUpdateApp) {
    return [{
      ...rootPath,
      children: [
        { path: PATHS.help, element: <Help /> },
        { path: PATHS.updateApp, element: <UpdateApp /> },
        { path: '*', element: <RedirectRoute to={PATHS.updateApp} /> }
      ]
    }];
  }

  return [{
    ...rootPath,
    children: [
      { path: PATHS.welcome, element: <Welcome /> },
      { path: PATHS.home, element: <PrivateRoute component={Home} /> },
      { path: PATHS.homeProduct, element: <PrivateRoute component={Home} /> },
      { path: PATHS.base, element: <PrivateRoute component={Base} /> },
      { path: PATHS.settings, element: <PrivateRoute component={Settings} /> },
      { path: PATHS.paymentMethods, element: <PrivateRoute component={PaymentMethods} /> },
      { path: PATHS.paymentMethodDetail, element: <PrivateRoute component={PaymentMethods} /> },
      { path: PATHS.referrals, element: <PrivateRoute component={Referrals} /> },
      { path: PATHS.invitations, element: <PrivateRoute component={Invitations} /> },
      { path: PATHS.claim, element: <PrivateRoute component={ClaimNew} /> },
      { path: PATHS.claimCall, element: <PrivateRoute component={ClaimCall} /> },
      { path: PATHS.claimUrgent, element: <PrivateRoute component={ClaimUrgent} /> },
      { path: PATHS.claimHandyman, element: <PrivateRoute component={ClaimCall} /> },
      { path: PATHS.claimType, element: <PrivateRoute component={ClaimType} /> },
      { path: PATHS.claimDetail, element: <PrivateRoute component={ClaimDetail} /> },
      { path: PATHS.claimMultimedia, element: <PrivateRoute component={ClaimMultimedia} /> },
      { path: PATHS.claimDetailStep, element: <PrivateRoute component={ClaimDetailStep} /> },
      { path: PATHS.access, element: <Access /> },
      { path: PATHS.accessRedirect, element: <Access /> },
      { path: PATHS.otp, element: <Otp /> },
      { path: PATHS.proposal, element: <PrivateRoute component={Policy} /> },
      { path: PATHS.policy, element: <PrivateRoute component={Policy} /> },
      { path: PATHS.proposalSign, element: <PolicySign /> },
      { path: PATHS.policySign, element: <PolicySign /> },
      { path: PATHS.policyCessionLetter, element: <PrivateRoute component={PolicyCessionLetter} /> },
      { path: PATHS.policyValuableItems, element: <PrivateRoute component={PolicyAddValuableItems} /> },
      { path: PATHS.policyMedicalHistory, element: <PrivateRoute component={PolicyMedicalHistory} /> },
      { path: PATHS.proposalValuableItems, element: <PrivateRoute component={PolicyAddValuableItems} /> },
      { path: PATHS.policyOppositionForm, element: <PrivateRoute component={PolicyOppositionForm} /> },
      { path: PATHS.proposalEdit, element: <PrivateRoute component={PolicyEdit} /> },
      { path: PATHS.proposalEditInfo, element: <PrivateRoute component={FastTrack} /> },
      { path: PATHS.proposalEditInfoBeneficiary, element: <PrivateRoute component={FastTrack} /> },
      { path: PATHS.policyEdit, element: <PrivateRoute component={PolicyEdit} /> },
      { path: PATHS.claims, element: <PrivateRoute component={Claims} /> },
      { path: PATHS.oldEstimateHome, element: <RedirectRoute to={PATHS.setEstimate(DEFAULT_STEP, PRODUCT_TYPE_LABELS[PRODUCT_TYPES.HOME])} /> },
      { path: PATHS.mainEstimate, element: <Estimate /> },
      { path: PATHS.estimate, element: <Estimate /> },
      { path: PATHS.fastTrack, element: <FastTrack /> },
      { path: PATHS.checkoutSign, element: <PrivateRoute component={Sign} /> },
      { path: PATHS.checkout, element: <PrivateRoute component={Checkout} /> },
      { path: PATHS.checkoutConfirmation, element: <PrivateRoute component={Confirmation} /> },
      { path: PATHS.checkoutSuccess, element: <PrivateRoute component={Success} /> },
      {
        element: <PrivateRoute component={CheckoutLayout} />,
        children: [
          { path: PATHS.checkoutSteps.reviewInfo, element: <CheckoutReviewInfo /> },
          { path: PATHS.checkoutSteps.reviewDate, element: <CheckoutReviewDate /> },
          { path: PATHS.checkoutSteps.needHelpToCancel, element: <CheckoutNeedHelpToCancel /> },
          { path: PATHS.checkoutSteps.customerAddressConfirm, element: <CheckoutCustomerAddressConfirm /> },
          { path: PATHS.checkoutSteps.customerAddressNew, element: <CheckoutCustomerAddressNew /> },
          { path: PATHS.checkoutSteps.petChip, element: <CheckoutPetChip /> },
        ]
      },
      { path: PATHS.needHelpToCancel, element: <PrivateRoute component={NeedHelpToCancel} /> },
      { path: PATHS.petChip, element: <PrivateRoute component={PetChip} /> },
      { path: PATHS.customerAddress, element: <PrivateRoute component={CustomerAddress} /> },
      { path: PATHS.customerAddressStep, element: <PrivateRoute component={CustomerAddress} /> },
      { path: PATHS.ngos, element: <PrivateRoute component={Ngos} /> },
      { path: PATHS.vetsPhone, element: <PrivateRoute component={VetsPhone} /> },
      { path: PATHS.ngoDetail, element: <PrivateRoute component={NgoDetail} /> },
      { path: PATHS.receipts, element: <PrivateRoute component={Receipts} /> },
      { path: PATHS.receiptsDetail, element: <PrivateRoute component={ReceiptsDetail} /> },
      { path: PATHS.help, element: <Help /> },
      { path: PATHS.updateApp, element: <UpdateApp /> },
      { path: PATHS.maintenance, element: <Maintenance /> },
      { path: PATHS.downloadApp, element: <DownloadApp /> },
      { path: PATHS.oldEstimate, element: <RedirectRoute to={PATHS.setEstimate(DEFAULT_STEP, PRODUCT_TYPE_LABELS[PRODUCT_TYPES.HOME])} /> },
      { path: '*', element: <PageNotFound /> },
    ]
  }];
};

const Routes = () => {
  const { forceUpdateApp } = useSelector(state => state.app);
  const maintenance = window.MAINTENANCE;

  const router = useMemo(
    () => createBrowserRouter(getRoutes(maintenance, forceUpdateApp)),
    [forceUpdateApp, maintenance]
  );

  return (
    <RouterProvider router={router} fallbackElement={<LoadingLayout />} />
  );
};

export default Routes;
