import { useContext, useMemo, createContext } from 'react';
import { OpenFeature, InMemoryProvider } from '@openfeature/web-sdk';

const InMemoryContext = createContext();

// eslint-disable-next-line react/prop-types
export const OpenFeatureProvider = ({ providerName, config, options, children }) => {
  // Register your feature flag provider
  const inMemoryProvider = new InMemoryProvider(config);
  OpenFeature.setProvider(providerName, inMemoryProvider);

  const client = OpenFeature.getClient(providerName);
  const value = useMemo(() => ({ client, options }), [client, options]);

  return <InMemoryContext.Provider value={value}>{children}</InMemoryContext.Provider>;
};

export const useInMemoryClient = () => {
  const { client } = useContext(InMemoryContext);
  return client;
};

export const useInMemoryBooleanFlagValue = (flagName, defaultValue) => {
  const client = useInMemoryClient();
  return client.getBooleanValue(flagName, defaultValue);
};
