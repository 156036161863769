import axiosMiddleware from 'redux-axios-middleware';
import * as axios from 'axios';

import { STATUS_CODE } from '../constants';
import { history } from '../App';
import { getSession, GET_SESSION, resetState } from '../Access/actions/access';
import { FAIL } from '../actions/app';
import { isPublicPath } from '../utils/isPublicPath';
import { getLanguage } from '../translations';
import PATHS from '../Routes/paths';

const middlewareConfig = {
  interceptors: {
    request: [
      {
        // Removing this unused variable will brek the function contract
        // eslint-disable-next-line no-unused-vars
        success: function ({ getState, dispatch, getSourceAction }, config) {
          config.headers['Content-Type'] = 'application/json';
          config.headers['Accept-Language'] = getLanguage();
          return config;
        },
        // Removing this unused variable will brek the function contract
        // eslint-disable-next-line no-unused-vars
        error: function ({ getState, dispatch, getSourceAction }, error) {
          return Promise.reject(error);
        }
      }
    ],
    response: [
      {
        // eslint-disable-next-line no-unused-vars
        success: function ({ getState, dispatch, getSourceAction }, res) {
          return Promise.resolve(res);
        },
        // Removing this unused variable will brek the function contract
        // eslint-disable-next-line no-unused-vars
        error: async function ({ getState, dispatch, getSourceAction }, request) {
          let result = request;
          const { response: { status } = {} } = request;
          const path = window.location.pathname;
          let errorMessage = null;

          switch (true) {
            case /^5\d{2}$/.test(String(status)):
              errorMessage = { id: 'common.error_unhandled' };
              break;
            case status === STATUS_CODE.BAD_REQUEST:
              const message = result?.response?.data?.message;
              if (
                message &&
                typeof message === 'string' &&
                message.startsWith('JSON parse error')
              ) {
                errorMessage = { id: 'common.error_unhandled' };
              }
              break;
            case status === STATUS_CODE.NOT_FOUND:
              break;
            case status === STATUS_CODE.UNAUTHORIZED && !isPublicPath(path):
              errorMessage = { id: 'errors.unauthorized_user' };
              if (result?.config?.reduxSourceAction?.type === GET_SESSION) {
                errorMessage = null;
                history.push(PATHS.welcome);
                dispatch(resetState());
              } else {
                const session = await dispatch(getSession());
                if (session.type === `${GET_SESSION}${FAIL}`) {
                  errorMessage = null;
                }
              }
              break;
            default:
              break;
          }

          return Promise.reject({
            ...result,
            response: {
              ...result?.response,
              data: {
                ...result?.response?.data,
                message: errorMessage || result?.response?.data?.message
              }
            }
          });
        }
      }
    ]
  }
};

const apiClient = axios.create({
  baseURL: window.API_BASE_URL,
  withCredentials: true,
  responseType: 'json'
});

const httpClientMiddleware = () => axiosMiddleware(apiClient, middlewareConfig);
export default httpClientMiddleware;
