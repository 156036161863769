import { createGlobalStyle } from 'styled-components/macro';
import reset from './reset';

const GlobalStyle = createGlobalStyle`

  ${reset}

  *::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  *::-webkit-scrollbar-track {
      background-color: #fff;
  }
  *::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 4px solid #fff;
  }
  *::-webkit-scrollbar-button {
      display:none;
  }
  :root{
    --viewport-height: 100vh;
    --header-height: 80px;
  }
  * {
    -webkit-tap-highlight-color: transparent;
  }

  html {
    font-family: ${({ theme }) => theme.global.fontFamily};
    ${({ theme }) => theme.texts.p1}
    height: -webkit-fill-available;
  }

  body {
    background-color: ${({ theme }) => theme.global.bgColor};
    color: ${({ theme }) => theme.global.fontColor};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 18.2px;
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  #root {
      padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }
  div.modal-container {
    overflow: hidden;
    height: 100%;
  }

  .modal-content {
    position: absolute;
    height: auto;
    bottom: 0;
    background-color: white;
    width: 100%;
    max-width: 700px;
    box-shadow: 0px -1px 1px rgba(46, 86, 108, 0.1), 0px -4px 8px rgba(46, 86, 108, 0.15);
    border-radius: 8px 8px 0px 0px;
    text-align: center;
    padding: 0;
    max-height: calc(100vh - 110px);
    overflow: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    .modal-content {
      width: 100%;
      max-height: calc(100vh - 110px);
    }
  }

  ul{
    list-style: none ;
    padding: 0 ;
    margin: 0 ;
  }

  h1 {
    ${({ theme }) => theme.headings.h1};
  }

  h2 {
    ${({ theme }) => theme.headings.h2};
  }

  h3 {
    ${({ theme }) => theme.headings.h3};
  }

  h4 {
    ${({ theme }) => theme.headings.h4};
  }

  h5 {
    ${({ theme }) => theme.headings.h5};
  }

  p {
    ${({ theme }) => theme.texts.p1n};
  }

  .p1n{
    ${({ theme }) => theme.texts.p1n};
  }

  .p2{
    ${({ theme }) => theme.texts.p2};
  }

  .p2b{
    ${({ theme }) => theme.texts.p2b};
  }

  .p3b{
    ${({ theme }) => theme.texts.p3b};
  }

  strong {
    font-family: ${({ theme }) => theme.global.fontFamily};
    ${({ theme }) => theme.texts.p1n}
    font-weight: ${({ theme }) => theme.global.fontWeightBold};
  }

  span,
  label,
  small {
    font-family: ${({ theme }) => theme.global.fontFamily};
  }

  button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    font-weight: ${({ theme }) => theme.global.fontWeightBold};
  }

  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button{
      -webkit-appearance: none;
      margin: 0;
    }

    &:-internal-autofill-selected{
      background-color:  transparent;
    }
  }

  a {
    font-family: ${({ theme }) => theme.global.fontFamily};
    font-weight: ${({ theme }) => theme.global.fontWeightBold};
    text-decoration: none;
    font-style: normal;
    transition: color 300ms ease-in-out;
    color: ${({ theme }) => theme.colors.base};

    &.primary {
      color: ${({ theme }) => theme.colors.base};
      &:visited {
        color: ${({ theme }) => theme.colors.base};
      }
    }

    &.disabled {
      color: ${({ theme }) => theme.colors.gray3};
      &:visited {
        color: ${({ theme }) => theme.colors.gray3};
      }
    }



    &:hover,
    .active {
      color: ${({ theme }) => theme.colors.base};
    }
  }


  // React Calendar

  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    font-family: ${({ theme }) => theme.global.fontFamily};
    line-height: 1.125em;
    color: #19181A;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    font-size: 13px ;
    color: #19181A;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
    color: #19181A;
  }
  .react-calendar__month-view__days__day--weekend {
    /* color: #d10000; */
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 26px;
    font-size: 13px;
    font-weight:  400;
    border-radius: 50% ;

  }
  .react-calendar__tile:disabled {
    background-color: transparent;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    /* background: #ffff76; */
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: ${({ theme }) => theme.colors.base};
    border-radius: 50% ;
    color: white;
    font-weight: bold ;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: ${({ theme }) => theme.colors.base};
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }

`;

export default GlobalStyle;
