export const isCadastreValid = ref => {
  // doc: https://trellat.es/validar-la-referencia-catastral-en-javascript/

  const pesoPosicion = [13, 15, 12, 5, 4, 17, 9, 21, 3, 7, 1];
  const letraDc = 'MQWERTYUIOPASDFGHJKLBZX';

  if (ref === null || ref.length !== 20) {
    return false;
  }
  ref = ref.toUpperCase();

  const cadenaPrimerDC = (ref.substring(0, 7) + ref.substring(14, 18)).toUpperCase();
  const cadenaSegundoDC = (ref.substring(7, 14) + ref.substring(14, 18)).toUpperCase();

  const cadenasDC = [cadenaPrimerDC, cadenaSegundoDC];
  let dcCalculado = '';

  cadenasDC.forEach(cadena => {
    let sumaDigitos = 0;

    cadena.split('').forEach((caracter, posicion) => {
      let valorCaracter = caracter;

      if (caracter >= 'A' && caracter <= 'N') {
        valorCaracter = caracter.charCodeAt() - 64;
      } else if (caracter === 'Ñ') {
        valorCaracter = 15;
      } else if (caracter > 'N') {
        valorCaracter = caracter.charCodeAt() - 63;
      }

      sumaDigitos = (sumaDigitos + valorCaracter * pesoPosicion[posicion]) % 23;
    });

    dcCalculado += letraDc.charAt(sumaDigitos);
  });

  if (dcCalculado !== ref.substring(18, 20)) {
    return false;
  }
  return true;
};
