import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useHotjar from 'react-use-hotjar';

export const useInitHotjar = () => {
  const { isAuthenticated, user } = useSelector(state => state.access);
  const { readyState, initHotjar, identifyHotjar } = useHotjar();

  useEffect(() => {
    if (window.HOTJAR_ID && isAuthenticated !== null) {
      if (!readyState) {
        initHotjar(window.HOTJAR_ID, window.HOTJAR_VERSION || 6);
      }
      identifyHotjar(user?.id || null, {
        userEmail: user?.email,
        userPhone: user?.phone
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);
};
