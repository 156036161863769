import { getErrorMessage } from '../../utils/getErrorMessage';
import { FAIL, RESET, SUCCESS } from '../../actions/app';
import {
  GET_PAYMENT_METHODS,
  SETUP_INTENT,
  UPDATE_PAYMENT_METHOD_DATA,
  RESET_PAYMENT_METHOD,
  UPDATE_NEED_HELP_TO_CANCEL,
  UPDATE_PAYMENT_DAY,
  CLEAN_ERROR,
  PAY_WITH_PAYMENT_METHOD,
  UPDATE_POLICY_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD_CARD,
  CREATE_PAYMENT_METHOD_SEPA,
  RESET_USER_PAYMENTS,
  CONFIRM_CARD_SETUP,
  DELETE_PAYMENT_METHOD
} from '../actions/checkout';
import { getPaymentNumberEnding } from './apiIntern';

export const initialState = {
  isLoading: 0,
  error: null,
  sign_url: null,
  mandate_id: null,
  intent: {},
  payment_method: {},
  payment_day: null,
  needHelpToCancel: null,
  userPayments: [],
  customer_active_payments: []
};

const reducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case SETUP_INTENT:
    case GET_PAYMENT_METHODS:
    case PAY_WITH_PAYMENT_METHOD:
    case CREATE_PAYMENT_METHOD_CARD:
    case CREATE_PAYMENT_METHOD_SEPA:
    case UPDATE_POLICY_PAYMENT_METHOD:
    case CONFIRM_CARD_SETUP:
    case DELETE_PAYMENT_METHOD:
      return {
        ...state,
        isLoading: state.isLoading + 1
      };
    case `${SETUP_INTENT}${SUCCESS}`:
      return {
        ...state,
        intent: payload.data,
        isLoading: state.isLoading - 1
      };
    case `${GET_PAYMENT_METHODS}${SUCCESS}`:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        userPayments: payload.data?.map(payment => {
          return {
            ...payment,
            number_ending: getPaymentNumberEnding(payment),
            customerId: payment.customer_id
          };
        })
      };
    case `${PAY_WITH_PAYMENT_METHOD}${SUCCESS}`:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        sign_url: payload.data.sign_url,
        mandate_id: payload.data.mandate_id,
        payment_day: null
      };
    case `${CREATE_PAYMENT_METHOD_CARD}${SUCCESS}`:
    case `${CREATE_PAYMENT_METHOD_SEPA}${SUCCESS}`:
    case `${DELETE_PAYMENT_METHOD}${SUCCESS}`:
    case `${UPDATE_POLICY_PAYMENT_METHOD}${SUCCESS}`:
    case `${CONFIRM_CARD_SETUP}${SUCCESS}`:
      return {
        ...state,
        isLoading: state.isLoading - 1
      };
    case `${SETUP_INTENT}${FAIL}`:
    case `${GET_PAYMENT_METHODS}${FAIL}`:
    case `${PAY_WITH_PAYMENT_METHOD}${FAIL}`:
    case `${CREATE_PAYMENT_METHOD_CARD}${FAIL}`:
    case `${CREATE_PAYMENT_METHOD_SEPA}${FAIL}`:
    case `${UPDATE_POLICY_PAYMENT_METHOD}${FAIL}`:
    case `${DELETE_PAYMENT_METHOD}${FAIL}`:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        error: getErrorMessage(error)
      };
    case `${CONFIRM_CARD_SETUP}${FAIL}`:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        error: payload?.error?.message ? { message: payload.error.message } : true
      };
    case UPDATE_PAYMENT_METHOD_DATA:
      return {
        ...state,
        payment_method: {
          ...state.payment_method,
          ...payload
        }
      };
    case RESET_PAYMENT_METHOD:
      return {
        ...state,
        payment_method: {}
      };
    case RESET_USER_PAYMENTS:
      return {
        ...state,
        userPayments: []
      };
    case UPDATE_NEED_HELP_TO_CANCEL:
      return {
        ...state,
        needHelpToCancel: payload
      };
    case UPDATE_PAYMENT_DAY:
      return {
        ...state,
        payment_day: payload
      };
    case CLEAN_ERROR:
      return {
        ...state,
        error: null
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
