/* eslint-disable no-undef */
window.gtmSessionProps = window.gtmSessionProps || {};
export const analyticsTrack = (event, data, sessionProps = false) =>
  window.SEGMENT_ID &&
  window.analytics.track(
    event,
    sessionProps ? { ...(data || {}), ...window.gtmSessionProps } : data
  );
export const analyticsIdentify = (id, data) => {
  window.SEGMENT_ID && window.analytics.identify(id, data);
  const message = JSON.stringify({ id });
  try {
    if (typeof Android !== 'undefined') {
      Android.postData(message); // native app interface
    } else if (window?.webkit?.messageHandlers) {
      window.webkit.messageHandlers['scriptHandler'].postMessage(message);
    }
  } catch (e) {
    console.error(e);
  }
};
export const analyticsPage = () => window.SEGMENT_ID && window.analytics.page();
export const analyticsReset = () => {
  window.SEGMENT_ID && window.analytics.reset();
  try {
    if (typeof Android !== 'undefined') {
      Android.logout(); // native app interface
    } else if (window?.webkit?.messageHandlers) {
      window.webkit.messageHandlers['scriptHandler'].postMessage('logout');
    }
  } catch (e) {
    console.error(e);
  }
};
