import { isValidPhoneNumber } from 'react-phone-number-input';
import { BENEFICIARY_TYPE, ID_TYPES } from '../constants';
import { isEmailValid } from './emailValidation';
import { isNifValid } from './isNifValid';

export const isBeneficiaryInfoValid = data => {
  if (!data || !data.btype) return false;

  switch (data.btype) {
    case BENEFICIARY_TYPE.PERSON:
      return (
        data.hasOwnProperty('first_name') &&
        data.first_name?.trim() &&
        data.hasOwnProperty('last_name') &&
        data.last_name?.trim() &&
        data.document_type &&
        data.document_number &&
        isNifValid(data.document_number, data.document_type) &&
        (!data?.email || isEmailValid(data.email)) &&
        (!data?.phone || isValidPhoneNumber(data.phone))
      );
    case BENEFICIARY_TYPE.COMPANY:
      return (
        data?.type &&
        data?.name &&
        data.document_number &&
        isNifValid(data.document_number, ID_TYPES.NIF) &&
        (!data?.email || isEmailValid(data.email)) &&
        (!data?.phone || isValidPhoneNumber(data.phone))
      );
    default:
      return false;
  }
};
