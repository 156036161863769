import { AGE_CONDITIONS } from '../constants';
import { getStringAsDate } from '.';

export const isLegalAge = value => {
  const { LEGAL_AGE, ABOVE_AGE } = AGE_CONDITIONS;

  const date = getStringAsDate(value); // returns null if the date is invalid (not parseable)
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  if (!date) {
    return false;
  }
  return !(
    currentDate.setFullYear(currentYear - LEGAL_AGE) - date < 0 ||
    currentDate.setFullYear(currentYear - ABOVE_AGE) - date > 0
  );
};

export const isValidDateFormat = dateString => {
  const regex = /^\d{4}[/ -]\d{2}[/ -]\d{2}$/;
  return regex.test(dateString);
};
