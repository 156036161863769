import { useEffect } from 'react';
import styled, { css } from 'styled-components/macro';

import Loading from './Loading';

const StyledContainer = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: ${({ theme }) => theme.global.bgColor};
  ${({ transparentBg }) =>
    transparentBg &&
    css`
      background: ${({ theme }) => theme.colors.gray8};
    `};
`;

const LoadingLayout = ({ transparentBg }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  return (
    <StyledContainer transparentBg={transparentBg}>
      <Loading />
    </StyledContainer>
  );
};

export default LoadingLayout;
