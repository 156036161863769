import { VALUE_TYPE } from '../constants';

export const getFullAddressString = address => {
  if (address) {
    const firstAddressLine = `${address.street_type || ''} ${address.street_name || ''} ${
      address.number || ''
    }`;
    let addressDescription = [];
    address.building && addressDescription.push(`Bloque ${address.building}`);
    address.stairway && addressDescription.push(`Escalera ${address.stairway}`);
    address.floor &&
      addressDescription.push(
        `${address.floor}${typeof +address.floor === VALUE_TYPE.NUMBER ? 'º' : ''}`
      );
    address.apartment && addressDescription.push(address.apartment);
    address.zip_code &&
      address.province &&
      addressDescription.push(
        `${addressDescription.length > 0 ? ', ' : ''}${address.zip_code} ${address.province}`
      );
    const secondAddressLine = addressDescription.join(' ');
    return [firstAddressLine, secondAddressLine];
  }
  return null;
};
