import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ReactComponent as User } from '../assets/help_user.svg';
import { ReactComponent as IconFaq } from '../assets/icons/help_faq.svg';
import { ReactComponent as IconPhone } from '../assets/icons/help_phone.svg';
import { ReactComponent as IconWa } from '../assets/icons/help_wa.svg';
import { analyticsTrack } from '../utils/analytics';
import { getPhoneLink } from '../utils/getPhoneLink';
import { useBreakpoint } from '../utils/hooks/useBreakpoint';
import { useHelpPhone } from '../utils/hooks/useHelpPhone';
import { LINKS } from '../constants';
import Popup from '../components/Popup';
import Button from '../components/Button';
import { showHelp } from '../actions/app';
import { history } from '../App';
import PATHS from '../Routes/paths';
import { openLivechat } from '../utils/livechat';
import { openPath } from '../utils/openPath';

const StyledContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    box-shadow: ${({ theme }) => theme.global.boxShadowS};
    max-width: 75%;
    margin: ${({ theme }) => theme.spacings.medium2} auto;
    border-radius: ${({ theme }) => theme.shapes.borderRadiusLarge};
  }
`;

const StyledLink = styled.a`
  ${({ theme }) => theme.texts.p1m}
  cursor: pointer;
  padding: ${({ theme }) => theme.spacings.small3} ${({ theme }) => theme.spacings.small4};
  display: flex;
  gap: ${({ theme }) => theme.spacings.small3};
  align-items: center;
  transition: ${({ theme }) => theme.global.transitionS};
  border-top: ${({ theme }) => theme.shapes.borderWidthSmall} solid
    ${({ theme }) => theme.colors.gray2};
  &:last-child {
    border-bottom: ${({ theme }) => theme.shapes.borderWidthSmall} solid
      ${({ theme }) => theme.colors.gray2};
  }

  &:active,
  &:hover {
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.base};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    &:first-child {
      border-top: none;
      border-top-left-radius: ${({ theme }) => theme.shapes.borderRadiusLarge};
      border-top-right-radius: ${({ theme }) => theme.shapes.borderRadiusLarge};
    }
    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: ${({ theme }) => theme.shapes.borderRadiusLarge};
      border-bottom-right-radius: ${({ theme }) => theme.shapes.borderRadiusLarge};
    }
  }
`;

const StyledIcon = styled(User)`
  max-width: 100px;
`;

const HelpModal = () => {
  const { user } = useSelector(state => state.access);
  const dispatch = useDispatch();
  const isMobile = useBreakpoint() === 's';
  const intl = useIntl();
  const helpNumber = useHelpPhone();
  const ctaType = isMobile ? 'wa' : 'livechat';

  useEffect(() => {
    analyticsTrack('help_viewed');

    const handleHashChange = () => {
      if (!window.location.hash) {
        dispatch(showHelp(false));
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = type => {
    analyticsTrack(`help_${type}_pressed`);
    dispatch(showHelp(false));

    switch (type) {
      case 'wa':
        openPath(LINKS.WHATSAPP, '_blank');
        break;
      case 'livechat':
        const { firstName: name, phone, email } = user;
        openLivechat({ email, phone, name });
        break;
      default:
        break;
    }

    history.replace(PATHS.setHelpModal(false));
  };

  const handleClose = () => {
    analyticsTrack('help_closed');
    dispatch(showHelp(false));
    if (!!matchPath(window.location.pathname, PATHS.help)) {
      history.replace(PATHS.home);
    } else {
      history.goBack();
    }
  };

  return (
    <Popup
      allowClose
      isOpen
      size="medium"
      onRequestClose={handleClose}
      title={intl.formatMessage({ id: 'help.title' })}
      headerImage={<StyledIcon />}
      actions={
        <Button onClick={() => handleClick(ctaType)}>
          <FormattedMessage id={`help.cta.${ctaType}`} />
        </Button>
      }
    >
      <StyledContainer>
        <StyledLink href={LINKS.ZENDESK} target="_blank" onClick={() => handleClick('faq')}>
          <IconFaq />
          <FormattedMessage id="help.options.faq" />
        </StyledLink>
        <StyledLink
          href={getPhoneLink(helpNumber)}
          target="_blank"
          onClick={() => handleClick('phone')}
        >
          <IconPhone />
          <FormattedMessage id="help.options.phone" />
        </StyledLink>
        {!isMobile && (
          <StyledLink href={LINKS.WHATSAPP} target="_blank" onClick={() => handleClick('wa')}>
            <IconWa />
            <FormattedMessage id="help.options.wa" />
          </StyledLink>
        )}
      </StyledContainer>
    </Popup>
  );
};
export default HelpModal;
