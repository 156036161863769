import { analyticsIdentify, analyticsReset } from '../../utils/analytics';
import { isPublicPath } from '../../utils/isPublicPath';
import { onUpdateLanguage, reset, SUCCESS } from '../../actions/app';
import { history } from '../../App';
import PATHS from '../../Routes/paths';

export const ADD_CUSTOMER = '@@ACCESS/ADD_CUSTOMER';
export const CONFIRM_PHONE_CHANGE = '@@ACCESS/CONFIRM_PHONE_CHANGE';
export const GET_SESSION = '@@ACCESS/GET_SESSION';
export const GET_USER_OTP = '@@ACCESS/GET_USER_OTP';
export const INIT_PHONE_CHANGE = '@@ACCESS/INIT_PHONE_CHANGE';
export const RESET_STATE = '@@ACCESS/RESET_STATE';
export const SIGN_IN = '@@ACCESS/SIGN_IN';
export const UPDATE_CUSTOMER = '@@ACCESS/UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_INFO = '@@ACCESS/UPDATE_CUSTOMER_INFO';
export const UPDATE_TAGS = '@@ACCESS/UPDATE_TAGS';
export const UPDATE_USER_INFO = '@@ACCESS/UPDATE_USER_INFO';
export const UPDATE_USER_PHONE = '@@ACCESS/UPDATE_USER_PHONE';
export const LOG_OUT = '@@ACCESS/LOG_OUT';
export const CLEAN_ERROR = 'CLEAN_ERROR';

export const getSession = () => ({
  type: GET_SESSION,
  payload: {
    request: {
      method: 'GET',
      url: '/v2/users/session'
    }
  }
});

export const onLogOut =
  (resetApp = true) =>
  async dispatch => {
    const action = await dispatch(logOut());
    if (action.type === `${LOG_OUT}${SUCCESS}`) {
      if (resetApp) {
        history.push(PATHS.welcome);
        analyticsReset();
        await dispatch(reset());
      } else {
        return true;
      }
    }
  };

export const logOut = () => ({
  type: LOG_OUT,
  payload: {
    request: {
      method: 'POST',
      url: '/users/logout'
    }
  }
});

export const identifyUser = payload => async (_, getState) => {
  const { platform } = getState().app;
  const { referral } = getState().estimate;
  const data = {
    related_user_id: payload.id,
    email_subscribe: true,
    registration_channel: platform,
    initial_referrer: referral.id
  };
  analyticsIdentify(payload.uuid, data);
};

export const checkUserIsLogged =
  (redirect = false) =>
  async (dispatch, getState) => {
    const path = window.location.pathname;
    const params = window.location.search;
    const action = await dispatch(getSession());
    const initialRoutes = [PATHS.base, PATHS.welcome, PATHS.access, PATHS.otp];
    if (action.type === `${GET_SESSION}${SUCCESS}`) {
      const { user } = getState().access;
      user?.lang && dispatch(onUpdateLanguage(user.lang));
      user?.uuid && analyticsIdentify(user.uuid);
      if (!redirect) {
        if (initialRoutes.includes(path)) {
          history.replace(PATHS.home + window.location.search);
        }
      } else {
        history.replace(redirect);
      }
    } else if (!isPublicPath(path)) {
      history.push(PATHS.setAccessRedirect(path) + params);
    }
  };

export const onSendPhone =
  (phone, state = null, goToOtpPage = true) =>
  async dispatch => {
    const action = await dispatch(getUserOtp(phone));
    if (action.type === `${GET_USER_OTP}${SUCCESS}`) {
      goToOtpPage && history.push(PATHS.otp, state);
    }
  };

const getUserOtp = phone => ({
  type: GET_USER_OTP,
  payload: {
    request: {
      method: 'GET',
      url: encodeURI(`/users/otp/${phone}`)
    },
    data: {
      phone
    }
  }
});

export const onSendOtp = (otp, redirect) => async (dispatch, getState) => {
  const phone = getState().access.user.phone;
  const action = await dispatch(signIn(otp, phone));
  if (action.type === `${SIGN_IN}${SUCCESS}`) {
    await dispatch(checkUserIsLogged(redirect));
  }
};

export const signIn = (otp, phone) => ({
  type: SIGN_IN,
  payload: {
    request: {
      method: 'POST',
      url: '/v2/users/signin',
      data: {
        phone,
        otp
      }
    }
  }
});

export const updateUserPhone = phone => ({
  type: UPDATE_USER_PHONE,
  payload: {
    phone
  }
});

export const onUpdateCustomerInfo = (customerId, data) => async dispatch => {
  /* TODO: refactor PersonalInfoForm when Get customers has the same object */
  const formatData = {
    phone: data?.phone || null,
    email: data?.email || null,
    person:
      !data.isCompany &&
      (data?.first_name || data?.last_name || data?.birth_date || data?.document_type)
        ? {
            first_name: data?.first_name || null,
            last_name: data?.last_name || null,
            birth_date: data?.birth_date || null,
            document_person_type: data?.document_type || null
          }
        : null,
    company:
      data.isCompany && (data?.company_name || data?.company_type || data?.document_type)
        ? {
            company_name: data?.company_name || null,
            company_type: data?.company_type || null,
            document_company_type: data?.document_type || null
          }
        : null,
    document_number: data?.document_number || null,
    address: data?.address || null
  };

  const action = await dispatch(updateCustomerInfo(customerId, formatData));
  return action.type === `${UPDATE_CUSTOMER_INFO}${SUCCESS}`;
};

export const updateCustomerInfo = (customerId, data) => {
  return {
    type: UPDATE_CUSTOMER_INFO,
    payload: {
      request: {
        method: 'PUT',
        url: `/v3/customer/${customerId}`,
        data
      }
    }
  };
};

export const onUpdateUser = data => async dispatch => {
  const action = await dispatch(updateUserInfo(data));

  if (action.type === `${UPDATE_USER_INFO}${SUCCESS}`) {
    data.lang && dispatch(onUpdateLanguage(data.lang, true));
    analyticsReset();
    analyticsIdentify(action.payload.data.uuid);
  }
};

export const updateUserInfo = data => ({
  type: UPDATE_USER_INFO,
  payload: {
    request: {
      method: 'PUT',
      url: '/v2/users',
      data
    }
  }
});

export const initPhoneChange = phone => async dispatch => {
  const action = await dispatch({
    type: INIT_PHONE_CHANGE,
    payload: {
      request: {
        method: 'PUT',
        url: '/users/phone/init',
        params: {
          phone
        }
      }
    }
  });

  return action.type === `${INIT_PHONE_CHANGE}${SUCCESS}`;
};

export const onConfirmPhoneChange = (phone, otp) => async dispatch => {
  const action = await dispatch(confirmPhoneChange(phone, otp));

  return action.type === `${CONFIRM_PHONE_CHANGE}${SUCCESS}`;
};

export const confirmPhoneChange = (phone, otp) => ({
  type: CONFIRM_PHONE_CHANGE,
  payload: {
    request: {
      method: 'PUT',
      url: '/users/phone/confirm',
      data: {
        phone,
        otp
      }
    }
  }
});

export const addCustomer = data => ({
  type: ADD_CUSTOMER,
  payload: {
    data
  }
});

export const updateCustomer = customerId => ({
  type: UPDATE_CUSTOMER,
  payload: {
    customerId
  }
});

export const updateTags = tags => ({
  type: UPDATE_TAGS,
  payload: {
    tags
  }
});

export const resetState = () => ({
  type: RESET_STATE
});

export const cleanError = () => ({
  type: CLEAN_ERROR,
  payload: null
});
