import { RECEIPT_STATUS } from '../constants';
import { ReactComponent as Tick } from '../assets/icons/tick_ok_blue.svg';
import { ReactComponent as Cross } from '../assets/icons/red_cross.svg';

export const getReceiptStatusIcon = status => {
  switch (status) {
    case RECEIPT_STATUS.FAILED:
    case RECEIPT_STATUS.DEFAULTED:
      return <Cross />;
    case RECEIPT_STATUS.ISSUED:
    case RECEIPT_STATUS.PENDING:
    case RECEIPT_STATUS.FULLY_PAID:
      return <Tick />;
    default:
      return null;
  }
};
