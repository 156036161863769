import { GET_AGENCY } from '../actions/agency';
import { FAIL, SUCCESS } from '../actions/app';
import { getImageUrl } from '../utils/getImageUrl';
import defaultTheme from '../theme/defaultTheme';
import { getErrorMessage } from '../utils/getErrorMessage';

const formatAgencyInfo = agency => ({
  id: agency?.id || null,
  agent: null,
  phone: agency?.phone || null,
  showPhone: agency?.show_phone || null,
  paymentFrequency: agency?.default_payment_frequency ?? null,
  apply: agency?.customize_onboarding || false,
  name: agency?.name || null,
  type: agency?.agency_type || null,
  font: {
    color: agency?.font_color || null,
    family: agency?.font || null
  },
  colors: {
    primary: agency?.primary_color || null,
    secondary: agency?.secondary_color || null,
    background: agency?.background_color || null,
    backgroundHeader: agency?.menu_background_color || null
  },
  buttons: {
    primary: {
      background: agency?.button_primary_color || null,
      borderColor: agency?.button_primary_border_color || null,
      backgroundHover: agency?.button_primary_hover_color || null
    }
  },
  logo: agency?.logo_id ? getImageUrl({ id: agency.logo_id }) : null,
  favicon: agency?.favicon_id ? getImageUrl({ id: agency.favicon_id }) : null,
  customCss: agency?.custom_css ? agency.custom_css : null,
  customTheme: {}
});

export const initialState = formatAgencyInfo(null);

const reducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case GET_AGENCY:
      return {
        ...initialState,
        isLoading: true,
        agent: payload.data?.agent_id
      };
    case `${GET_AGENCY}${SUCCESS}`:
      const { id, name, phone, show_phone, default_payment_frequency } = payload.data;
      const agency = formatAgencyInfo(
        payload?.data?.customize_onboarding
          ? payload.data
          : { id, name, phone, show_phone, default_payment_frequency }
      );
      return {
        ...state,
        ...agency,
        isLoading: false,
        agent: state?.agent,
        customTheme: overwriteTheme(agency)
      };
    case `${GET_AGENCY}${FAIL}`:
      return {
        ...state,
        agent: null,
        isLoading: false,
        error: getErrorMessage(error)
      };
    default:
      return state;
  }
};

const overwriteTheme = agency => {
  const newTheme = {};
  if (!agency.apply) {
    return newTheme;
  }

  const newFont = agency?.font?.family
    ? `"${agency.font.family}", ${defaultTheme.global.fontFamily}`
    : false;

  newTheme.colors = {
    ...defaultTheme.colors,
    text: agency?.font?.color || defaultTheme.colors.text,
    base: agency?.colors?.primary || defaultTheme.colors.base,
    secondary: agency?.colors?.secondary || defaultTheme.colors.secondary
  };
  newTheme.global = {
    ...defaultTheme.global,
    bgColor: agency?.colors?.background || defaultTheme.global.bgColor,
    bgColorHeader: agency?.colors?.backgroundHeader || defaultTheme.global.bgColorHeader,
    fontColor: agency?.font?.color || defaultTheme.global.fontColor,
    fontFamily: newFont || defaultTheme.global.fontFamily
  };

  if (['#fff', '#ffffff', 'white'].includes(newTheme.global.bgColorHeader)) {
    newTheme.global.bgColorHeader = defaultTheme.colors.white;
  }

  if (newFont) {
    newTheme.headings = {};
    Object.entries(defaultTheme.headings).forEach(entry => {
      newTheme.headings[entry[0]] = { ...entry[1], fontFamily: newFont };
    });
    newTheme.texts = {};
    Object.entries(defaultTheme.texts).forEach(entry => {
      newTheme.texts[entry[0]] = {
        ...entry[1],
        fontFamily: newFont,
        color: newTheme.global.fontColor
      };
    });
  }
  if (
    agency?.buttons?.primary.background ||
    agency?.buttons?.primary.borderColor ||
    agency?.buttons?.primary.backgroundHover
  ) {
    newTheme.buttons = {
      ...defaultTheme.buttons,
      primary: {
        ...defaultTheme.buttons.primary,
        background: agency?.buttons?.primary.background || defaultTheme.buttons.primary.background,
        borderColor:
          agency?.buttons?.primary.borderColor || defaultTheme.buttons.primary.borderColor,
        backgroundHover:
          agency?.buttons?.primary.backgroundHover ||
          agency?.buttons?.primary.background ||
          defaultTheme.buttons.primary.backgroundHover
      }
    };
  }

  return newTheme;
};

export default reducer;
