export const UPDATE_NEED_HELP_TO_CANCEL = '@@CHECKOUT/UPDATE_NEED_HELP_TO_CANCEL';
export const RESET = '@@CHECKOUT/RESET';

export const onUpdateNeedHelpToCancel = payload => ({
  type: UPDATE_NEED_HELP_TO_CANCEL,
  payload
});

export const onReset = () => ({
  type: RESET
});
