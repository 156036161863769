export const showPaymentDayInfo = (paymentDay, validFrom) => {
  if (!paymentDay) {
    return false;
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const startDate = new Date(validFrom);
  startDate.setHours(0, 0, 0, 0);
  const paymentDate = new Date(today);

  paymentDate.setDate(paymentDay);

  if (paymentDay < today.getDate()) {
    paymentDate.setMonth(today.getMonth() + 1);
  }

  return paymentDate < today || paymentDate > startDate;
};
