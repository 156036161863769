const PATHS = {
  access: '/access',
  accessRedirect: '/access/*',
  base: '/',
  checkout: '/checkout',
  checkoutConfirmation: '/checkout/confirmation',
  checkoutSign: '/checkout/sign',
  checkoutSuccess: '/checkout/success',
  checkoutSteps: {
    reviewInfo: '/checkout/review-info',
    reviewDate: '/checkout/review-date',
    needHelpToCancel: '/checkout/help-to-cancel',
    customerAddressNew: '/checkout/new-customer-address',
    customerAddressConfirm: '/checkout/confirm-customer-address',
    paymentMethod: '/checkout/payment-method',
    singProposal: '/checkout/sign-proposal',
    petChip: '/checkout/pet-chip',
  },
  needHelpToCancel: '/help-to-cancel',
  petChip: '/pet-chip',
  oldEstimate: '/chat/estimate/:step',
  oldEstimateHome: '/estimate/house-use',
  mainEstimate: '/estimate/:step',
  estimate: '/estimate/:product/:step',
  estimateSteps: {
    referralWelcome: 'referral-welcome',
    confirmProducts: 'products',
    confirmCustomer: 'confirm-customer',
    customerInfo: 'customer-info',
    personalInformation: 'personal-info',
    loading: 'loading',
    nextProduct: 'next-product',
    home: {
      confirmUse: 'house-use',
      address: 'address',
      confirmAddress: 'confirm-address',
      confirmSecurity: 'security',
      confirmIsFlammable: 'house-materials',
      addressNotFound: 'address-not-found',
      province: 'province',
      municipality: 'municipality',
      houseType: 'house-type',
      housingType: 'house-floor',
      area: 'area',
      houseYear: 'construction-year'
    },
    pets: {
      type: 'type',
      sex: 'sex',
      age: 'age',
      mixedBreed: 'mixed-breed',
      breed: 'breed',
      size: 'size',
      dangerous: 'dangerous',
      name: 'name'
    },
    life: {
      gender: 'gender',
      shape: 'shape',
      employment: 'employment',
      profession: 'profession',
      taxResidence: 'tax-residence',
      insuranceStatus: 'insurance-status',
      smokingHabit: 'smoking-habit',
      drive: 'drive',
      sports: 'sports',
      medicalTreatments: 'medical-treatments',
      disability: 'disability',
      pendingMedicalExam: 'pending-medical-exam',
      cancer: 'cancer',
      diseases: 'diseases',
      psychiatricCare: 'psychiatric-care',
      visualSkills: 'visual-skills',
      auditorySkills: 'auditory-skills',
      drugUse: 'drug-use',
      missingInformation: 'missing-info',
      permanentDisability: 'permanent-disability',
      insuredCapital: 'insured-capital',
      beneficiaries: 'beneficiaries',
      beneficiaryForm: 'beneficiary-form',
      beneficiaryList: 'beneficiary-list'
    }
  },
  fastTrack: '/fast-track/:step',
  fastTrackSteps: {
    resume: 'resume',
    editAddress: 'edit-address',
    editHouse: 'edit-house',
    editCovers: 'edit-covers'
  },
  home: '/home',
  homeProduct: '/home/:product',
  vetsPhone: '/vets-phone',
  ngos: '/ngos',
  ngoDetail: '/ngos/:id',
  otp: '/otp',
  proposal: '/proposal/:id',
  policy: '/policy/:id',
  proposalSign: '/proposal/:id/sign',
  policySign: '/policy/:id/sign',
  policyCessionLetter: '/policy/:id/cession',
  policyValuableItems: '/policy/:id/valuable-items',
  policyMedicalHistory: '/policy/:id/medical-history',
  proposalValuableItems: '/proposal/:id/valuable-items',
  proposalEdit: '/proposal/:id/edit',
  proposalEditInfo: '/proposal/:id/edit/:step',
  proposalEditInfoBeneficiary: '/proposal/:id/edit/:step/:beneficiaryStep',
  policyEdit: '/policy/:id/edit',
  proposalEditSteps: {
    address: 'address',
    house: 'house',
    about: 'about',
    health: 'health',
    habits: 'habits',
    beneficiaries: 'beneficiaries',
    beneficiary: {
      intro: 'intro',
      form: 'form',
      list: 'list'
    },
    pets: 'pets'
  },
  policyOppositionForm: '/policy/:id/opposition-form',
  receipts: '/receipts',
  receiptsDetail: '/receipts/:id',
  claims: '/claims',
  claim: '/claim',
  claimCall: '/claim/call',
  claimHandyman: '/claim/call/handyman',
  claimUrgent: '/claim/urgent',
  claimType: '/claim/type',
  claimDetail: '/claim/:id',
  claimMultimedia: '/claim/:id/multimedia',
  claimDetailStep: '/claim/:id/:step',
  claimDetailSteps: {
    calendar: 'calendar',
    cause: 'cause',
    honest: 'honest',
    proof: 'proof',
    videoTestimony: 'video-testimony',
    documents: 'documents',
    medicalHistory: 'medical-history',
    petDocuments: 'pet-documents',
    abroad: 'abroad',
    description: 'description',
    resume: 'resume',
    multimediaSummary: 'multimedia-summary'
  },
  referrals: '/referrals',
  invitations: '/referrals/invitations',
  settings: '/settings',
  paymentMethods: '/payment-methods',
  paymentMethodDetail: '/payment-method/:id',
  welcome: '/welcome',
  help: '/help',
  updateApp: '/update-app',
  maintenance: '/maintenance',
  downloadApp: '/download-app',
  customerAddress: '/customer-address',
  customerAddressStep: '/customer-address/:step',
  customerAddressSteps: {
    confirm: 'confirm',
    new: 'new'
  },

  setHelpModal: (show = true) =>
    window.location.pathname + window.location.search + ((show && '#help') || ''),
  setAccessRedirect: redirect => `/access${redirect}`,
  setPolicyReceipts: policy => `/receipts/${policy}`,
  setProposal: proposal => `/proposal/${proposal}`,
  setProposalSign: policy => `/proposal/${policy}/sign`,
  setPolicy: policy => `/policy/${policy}`,
  setPolicySign: policy => `/policy/${policy}/sign`,
  setEstimate: (step, product) => (product ? `/estimate/${product}/${step}` : `/estimate/${step}`),
  setHomeProduct: product => `/home/${product}`,
  setFastTrack: step => `/fast-track/${step}`,
  setPolicyCessionLetter: policy => `/policy/${policy}/cession`,
  setProposalValuableItems: policy => `/proposal/${policy}/valuable-items`,
  setPolicyValuableItems: policy => `/policy/${policy}/valuable-items`,
  setPolicyOppositionForm: policy => `/policy/${policy}/opposition-form`,
  setPolicyMedicalHistory: policy => `/policy/${policy}/medical-history`,
  setPolicyEdit: (type, policy) => `/${type}/${policy}/edit`,
  setProposalEditInfo: (policy, step, beneficiaryStep) =>
    beneficiaryStep
      ? `/proposal/${policy}/edit/${step}/${beneficiaryStep}`
      : `/proposal/${policy}/edit/${step}`,
  setClaimDetail: claim => `/claim/${claim}`,
  setClaimMultimedia: claim => `/claim/${claim}/multimedia`,
  setClaimDetailStep: (claim, step) => `/claim/${claim}/${step}`,
  setOtp: (redirect = null) => `/otp${redirect ? `?redirect=${encodeURIComponent(redirect)}` : ''}`,
  setNgoDetail: ngo => `/ngos/${ngo}`,
  setPaymentMethod: payment => `/payment-method/${payment}`,
  setCustomerAddress: step => `/customer-address/${step}`
};

export default PATHS;
