import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { isAppSelector } from '../selectors/app';
import Modal from './Modal';
import Drawer from './Drawer';

const Popup = ({
  headerImage,
  title,
  isOpen = false,
  onRequestClose,
  allowClose = true,
  actions,
  children,
  modal = false, //force modal
  ...rest
}) => {
  const isApp = useSelector(isAppSelector);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const renderDrawer = () => (
    <Drawer
      isOpen={isOpen}
      allowClose={allowClose}
      title={title}
      headerImage={headerImage}
      onRequestClose={allowClose ? onRequestClose : null}
      actions={actions}
      {...rest}
    >
      {children}
    </Drawer>
  );

  const renderModal = () => (
    <Modal
      isOpen={isOpen}
      allowClose={allowClose}
      title={title}
      headerImage={headerImage}
      onRequestClose={allowClose ? onRequestClose : null}
      actions={actions}
      {...rest}
    >
      {children}
    </Modal>
  );

  return <>{!isApp || modal ? renderModal() : renderDrawer()}</>;
};

export default Popup;
