// Alternative to using window.open(path, target) (does not work correctly on ios)
export const openPath = (path, target) => {
  let a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  if (path) a.href = path;
  if (target) a.target = target;
  a.click();
  document.body.removeChild(a);
};
