import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components/macro';

import { history } from '../App';
import { showNPS } from '../actions/app';
import { isAppSelector } from '../selectors/app';
import { analyticsTrack } from '../utils/analytics';
import Popup from './Popup';
import Button from './Button';
import TextArea from './TextArea';
import { useBreakpoint } from '../utils/hooks/useBreakpoint';
import coconutShare from '../assets/nps_coconut_share.svg';
import Rating1 from '../assets/nps_1.svg';
import Rating2 from '../assets/nps_2.svg';
import Rating3 from '../assets/nps_3.svg';
import Rating4 from '../assets/nps_4.svg';
import Rating5 from '../assets/nps_5.svg';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;

  h3,
  h5 {
    text-align: center;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    ${({ reverse }) =>
      reverse
        ? css`
            order: 1;
            font-weight: 600;
            text-transform: uppercase;
          `
        : css`
            order: 0;
          `}
  }

  figure {
    order: ${({ reverse }) => (reverse ? 0 : 1)};
    margin-bottom: ${({ theme }) => theme.spacings.small2};
  }
`;

const RatingFigure = styled.figure`
  svg {
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    svg {
      max-width: 130px;
    }
  }
`;

const RatingLevels = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: ${({ theme }) => `${theme.spacings.small2} 0 ${theme.spacings.small3}`};
`;

const RatingLevel = styled.figure`
  cursor: pointer;
  transition: ${({ theme }) => theme.global.transitionM};
  opacity: ${({ selected }) => (selected ? '1' : '0.5')};

  &:hover {
    opacity: 1;
  }
`;

const CoconutShare = styled.img``;

/**
 * Para usar este componente en cualquier pagina:
 * 	- sobre cualquier ruta /xxxx/nps
 *	- dentro de una vista como un componente mas
 */
const NpsRating = props => {
  const { show: localShow, onClose } = props;
  const levels = [
    { image: Rating1 },
    { image: Rating2 },
    { image: Rating3 },
    { image: Rating4 },
    { image: Rating5 }
  ];

  const [values, setValues] = useState({
    score: '',
    comments: ''
  });

  const { user } = useSelector(state => state.access);
  const { show: globalShow, redirect } = useSelector(state => state.app.nps);
  const isApp = useSelector(isAppSelector);

  const dispatch = useDispatch();
  const intl = useIntl();
  const isMobile = useBreakpoint() === 's';

  const show = useMemo(() => globalShow || localShow, [globalShow, localShow]);

  useEffect(() => {
    if (show) {
      analyticsTrack('nps_review_viewed');
    }
  }, [show]);

  const handleClose = () => {
    analyticsTrack('nps_review_closed');
    handleAction();
  };

  const handleComplete = () => {
    const data = {
      ...values,
      comments: values?.comments ? values.comments.trim().replace(/\n/g, ' ') : '',
      path: window.location.pathname,
      timestamp: new Date().toISOString(),
      client: user?.uuid
    };

    analyticsTrack('nps_review_completed', data);
    handleAction();
  };

  const handleAction = () => {
    dispatch(showNPS(false));
    redirect && history.replace(redirect);
    onClose && onClose();
  };

  if (!show) {
    return null;
  }

  return (
    <Popup
      modal={!isApp}
      size="small"
      allowClose
      isOpen
      onRequestClose={handleClose}
      actions={
        <>
          <Button onClick={handleComplete} block disabled={!values?.score}>
            <FormattedMessage id="common.send" />
          </Button>
          {!isApp && (
            <Button onClick={handleClose} block variant="clean">
              <FormattedMessage id="common.close" />
            </Button>
          )}
        </>
      }
    >
      <StyledContent>
        <StyledHeader reverse={!(isApp || isMobile)}>
          <h3>
            <FormattedMessage id="nps.help_us" />
          </h3>
          <RatingFigure>
            <CoconutShare src={coconutShare} />
          </RatingFigure>
        </StyledHeader>
        <h5>
          <FormattedMessage id="nps.experience" />
        </h5>
        <RatingLevels>
          {levels.map((level, i) => (
            <RatingLevel
              key={i}
              onClick={() => setValues({ ...values, score: levels.length - i })}
              selected={values?.score === levels.length - i}
            >
              <img src={level.image} alt={`Score ${levels.length - i}`} />
            </RatingLevel>
          ))}
        </RatingLevels>
        <TextArea
          placeholder={intl.formatMessage({ id: 'nps.commentary' })}
          onChange={comments => setValues({ ...values, comments })}
        />
      </StyledContent>
    </Popup>
  );
};

export default NpsRating;
