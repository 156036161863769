import { RESET, UPDATE_NEED_HELP_TO_CANCEL } from '../actions/checkout';

export const initialState = {
  needHelpToCancel: null
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_NEED_HELP_TO_CANCEL:
      return {
        ...state,
        needHelpToCancel: payload
      };

    case RESET:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
