// define your feature flags here
export const LOCAL_NEW_CLAIM_FLOW = 'new_claim_flow';
export const LOCAL_NEW_CHECKOUT_FLOW = 'new_checkout_flow';

export const localFlagConfig = {
  [LOCAL_NEW_CLAIM_FLOW]: {
    disabled: false,
    variants: {
      on: true,
      off: false
    },
    defaultVariant: 'off'
  },
  [LOCAL_NEW_CHECKOUT_FLOW]: {
    disabled: false,
    variants: {
      on: true,
      off: false
    },
    defaultVariant: 'off'
  }
};
