import { MAX_FILE_SIZE } from '../constants';

export const isValidFileType = (file, types = ['image', 'video']) => {
  return types.some(type => file.type.includes(type));
};

export const isValidFileSize = (file, maxSize = MAX_FILE_SIZE) => {
  return file.size <= maxSize;
};

export const filterInvalidFiles = (files, types = ['image', 'video']) => {
  const filteredFiles = files.filter(file => isValidFileType(file, types) && isValidFileSize(file));
  return filteredFiles;
};
