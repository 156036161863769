import { useState } from 'react';
import styled, { css } from 'styled-components/macro';

const StyledContainer = styled.div`
  height: 150px;
  border-radius: ${({ theme }) => theme.shapes.borderRadiusLarge};
  position: relative;

  & textarea + label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-family: ${({ theme }) => theme.global.fontFamily};
    font-size: 10px;
    font-weight: ${({ theme }) => theme.global.fontWeightNormal};
    line-height: 24px;
    color: ${({ theme, variant }) =>
      variant === 'secondary' ? theme.colors.gray12 : theme.colors.gray6};
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
  }

  &.active textarea {
    padding: 24px 16px 8px 16px;

    &::placeholder {
      color: white;
    }
  }

  &.active textarea + label {
    top: 4px;
    opacity: 1;
  }
`;

const StyledTextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacings.small3};
  border: 1.5px solid
    ${({ theme, variant }) => (variant === 'secondary' ? theme.colors.gray6 : 'transparent')};
  border-radius: ${({ theme }) => theme.shapes.borderRadiusMedium};
  font-family: ${({ theme }) => theme.global.fontFamily};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.global.fontWeightMedium};
  color: ${({ theme }) => theme.colors.text};
  transition: 0.1s padding ease-in-out;

  ${({ variant }) =>
    variant === 'secondary'
      ? null
      : css`
          box-shadow: 0px 1px 1px rgba(46, 86, 108, 0.15), 0px 1px 2px rgba(46, 86, 108, 0.15);
        `};

  &::placeholder {
    font-size: 14px;
    text-transform: uppercase;
    color: ${({ theme, variant }) =>
      variant === 'secondary' ? theme.colors.gray12 : theme.colors.gray6};
    font-weight: ${({ theme }) => theme.global.fontWeightNormal};
  }

  &:disabled {
    background-color: white;
    color: ${({ theme }) => theme.colors.gray6};
  }

  &:focus {
    border-color: ${({ theme, variant }) =>
      variant === 'secondary' ? theme.colors.text : theme.colors.base};
  }
`;

const StyledLabel = styled.label`
  text-transform: uppercase;
`;

const TextArea = ({ onChange, className, ...props }) => {
  const [active, setActive] = useState(false);

  return (
    <StyledContainer
      className={`field ${(active || props.value) && 'active'} ${className}`}
      variant={props.variant}
    >
      <StyledTextArea
        id={1}
        onChange={e => {
          onChange(e.target.value);
        }}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        {...props}
      />
      <StyledLabel htmlFor={1}>{props.placeholder}</StyledLabel>
    </StyledContainer>
  );
};

export default TextArea;
