import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import app from './reducers/app';
import agency from './reducers/agency';
import claim from './Claim/reducers/claim';
import access from './Access/reducers/access';
import policy from './Policy/reducers/policy';
import estimate from './Estimate/reducers/estimate';
import checkout from './Checkout/reducers/checkout';
import checkoutNew from './CheckoutNew/reducers/checkout';
import httpClientMiddleware from './interceptors/config';
import virtualVet from './VirtualVet/reducers/virtualVet';
import { createCancellationMiddleware } from './interceptors/cancelMiddleware';

const reducers = combineReducers({
  app,
  access,
  policy,
  estimate,
  checkout,
  checkoutNew,
  claim,
  agency,
  virtualVet
});

const store = createStore(
  reducers,
  compose(
    applyMiddleware(thunk, createCancellationMiddleware()),
    applyMiddleware(thunk, httpClientMiddleware()),
    (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose
  )
);

export default store;
