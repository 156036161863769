import { CAPITAL_CODES } from '../../constants';
import { isProposal } from '../../utils';
import { history } from '../../App';
import { getPolicyChanges, getPolicyData } from '../reducers/apiIntern';
import { SUCCESS } from '../../actions/app';
import PATHS from '../../Routes/paths';

export const GET_PROPOSAL = '@@POLICY/GET_PROPOSAL';
export const UPDATE_PROPOSAL = '@@POLICY/UPDATE_PROPOSAL';
export const UPDATE_POLICY = '@@POLICY/UPDATE_POLICY';
export const UPDATE_POLICY_PRICE = '@@POLICY/UPDATE_POLICY_PRICE';
export const GET_POLICY = '@@POLICY/GET_POLICY';
export const CLEAN_ERROR = 'CLEAN_ERROR';
export const RESET_POLICY_STATE = 'RESET_POLICY_STATE';
export const DELETE_PROPOSAL = '@@POLICY/DELETE_PROPOSAL';
export const ADJUST_POLICY = '@@POLICY/ADJUST_POLICY';
export const CONFIRM_POLICY_ADJUSTMENT = '@@POLICY/CONFIRM_POLICY_ADJUSTMENT';
export const CANCEL_POLICY = 'CANCEL_POLICY';
export const CONFIRM_CANCEL_POLICY = 'CONFIRM_CANCEL_POLICY';
export const GET_FINANCIAL = 'GET_FINANCIAL';
export const CREATE_CESSION_LETTER = 'CREATE_CESSION_LETTER';
export const GET_CESSION_LETTER = 'GET_CESSION_LETTER';
export const SEND_MEDICAL_HISTORY = 'SEND_MEDICAL_HISTORY';
export const GET_MEDICAL_HISTORY = 'GET_MEDICAL_HISTORY';
export const SET_LUKO_MTA_WARNING_SHOWN = 'SET_LUKO_MTA_WARNING_SHOWN';
export const CREATE_OPPOSITION_FORM = 'CREATE_OPPOSITION_FORM';
export const SIGN_POLICY = 'SIGN_POLICY';
export const FAST_POLICY_PRICE = '@@POLICY/FAST_POLICY_PRICE';
export const RESET_POLICY = '@@POLICY/RESET_POLICY';
export const REQUOTE_POLICY = '@@POLICY/REQUOTE_POLICY';

export const getBanks = () => async dispatch => {
  const action = await dispatch({
    type: GET_FINANCIAL,
    payload: {
      request: {
        method: 'GET',
        url: '/financial'
      }
    }
  });
  return action.type === `${GET_FINANCIAL}${SUCCESS}`;
};

export const getMedicalHistory = policyId => async dispatch => {
  const action = await dispatch({
    type: GET_MEDICAL_HISTORY,
    payload: {
      request: {
        method: 'GET',
        url: `/v1/policy/${policyId}/medical-history`
      }
    }
  });
  return action.type === `${GET_MEDICAL_HISTORY}${SUCCESS}`;
};

export const sendMedicalHistory = (policyId, files) => async dispatch => {
  let formData = new FormData();
  files.forEach(file => formData.append('files', file));

  const action = await dispatch({
    type: SEND_MEDICAL_HISTORY,
    payload: {
      request: {
        method: 'POST',
        url: `/v1/policy/${policyId}/medical-history`,
        data: formData
      }
    }
  });
  return action.type === `${SEND_MEDICAL_HISTORY}${SUCCESS}`;
};

export const getCessionLetter = policyId => async dispatch => {
  const action = await dispatch({
    type: GET_CESSION_LETTER,
    payload: {
      request: {
        method: 'GET',
        url: `/v2/policy/${policyId}/cession`
      }
    }
  });
  return action.type === `${GET_CESSION_LETTER}${SUCCESS}`;
};

export const createCessionLetter = (policyId, data) => async dispatch => {
  const action = await dispatch({
    type: CREATE_CESSION_LETTER,
    payload: {
      request: {
        method: 'POST',
        url: `/v2/policy/${policyId}/cession`,
        data
      }
    }
  });
  return action.type === `${CREATE_CESSION_LETTER}${SUCCESS}`;
};

export const createOppositionForm = (policyId, params) => async dispatch => {
  let formData = new FormData();
  Object.keys(params).forEach(key => formData.append(key, params[key]));

  const response = await dispatch({
    type: CREATE_OPPOSITION_FORM,
    payload: {
      request: {
        method: 'POST',
        url: `/insurance/opposition/${policyId}`,
        data: formData
      }
    }
  });

  return response.type === `${CREATE_OPPOSITION_FORM}${SUCCESS}`;
};

export const onRequotePolicy = policy => async dispatch => {
  const payload = getPolicyData(policy);
  const action = await dispatch(requotePolicy(payload));

  if (action.type === `${REQUOTE_POLICY}${SUCCESS}`) {
    return action.payload.data.id;
  }
  return null;
};

export const requotePolicy = data => ({
  type: REQUOTE_POLICY,
  payload: {
    request: {
      method: 'POST',
      url: '/v4/proposal',
      data
    }
  }
});

export const deleteProposal = proposalId => async dispatch => {
  const action = await dispatch({
    type: DELETE_PROPOSAL,
    payload: {
      request: {
        method: 'DELETE',
        url: `/v1/proposal/${proposalId}`
      }
    }
  });

  return action.type === `${DELETE_PROPOSAL}${SUCCESS}`;
};

export const cancelPolicy = policyId => ({
  type: CANCEL_POLICY,
  payload: {
    request: {
      method: 'DELETE',
      url: `/v2/policy/${policyId}`
    }
  }
});

export const confirmCancelPolicy = (policyId, code, cancelAsap) => async dispatch => {
  const action = await dispatch({
    type: CONFIRM_CANCEL_POLICY,
    payload: {
      request: {
        method: 'DELETE',
        url: `/v2/policy/${policyId}/confirm`,
        params: { code, cancelAsap }
      }
    }
  });
  return action.type === `${CONFIRM_CANCEL_POLICY}${SUCCESS}`;
};

export const onGetProposal = (proposalId, notFoundCallback) => async (dispatch, getState) => {
  const { isAuthenticated } = getState().access;

  if (isAuthenticated) {
    const response = await dispatch({
      type: GET_PROPOSAL,
      payload: {
        request: {
          method: 'GET',
          url: `/v4/proposal/${proposalId}`
        }
      }
    });
    if (response.type === `${GET_PROPOSAL}${SUCCESS}`) {
      return true;
    }
    if (notFoundCallback) notFoundCallback();
    return false;
  }

  if (notFoundCallback) {
    notFoundCallback();
  } else {
    history.push(PATHS.home);
  }
  return false;
};

export const onGetPolicy = (policyId, notFoundCallback) => async (dispatch, getState) => {
  const { isAuthenticated } = getState().access;

  if (isAuthenticated) {
    const response = await dispatch({
      type: GET_POLICY,
      payload: {
        request: {
          method: 'GET',
          url: `/v4/policy/${policyId}`
        }
      }
    });
    if (response.type === `${GET_POLICY}${SUCCESS}`) {
      return true;
    }
    if (notFoundCallback) notFoundCallback();
    return false;
  }

  if (notFoundCallback) {
    notFoundCallback();
  } else {
    history.push(PATHS.home);
  }
  return false;
};

export const adjustPolicy = policyId => ({
  type: ADJUST_POLICY,
  payload: {
    request: {
      method: 'PUT',
      url: `/v3/policy/${policyId}/adjust`
    }
  }
});

export const onConfirmPolicyAdjustment = (policyId, code) => async (dispatch, getState) => {
  const { policy } = getState().policy;

  const changes = getPolicyChanges(policy);

  const action = await dispatch(confirmPolicyAdjustment(policyId, changes, code));

  return action.type === `${CONFIRM_POLICY_ADJUSTMENT}${SUCCESS}`;
};

export const confirmPolicyAdjustment = (policyId, data, code) => ({
  type: CONFIRM_POLICY_ADJUSTMENT,
  payload: {
    request: {
      method: 'PUT',
      url: `/v4/policy/${policyId}/adjust/confirm`,
      data,
      params: { code }
    }
  }
});

export const calculatePolicyPrice = changes => async (dispatch, getState) => {
  const { policy } = getState().policy;
  const policyData = { ...policy, ...changes };

  const payload = getPolicyChanges(policyData);
  const action = await dispatch(policyPrice(policy.id, payload, isProposal(policy.status)));

  return action.type === `${FAST_POLICY_PRICE}${SUCCESS}`;
};

export const policyPrice = (id, data, isProposal) => ({
  type: FAST_POLICY_PRICE,
  payload: {
    request: {
      method: 'POST',
      url: `/v3/${isProposal ? 'proposal' : 'policy'}/${id}/price`,
      data
    }
  }
});

export const onUpdate = (policyId, data, tag) => async (dispatch, getState) => {
  const {
    policy: { status }
  } = getState().policy;

  const proposal = isProposal(status);

  return dispatch(
    proposal ? onUpdateProposal(policyId, data, tag) : onUpdatePolicy(policyId, data, tag)
  );
};

export const onUpdatePolicy = (policyId, data, tag) => async (dispatch, getState) => {
  const { policy } = getState().policy;

  const newPriceProps = getPolicyChanges(policy, data);

  const action = await dispatch(updatePolicyPrice(policyId, newPriceProps, tag));
  if (action.type === `${UPDATE_POLICY_PRICE}${SUCCESS}`) {
    const edited = Object.keys(data)[0];
    let policyNewProp = {};
    switch (edited) {
      case CAPITAL_CODES.CONTENT.toLowerCase():
      case CAPITAL_CODES.CONTINENT.toLowerCase():
      case CAPITAL_CODES.LIABILITY.toLowerCase():
      case `${CAPITAL_CODES.LIFE.toLowerCase()}_capital`:
        policyNewProp = { capitals: policy.capitals };
        const index = policyNewProp.capitals.findIndex(
          capital =>
            capital.code.toLowerCase() === edited ||
            `${capital.code.toLowerCase()}_capital` === edited
        );
        if (index !== -1) {
          policyNewProp.capitals[index].amount = data[edited];
        }
        break;
      case 'additional_inhabitants':
        policyNewProp = {
          additional_inhabitants: {
            ...policy.additional_inhabitants,
            values: data[edited].map(item => ({
              ...item,
              id: item.id || 'temp_' + Math.floor(Math.random() * 1000000)
            }))
          }
        };
        break;
      case 'valuable_items':
        policyNewProp = {
          valuable_items: {
            ...policy.valuable_items
          }
        };
        policyNewProp.valuable_items.values.forEach(item => {
          item.values = [];
        });
        data[edited].forEach(item => {
          if (!item.id) {
            item.id = 'temp_' + Math.floor(Math.random() * 1000000);
          }
          const index = policyNewProp.valuable_items.values.findIndex(
            value => value.code === item.type
          );
          if (index !== -1) {
            policyNewProp.valuable_items.values[index].values.push(item);
          }
        });
        break;
      case 'covers':
        policyNewProp = {
          covers: policy.covers.map(cover => ({
            ...cover,
            active: cover.extra ? data.covers.includes(cover.code) : cover.active
          }))
        };
        break;
      case 'interval':
        policyNewProp = {
          period: data[edited]
        };
        break;
      case 'automatic_capital_increase':
        policyNewProp = {
          automatic_capital_increase: data[edited]
        };
        break;
      case 'beneficiaries':
        policyNewProp = {
          beneficiaries: data[edited]
        };
        break;
      default:
        break;
    }
    dispatch(updatePolicy(policyNewProp));
    return true;
  }
  return false;
};

export const updatePolicy = payload => ({
  type: UPDATE_POLICY,
  payload
});

export const updatePolicyPrice = (policyId, data, tag) => ({
  type: UPDATE_POLICY_PRICE,
  payload: {
    request: {
      method: 'POST',
      url: `/v3/policy/${policyId}/price`,
      data
    },
    tag
  }
});

export const onUpdateProposal = (proposalId, data, tag) => async (dispatch, getState) => {
  const { policy } = getState().policy;
  const mappedData = getPolicyChanges(policy, data);

  const action = await dispatch(updateProposal(proposalId, mappedData, tag));

  return action.type === `${UPDATE_PROPOSAL}${SUCCESS}`;
};

export const updateProposal = (proposalId, data, tag) => ({
  type: UPDATE_PROPOSAL,
  payload: {
    request: {
      method: 'PUT',
      url: `/v4/proposal/${proposalId}`,
      data
    },
    tag
  }
});

export const signPolicy = policyId => async dispatch => {
  const action = await dispatch({
    type: SIGN_POLICY,
    payload: {
      request: {
        method: 'POST',
        url: `/v4/policy/${policyId}/sign`
      }
    }
  });
  return action.type === `${SIGN_POLICY}${SUCCESS}`;
};

export const setLukoWarningShown = () => ({
  type: SET_LUKO_MTA_WARNING_SHOWN,
  payload: null
});

export const resetChanges = () => {
  window.location.reload();
};

export const resetPolicyState = () => ({
  type: RESET_POLICY_STATE,
  payload: null
});

export const onBackUpdate = () => ({
  type: 'GO_BACK_UPDATE',
  payload: null
});

export const onFinishUpdate = () => ({
  type: 'FINISH_UPDATE',
  payload: null
});

export const cleanError = () => ({
  type: CLEAN_ERROR,
  payload: null
});

export const resetPolicy = () => ({
  type: RESET_POLICY,
  payload: null
});
