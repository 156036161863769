import { FLOORS } from '../constants';

export const getFloorType = (floor, topFloor = null) => {
  switch (floor?.toString().toLowerCase()) {
    case '0':
    case 'bj':
    case 'bajo':
      return FLOORS.GROUND_FLOOR;
    case 'en':
    case 'entreplanta':
    case 'pr':
    case 'principal':
    case '1':
      return FLOORS.FIRST_FLOOR;
    case 'at':
    case 'atico':
    case '+1':
    case topFloor?.toString():
      return FLOORS.TOP_FLOOR;
    default:
      return FLOORS.MIDDLE_FLOOR;
  }
};
