import { isPolicyValid } from '../Policy/reducers/apiIntern';

export const formatReceipts = data => {
  return data
    ? data
        .map(({ collections, ...rest }) => ({
          ...rest,
          policies: collections.map(({ policy_id }) => policy_id)
        }))
        .sort((a, b) => new Date(b.created_date) - new Date(a.created_date))
    : [];
};

export const formatPolicies = data => {
  return data
    ? data.map(policy => ({
        ...policy,
        valid: isPolicyValid(policy)
      }))
    : [];
};
