import { LOCAL_FONTS } from '../constants';
import { appendTagToHead } from '../utils/appendTagToHead';
import { SUCCESS } from './app';

export const GET_AGENCY = 'GET_AGENCY';

export const getAgency = (agency_id, agent_id) => async (dispatch, getState) => {
  const action = await dispatch({
    type: GET_AGENCY,
    payload: {
      request: {
        method: 'GET',
        url: `/agency/${agency_id}`
      },
      data: {
        agency_id,
        agent_id
      }
    }
  });

  if (action.type === `${GET_AGENCY}${SUCCESS}`) {
    const agency = getState().agency;
    agency.apply && applyAgencyPersonalization(agency);
  }
};

const applyAgencyPersonalization = agency => {
  if (agency.favicon) {
    document.querySelector("link[rel*='icon']").href = agency.favicon;
  }
  let customStyles = '';
  if (agency.customCss) {
    customStyles += agency.customCss;
  }

  customStyles += `
    svg:not(.not-branded),
    img:not(.not-branded){
      filter: grayscale(100%);
    }';
  `;
  const style = document.createElement('style');
  style.innerHTML = customStyles;
  document.head.appendChild(style);

  if (agency?.font?.family && !LOCAL_FONTS.includes(agency.font.family)) {
    const cleanFont = agency.font.family.replace(/\s/g, '+');
    appendTagToHead('link', {
      rel: 'stylesheet',
      href: `https://fonts.googleapis.com/css2?family=${cleanFont}&display=swap`
    });
  }
};
