import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { OpenFeatureProvider } from './openFeature/OpenFeatureProvider';
import { INMEMORY_PROVIDER_NAME } from './openFeature/config';
import { localFlagConfig } from './openFeature/features';
import Routes from './Routes/';
import reportWebVitals from './reportWebVitals';
import store from './store';

Sentry.init({
  dsn: window.SENTRY_DSN,
  environment: window.SENTRY_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <OpenFeatureProvider providerName={INMEMORY_PROVIDER_NAME} config={localFlagConfig}>
      <Routes />
    </OpenFeatureProvider>
  </Provider>
);

reportWebVitals();
