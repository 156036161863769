import { FAIL, RESET, SUCCESS } from '../../actions/app';
import { getErrorMessage } from '../../utils/getErrorMessage';
import {
  CREATE_CLAIM,
  DISCARD_CLAIM,
  GET_CLAIM,
  GET_USER_CLAIMS,
  LOADING_STEP,
  RESET_CLAIM,
  SEND_CLAIM,
  UPDATE_CLAIM,
  UPDATE_CLAIM_PROPERTY,
  UPLOAD_CLAIM_FILE,
  CLEAN_ERROR,
  DELETE_CLAIM_FILE
} from '../actions/claim';
import { getClaimData, getCleanClaims } from './apiIntern';

export const initialState = {
  isLoading: false,
  isLoadingStep: false,
  error: null,
  claims: [],
  claim: {
    id: null,
    status: null,
    type: null,
    damageType: null,
    date: null,
    honestSigned: false,
    documents: [],
    abroad: false,
    description: null
  }
};

const reducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case GET_USER_CLAIMS:
      return {
        ...state,
        isLoading: true,
        claims: []
      };
    case `${GET_USER_CLAIMS}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        claims: getCleanClaims(payload.data)
      };
    case GET_CLAIM:
      return {
        ...state,
        isLoading: true,
        claim: {
          ...initialState.claim
        }
      };
    case `${GET_CLAIM}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        claim: getClaimData(payload.data)
      };
    case LOADING_STEP:
      return {
        ...state,
        isLoadingStep: payload
      };
    case RESET_CLAIM:
      return {
        ...state,
        claim: {
          ...initialState.claim,
          ...(payload || {})
        }
      };
    case UPDATE_CLAIM_PROPERTY:
      return {
        ...state,
        claim: {
          ...state.claim,
          ...payload
        }
      };
    case CREATE_CLAIM:
    case DISCARD_CLAIM:
    case UPLOAD_CLAIM_FILE:
      return {
        ...state,
        isLoading: true
      };
    case SEND_CLAIM:
      return {
        ...state
      };
    case UPDATE_CLAIM:
      return {
        ...state
      };
    case `${UPDATE_CLAIM}${SUCCESS}`:
    case `${CREATE_CLAIM}${SUCCESS}`:
    case `${UPLOAD_CLAIM_FILE}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        isLoadingStep: false,
        claim: {
          ...state.claim,
          ...Object.fromEntries(Object.entries(payload.data).filter(([, v]) => v !== null)),
          ...getClaimData(payload.data)
        }
      };
    case `${DELETE_CLAIM_FILE}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        isLoadingStep: false
      };
    case `${DISCARD_CLAIM}${SUCCESS}`:
    case `${SEND_CLAIM}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        isLoadingStep: false
      };
    case `${GET_CLAIM}${FAIL}`:
    case `${GET_USER_CLAIMS}${FAIL}`:
    case `${UPDATE_CLAIM}${FAIL}`:
    case `${CREATE_CLAIM}${FAIL}`:
    case `${DELETE_CLAIM_FILE}${FAIL}`:
    case `${DISCARD_CLAIM}${FAIL}`:
    case `${SEND_CLAIM}${FAIL}`:
    case `${UPLOAD_CLAIM_FILE}${FAIL}`:
      return {
        ...state,
        isLoading: false,
        isLoadingStep: false,
        error: !error.message.cancelled ? getErrorMessage(error) : null
      };
    case CLEAN_ERROR:
      return {
        ...state,
        error: null
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
