/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { POLICY_STATUS, TELEPHONE } from '../../constants';
import { useHasPolicies } from './useHasPolicies';
import { prettyPhoneNumber } from '../getPhoneLink';

export const useHelpPhone = () => {
  const { user } = useSelector(state => state.access);
  const { phone, showPhone } = useSelector(state => state.agency);

  if (!showPhone || !phone) {
    return TELEPHONE.HELP;
  }

  const [hasProposals, isLoadingProposals] = useHasPolicies(user?.id, [POLICY_STATUS.NEW]);
  const intl = useIntl();

  if (isLoadingProposals) {
    return intl.formatMessage({ id: 'common.loading' });
  }

  return hasProposals ? prettyPhoneNumber(phone) : TELEPHONE.HELP;
};
