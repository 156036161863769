import {
  AVAILABLE_PRODUCTS,
  POLICY_STATUS,
  PRODUCT_TYPE_LABELS,
  PRODUCT_TYPES,
  SECOND_HOME
} from '../constants';

export function getCrossSellProduct(policies) {
  // Products sorted by priority and filtered by the ones that are available
  const crossSellProducts = [PRODUCT_TYPES.HOME, PRODUCT_TYPES.PETS, PRODUCT_TYPES.LIFE].filter(
    product => AVAILABLE_PRODUCTS.includes(product)
  );

  // Filter the policies that are valid
  const validPolicies = policies.filter(policy => policy.valid);

  // Remove from crossSellProducts the products that the user already has
  validPolicies.forEach(policy => {
    const index = crossSellProducts.indexOf(policy.business_line);
    if (index !== -1) {
      crossSellProducts.splice(index, 1);
    }
  });

  // If there are products to recommend, we show the first one
  if (crossSellProducts.length) {
    return PRODUCT_TYPE_LABELS[crossSellProducts[0]];
  }

  // If there are no products left to recommend, the home is enabled and doesn't have any suspended home, we show second home
  const userHasSuspendedHome = policies.some(
    policy =>
      policy.business_line === PRODUCT_TYPES.HOME && policy.status === POLICY_STATUS.SUSPENDED
  );
  if (
    !crossSellProducts.length &&
    AVAILABLE_PRODUCTS.includes(PRODUCT_TYPES.HOME) &&
    !userHasSuspendedHome
  ) {
    return SECOND_HOME;
  }

  return null;
}
