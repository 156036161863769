import { ConsentManagerBuilder } from '@segment/consent-manager';
import { useEffect, useState } from 'react';
import { getCookie } from '../utils';
import JSON5 from 'json5';

const COOKIEBOT_COKIE_NAME = 'CookieConsent';

const COOKIEBOT_CATEGORIES = {
  NECESSARY: 'necessary',
  PREFERENCES: 'preferences',
  STATISTICS: 'statistics',
  MARKETING: 'marketing'
};

const SEGMENT_CATEGORIES = {
  NECESSARY: 'necessary',
  PREFERENCES: 'functional',
  STATISTICS: 'marketingAndAnalytics',
  MARKETING: 'advertising'
};

const STATISTICS_CATEGORIES = [
  'A/B Testing',
  'Analytics',
  'Attribution',
  'Email',
  'Email Marketing',
  'Enrichment',
  'Heatmaps & Recordings',
  'Raw Data',
  'Realtime Dashboards',
  'Referrals',
  'Surveys',
  'Video'
];

const MARKETING_CATEGORIES = ['Advertising', 'Tag Managers'];

const PREFERENCES_CATEGORIES = [
  'CRM',
  'Customer Success',
  'Deep Linking',
  'Helpdesk',
  'Livechat',
  'Performance Monitoring',
  'Personalization',
  'SMS & Push Notifications',
  'Security & Fraud'
];

const NECESSARY_DESTINATIONS_IDS = [
  'Actions Amplitude',
  'Actions Slack',
  'Amazon EventBridge',
  'Braze Cloud Mode (Actions)',
  'Zendesk',
  'Google Tag Manager'
];

const initialPreferences = { [SEGMENT_CATEGORIES.NECESSARY]: true };

const handleMapCustomPreferences = (destinations, preferences) => {
  const customPreferences = Object.keys(SEGMENT_CATEGORIES).reduce((obj, category) => {
    obj[SEGMENT_CATEGORIES[category]] = preferences[SEGMENT_CATEGORIES[category]] || false;
    return obj;
  }, {});

  const destinationPreferences = destinations.reduce((obj, destination) => {
    if (NECESSARY_DESTINATIONS_IDS.includes(destination.id)) {
      obj[destination.id] = true;
    } else if (MARKETING_CATEGORIES.includes(destination.category)) {
      obj[destination.id] = preferences[SEGMENT_CATEGORIES.MARKETING] || false;
    } else if (PREFERENCES_CATEGORIES.includes(destination.category)) {
      obj[destination.id] = preferences[SEGMENT_CATEGORIES.PREFERENCES] || false;
    } else if (STATISTICS_CATEGORIES.includes(destination.category)) {
      obj[destination.id] = preferences[SEGMENT_CATEGORIES.STATISTICS] || false;
    } else {
      obj[destination.id] = false;
    }
    return obj;
  }, {});

  return { destinationPreferences, customPreferences };
};

export const SegmentPreferences = ({ saveConsent }) => {
  const [cookiebot, setCookiebot] = useState(getCookie(COOKIEBOT_COKIE_NAME));

  const refreshCookiebot = () => setCookiebot(getCookie(COOKIEBOT_COKIE_NAME));

  useEffect(() => {
    window.addEventListener('CookiebotOnConsentReady', refreshCookiebot);
  }, []);

  useEffect(() => {
    if (cookiebot) {
      const cookieBotObj = JSON5.parse(cookiebot);
      const newPreferences = {
        [SEGMENT_CATEGORIES.NECESSARY]: true,
        [SEGMENT_CATEGORIES.PREFERENCES]: cookieBotObj[COOKIEBOT_CATEGORIES.PREFERENCES] || false,
        [SEGMENT_CATEGORIES.STATISTICS]: cookieBotObj[COOKIEBOT_CATEGORIES.STATISTICS] || false,
        [SEGMENT_CATEGORIES.MARKETING]: cookieBotObj[COOKIEBOT_CATEGORIES.MARKETING] || false
      };
      saveConsent(newPreferences, true, false);
      window.removeEventListener('CookiebotOnConsentReady', refreshCookiebot);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookiebot]);

  return null;
};

const SegmentConsentManager = () => {
  return (
    <ConsentManagerBuilder
      writeKey={window.SEGMENT_ID}
      defaultDestinationBehavior="imply"
      mapCustomPreferences={handleMapCustomPreferences}
      initialPreferences={initialPreferences}
    >
      {({ setPreferences, saveConsent }) => {
        return <SegmentPreferences saveConsent={saveConsent} setPreferences={setPreferences} />;
      }}
    </ConsentManagerBuilder>
  );
};

export default SegmentConsentManager;
