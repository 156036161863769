import * as Sentry from '@sentry/react';

import { FAIL, SUCCESS } from '../../actions/app';
import { onGetPolicy } from '../../Policy/actions/policy';

export const GET_PAYMENT_METHODS = '@@CHECKOUT/GET_PAYMENT_METHODS';
export const RESET_USER_PAYMENTS = '@@CHECKOUT/RESET_USER_PAYMENTS';
export const SETUP_INTENT = '@@CHECKOUT/SETUP_INTENT';
export const CONFIRM_CARD_SETUP = '@@CHECKOUT/CONFIRM_CARD_SETUP';
export const UPDATE_PAYMENT_METHOD_DATA = '@@CHECKOUT/UPDATE_PAYMENT_METHOD_DATA';
export const DELETE_PAYMENT_METHOD = '@@CHECKOUT/DELETE_PAYMENT_METHOD';
export const RESET_PAYMENT_METHOD = '@@CHECKOUT/RESET_PAYMENT_METHOD';
export const UPDATE_NEED_HELP_TO_CANCEL = '@@CHECKOUT/UPDATE_NEED_HELP_TO_CANCEL';
export const UPDATE_PAYMENT_DAY = '@@CHECKOUT/UPDATE_PAYMENT_DAY';
export const PAY_WITH_PAYMENT_METHOD = '@@CHECKOUT/PAY_WITH_PAYMENT_METHOD';
export const UPDATE_POLICY_PAYMENT_METHOD = '@@CHECKOUT/UPDATE_POLICY_PAYMENT_METHOD';
export const CREATE_PAYMENT_METHOD_CARD = '@@CHECKOUT/CREATE_PAYMENT_METHOD_CARD';
export const CREATE_PAYMENT_METHOD_SEPA = '@@CHECKOUT/CREATE_PAYMENT_METHOD_SEPA';
export const CLEAN_ERROR = 'CLEAN_ERROR';

const newSentryLog = (data, action, message) => {
  Sentry.withScope(scope => {
    scope.setLevel('log');
    scope.addBreadcrumb({
      message: 'Log',
      data: {
        ...data,
        error: {
          errorCode: action?.error?.code || '-',
          errorMessage: action?.error?.message || '-',
          status: action?.status || '-',
          message: action?.message || '-'
        }
      }
    });
    Sentry.captureMessage(message);
  });
};

const setupStripePayment = async (dispatch, stripe, paymentMethod, sentryData) => {
  const actionIntent = await dispatch(setupIntent());

  if (actionIntent.type === `${SETUP_INTENT}${SUCCESS}`) {
    dispatch(confirmCardSetup());
    const confirmAction = await stripe.confirmCardSetup(actionIntent.payload.data.client_secret, {
      payment_method: paymentMethod
    });

    if (confirmAction.error) {
      dispatch(confirmCardSetup(FAIL, confirmAction.error));
      newSentryLog(sentryData, confirmAction, 'FAIL onPayWithCard:confirmCardSetup');
      return false;
    } else {
      dispatch(confirmCardSetup(SUCCESS));
      return confirmAction.setupIntent.payment_method;
    }
  } else {
    newSentryLog(sentryData, actionIntent, 'FAIL onPayWithCard:setupIntent');
  }
  return false;
};

export const onCreatePaymentMethod =
  (withCard, paymentInfo, account_holder, sentryData) => async dispatch => {
    const finalPaymentInfo = withCard
      ? await setupStripePayment(
          dispatch,
          paymentInfo.stripe,
          paymentInfo.paymentMethod,
          sentryData
        )
      : paymentInfo;

    if (finalPaymentInfo) {
      const data = withCard
        ? { payment_method: finalPaymentInfo, account_holder }
        : { iban: finalPaymentInfo, account_holder };
      const action = await dispatch(createPaymentMethod(withCard, data));
      if (
        action.type === `${CREATE_PAYMENT_METHOD_CARD}${SUCCESS}` ||
        action.type === `${CREATE_PAYMENT_METHOD_SEPA}${SUCCESS}`
      ) {
        return action;
      }
    }
    return false;
  };

export const onPayWithPaymentMethod =
  (proposalId, paymentDay, helpToCancel, paymentMethodId, allowBackDating = true) =>
  async dispatch => {
    const data = { proposalId, paymentDay, helpToCancel, paymentMethodId, allowBackDating };
    const action = await dispatch(payWithPaymentMethod(data));
    if (action.type === `${PAY_WITH_PAYMENT_METHOD}${SUCCESS}`) {
      if (action.payload.data?.policy_id) {
        dispatch(onGetPolicy(action.payload.data.policy_id));
      }
      return action.payload.data;
    }
    return false;
  };

export const onCreatePaymentAndPay =
  (withCard, paymentInfo, policyId, paymentDay, accountHolder) => async (dispatch, getState) => {
    const newPaymentSentryMessage = withCard
      ? 'FAIL onCreatePaymentMethod:createPaymentMethod(withCard)'
      : 'FAIL onCreatePaymentMethod:createPaymentMethod(withSepa)';
    const sentryData = withCard ? { policyId, paymentDay } : { policyId };
    const { needHelpToCancel } = getState().checkout;

    const newPaymentAction = await dispatch(
      onCreatePaymentMethod(withCard, paymentInfo, accountHolder, sentryData)
    );

    if (newPaymentAction) {
      const paymentWithMethodResult = await dispatch(
        onPayWithPaymentMethod(
          policyId,
          paymentDay,
          needHelpToCancel,
          newPaymentAction.payload.data.id
        )
      );
      return paymentWithMethodResult;
    } else {
      newSentryLog(sentryData, newPaymentAction, newPaymentSentryMessage);
    }
    return newPaymentAction;
  };

export const getPaymentMethods = customerId => ({
  type: GET_PAYMENT_METHODS,
  payload: {
    request: {
      method: 'GET',
      url: '/v2/payment/activePayments',
      params: { customer_id: customerId }
    }
  }
});

export const setupIntent = () => ({
  type: SETUP_INTENT,
  payload: {
    request: {
      method: 'POST',
      url: '/v2/payment/setupIntent'
    }
  }
});

const createPaymentMethod = (withCard, data) => ({
  type: withCard ? CREATE_PAYMENT_METHOD_CARD : CREATE_PAYMENT_METHOD_SEPA,
  payload: {
    request: {
      method: 'POST',
      url: withCard ? '/v3/payment/card' : '/v3/payment/sepa',
      data
    }
  }
});

const payWithPaymentMethod = data => ({
  type: PAY_WITH_PAYMENT_METHOD,
  payload: {
    request: {
      method: 'POST',
      url: '/policy',
      data
    }
  }
});

export const updatePolicyPaymentMethod = (paymentMethodId, policyId) => ({
  type: UPDATE_POLICY_PAYMENT_METHOD,
  payload: {
    request: {
      method: 'PUT',
      url: `/paymentMethod/${paymentMethodId}`,
      data: { policy_id: policyId }
    }
  }
});

export const deletePaymentMethod = paymentMethodId => ({
  type: DELETE_PAYMENT_METHOD,
  payload: {
    request: {
      method: 'DELETE',
      url: `/v3/payment/${paymentMethodId}`
    }
  }
});

export const onDeletePaymentMethod = paymentMethodId => async dispatch => {
  const action = await dispatch(deletePaymentMethod(paymentMethodId));

  if (action.type === `${DELETE_PAYMENT_METHOD}${SUCCESS}`) {
    dispatch(getPaymentMethods());
    return true;
  }

  return false;
};

export const confirmCardSetup = (status, error) => ({
  type: `${CONFIRM_CARD_SETUP}${status ? status : ''}`,
  payload: {
    error
  }
});

export const updatePaymentMethodData = payload => ({
  type: UPDATE_PAYMENT_METHOD_DATA,
  payload
});

export const updateNeedHelpToCancel = payload => ({
  type: UPDATE_NEED_HELP_TO_CANCEL,
  payload
});

export const updatePaymentDay = payload => ({
  type: UPDATE_PAYMENT_DAY,
  payload
});

export const resetUserPayments = () => ({
  type: RESET_USER_PAYMENTS
});

export const resetPaymentMethod = () => ({
  type: RESET_PAYMENT_METHOD
});

export const cleanError = () => ({
  type: CLEAN_ERROR,
  payload: null
});
