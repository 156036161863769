import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { POLICY_STATUS } from '../../constants';
import { onGetUserPolicies } from '../../actions/app';

export const useHasPolicies = (id, status = [POLICY_STATUS.ACTIVE], checkForCustomer = false) => {
  const [hasPolicies, setHasPolicies] = useState(null);
  const { policies } = useSelector(state => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      // Si tenemos las polizas en el store no hacemos la llamada a la API, sino por si acaso se hace igualmente
      const filteredPolicies =
        policies?.length &&
        policies.some(
          policy =>
            status.includes(policy.status) && (checkForCustomer ? policy.customer_id === id : true)
        );
      if (filteredPolicies) {
        setHasPolicies(filteredPolicies);
      } else {
        dispatch(onGetUserPolicies(status.includes(POLICY_STATUS.NEW)))
          .then(response => {
            const filteredPolicies = response?.some(
              policy =>
                status.includes(policy.status) &&
                (checkForCustomer ? policy.customer_id === id : true)
            );
            setHasPolicies(filteredPolicies);
          })
          .catch(() => {
            setHasPolicies(false);
          });
      }
    } else {
      setHasPolicies(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return [hasPolicies, hasPolicies === null];
};
