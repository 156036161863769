import { FAIL, RESET, SUCCESS } from '../../actions/app';
import {
  RESET_POLICY_STATE,
  GET_PROPOSAL,
  GET_POLICY,
  GET_FINANCIAL,
  GET_CESSION_LETTER,
  CREATE_CESSION_LETTER,
  GET_MEDICAL_HISTORY,
  CANCEL_POLICY,
  CLEAN_ERROR,
  CONFIRM_CANCEL_POLICY,
  CONFIRM_POLICY_ADJUSTMENT,
  DELETE_PROPOSAL,
  ADJUST_POLICY,
  UPDATE_PROPOSAL,
  UPDATE_POLICY_PRICE,
  UPDATE_POLICY,
  SET_LUKO_MTA_WARNING_SHOWN,
  CREATE_OPPOSITION_FORM,
  FAST_POLICY_PRICE,
  SIGN_POLICY,
  RESET_POLICY,
  REQUOTE_POLICY,
  SEND_MEDICAL_HISTORY
} from '../actions/policy';
import { STATUS_CODE } from '../../constants';
import { getErrorMessage } from '../../utils/getErrorMessage';
import { getPolicyAttributes, getPolicyPrice, mapDocuments } from './apiIntern';
import { cloneDeep } from 'lodash';

export const initialState = {
  isLoadingCession: false,
  isLoadingInhabitant: false,
  isLoading: false,
  isLoadingPrice: false,
  error: null,
  initialPolicy: null,
  cession: null,
  medicalHistory: null,
  policy: null,
  financial: null,
  updateStep: 1,
  fastPrice: null,
  isLoadingDate: false,
  isLoadingPayment: false,
  isLoadingCancel: false,
  isLoadingConfirmCancel: false,
  isLoadingUpdate: false,
  isLoadingDiscard: false,
  isLoadingReset: false,
  isLoadingFastPrice: false,
  isLoadingValuableItem: false,
  isLoadingRequote: false,
  isLoadingMedicalHistory: false,
  sign_url: null
};

const reducer = (state = initialState, { type, payload, error }) => {
  let errorMessage = null;
  switch (type) {
    case GET_FINANCIAL:
    case GET_CESSION_LETTER:
    case CREATE_CESSION_LETTER:
      return {
        ...state,
        isLoadingCession: state.isLoadingCession + 1,
        cession: null
      };
    case `${GET_FINANCIAL}${SUCCESS}`:
      return {
        ...state,
        isLoadingCession: state.isLoadingCession - 1,
        financial: payload.data
      };

    case `${GET_FINANCIAL}${FAIL}`:
      return {
        ...state,
        isLoadingCession: state.isLoadingCession - 1,
        financial: null
      };
    case GET_MEDICAL_HISTORY:
    case SEND_MEDICAL_HISTORY:
      return {
        ...state,
        isLoadingMedicalHistory: true,
        medicalHistory: null
      };
    case `${GET_MEDICAL_HISTORY}${SUCCESS}`:
      return {
        ...state,
        isLoadingMedicalHistory: false,
        medicalHistory: mapDocuments(payload.data)
      };
    case `${SEND_MEDICAL_HISTORY}${SUCCESS}`:
      return {
        ...state,
        isLoadingMedicalHistory: false
      };
    case `${GET_MEDICAL_HISTORY}${FAIL}`:
    case `${SEND_MEDICAL_HISTORY}${FAIL}`:
      return {
        ...state,
        error: getErrorMessage(error),
        isLoadingMedicalHistory: false
      };
    case `${GET_CESSION_LETTER}${SUCCESS}`:
    case `${CREATE_CESSION_LETTER}${SUCCESS}`:
      return {
        ...state,
        error: null,
        isLoadingCession: state.isLoadingCession - 1,
        cession: payload.data
      };
    case `${GET_CESSION_LETTER}${FAIL}`:
      return {
        ...state,
        error: error?.response?.status === STATUS_CODE.BAD_REQUEST ? null : getErrorMessage(error),
        isLoadingCession: state.isLoadingCession - 1
      };
    case `${CREATE_CESSION_LETTER}${FAIL}`:
      return {
        ...state,
        error: getErrorMessage(error),
        isLoadingCession: state.isLoadingCession - 1
      };
    case CREATE_OPPOSITION_FORM:
      return {
        ...state,
        isLoading: true
      };
    case `${CREATE_OPPOSITION_FORM}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        error: null,
        initialPolicy: {
          ...state.initialPolicy,
          opposition_sent: true
        },
        policy: {
          ...state.policy,
          opposition_sent: true
        }
      };
    case `${CREATE_OPPOSITION_FORM}${FAIL}`:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(error)
      };
    case GET_POLICY:
    case GET_PROPOSAL:
    case SIGN_POLICY:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case `${GET_PROPOSAL}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        error: null,
        initialPolicy: null,
        policy: getPolicyAttributes(payload.data)
      };
    case `${GET_POLICY}${SUCCESS}`:
      const policyAttributes = getPolicyAttributes(payload.data);
      return {
        ...state,
        isLoading: false,
        error: null,
        initialPolicy: cloneDeep(policyAttributes),
        policy: policyAttributes
      };
    case `${SIGN_POLICY}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        initialPolicy: {
          ...state.initialPolicy,
          signatureStatus: payload.data.signatureStatus
        },
        policy: {
          ...state.policy,
          signatureStatus: payload.data.signatureStatus
        }
      };
    case `${GET_PROPOSAL}${FAIL}`:
    case `${GET_POLICY}${FAIL}`:
    case `${SIGN_POLICY}${FAIL}`:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(error)
      };
    case REQUOTE_POLICY:
      return {
        ...state,
        isLoadingRequote: true
      };
    case `${REQUOTE_POLICY}${SUCCESS}`:
      return {
        ...state,
        isLoadingRequote: false
      };
    case `${REQUOTE_POLICY}${FAIL}`:
      return {
        ...state,
        isLoadingRequote: false,
        error: getErrorMessage(error)
      };
    case UPDATE_PROPOSAL:
    case UPDATE_POLICY_PRICE:
      const loadingTag = payload?.tag ? payload.tag[0].toUpperCase() + payload.tag.slice(1) : '';
      return {
        ...state,
        [`isLoading${loadingTag}`]: true
      };
    case UPDATE_POLICY:
      return {
        ...state,
        policy: {
          ...state.policy,
          ...payload
        }
      };
    case FAST_POLICY_PRICE:
      return {
        ...state,
        isLoadingFastPrice: true
      };
    case `${UPDATE_PROPOSAL}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        isLoadingPrice: false,
        isLoadingDate: false,
        isLoadingPayment: false,
        isLoadingInhabitant: false,
        isLoadingValuableItem: false,
        policy: getPolicyAttributes(payload.data)
      };
    case `${UPDATE_POLICY_PRICE}${SUCCESS}`:
      return {
        ...state,
        isLoadingPrice: false,
        isLoadingDate: false,
        isLoadingPayment: false,
        isLoadingInhabitant: false,
        isLoadingValuableItem: false,
        policy: {
          ...state.policy,
          ...getPolicyPrice(payload.data)
        }
      };
    case `${FAST_POLICY_PRICE}${SUCCESS}`:
      return {
        ...state,
        isLoadingFastPrice: false,
        fastPrice: payload.data
      };
    case `${FAST_POLICY_PRICE}${FAIL}`:
      return {
        ...state,
        isLoadingFastPrice: false,
        fastPrice: null
      };
    case 'GO_BACK_UPDATE':
      return {
        ...state,
        updateStep: 1
      };
    case 'FINISH_UPDATE':
      return {
        ...state,
        updateStep: 1
      };
    case ADJUST_POLICY:
    case CONFIRM_POLICY_ADJUSTMENT:
      return {
        ...state,
        isLoadingUpdate: true
      };
    case `${ADJUST_POLICY}${SUCCESS}`:
      return {
        ...state,
        isLoadingUpdate: false,
        updateStep: 2
      };
    case `${CONFIRM_POLICY_ADJUSTMENT}${SUCCESS}`:
      const adjustedPolicyAttributes = getPolicyAttributes(payload.data);
      return {
        ...state,
        isLoadingUpdate: false,
        updateStep: 3,
        sign_url: payload.data.sign_url,
        initialPolicy: cloneDeep(adjustedPolicyAttributes),
        policy: adjustedPolicyAttributes
      };
    case DELETE_PROPOSAL:
      return {
        ...state,
        isLoadingDiscard: true
      };
    case `${DELETE_PROPOSAL}${SUCCESS}`:
      return {
        ...state,
        isLoadingDiscard: false
      };
    case CANCEL_POLICY:
      return {
        ...state,
        isLoadingCancel: true,
        isLoadingConfirmCancel: false
      };
    case `${CANCEL_POLICY}${SUCCESS}`:
      return {
        ...state,
        isLoadingCancel: false
      };
    case CONFIRM_CANCEL_POLICY:
      return {
        ...state,
        isLoadingConfirmCancel: true
      };
    case `${CONFIRM_CANCEL_POLICY}${SUCCESS}`:
      return {
        ...state,
        isLoadingConfirmCancel: false
      };
    case `${CONFIRM_POLICY_ADJUSTMENT}${FAIL}`:
      errorMessage = getErrorMessage(error);
      return {
        ...state,
        isLoadingUpdate: false,
        error: {
          ...errorMessage,
          message:
            errorMessage.code === STATUS_CODE.CONFLICT
              ? { id: 'policy.update_error_pending_mta' }
              : errorMessage.message
        }
      };
    case `${CONFIRM_CANCEL_POLICY}${FAIL}`:
      errorMessage = getErrorMessage(error);
      return {
        ...state,
        isLoadingConfirmCancel: false,
        error: {
          ...errorMessage,
          message:
            errorMessage.code === STATUS_CODE.BAD_REQUEST
              ? { id: 'policy.update_error_code_confirm' }
              : errorMessage.code === STATUS_CODE.CONFLICT
              ? { id: 'policy.cancel_error_pending_mta' }
              : errorMessage.message
        }
      };
    case `${CANCEL_POLICY}${FAIL}`:
    case `${UPDATE_PROPOSAL}${FAIL}`:
    case `${UPDATE_POLICY_PRICE}${FAIL}`:
    case `${DELETE_PROPOSAL}${FAIL}`:
    case `${ADJUST_POLICY}${FAIL}`:
      return {
        ...state,
        isLoading: false,
        isLoadingInhabitant: false,
        isLoadingValuableItem: false,
        isLoadingPrice: false,
        isLoadingCancel: false,
        isLoadingConfirmCancel: false,
        isLoadingUpdate: false,
        isLoadingDiscard: false,
        isLoadingReset: false,
        isLoadingDate: false,
        isLoadingPayment: false,
        error: getErrorMessage(error)
      };
    case SET_LUKO_MTA_WARNING_SHOWN:
      return {
        ...state,
        lukoMtaWarningShown: true
      };
    case CLEAN_ERROR:
      return {
        ...state,
        error: null
      };
    case RESET_POLICY:
      return {
        ...state,
        initialPolicy: null,
        policy: null
      };
    case RESET:
    case RESET_POLICY_STATE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
