import { locale } from '../translations';

import {
  NPS,
  FAIL,
  HELP,
  GET_RECEIPTS,
  GET_REFERRAL_INFO,
  RESET,
  WE_CALL,
  SEND_RECEIPT,
  SEND_REFERRAL,
  RESET_RECEIPTS,
  START_IMPERSONATING,
  STOP_IMPERSONATING,
  FORCE_IMPERSONATE,
  SUCCESS,
  UPDATE_LANGUAGE,
  CLEAN_ERROR,
  SAVE_PLATFORM,
  UPDATE_PREV_PATH,
  GET_USER_POLICIES,
  GET_USER_PROPOSALS,
  GET_NGOS,
  GET_INSURANCE_COMPANIES,
  RESET_POLICIES,
  SAVE_FORCE_UPDATE_APP
} from '../actions/app';
import { PLATFORM_TYPE, POLICY_STATUS } from '../constants';
import { getErrorMessage } from '../utils/getErrorMessage';
import { formatPolicies, formatReceipts } from './apiIntern';

export const initialState = {
  platform: PLATFORM_TYPE.WEB,
  forceUpdateApp: false,
  language: locale,
  isLoading: 0,
  error: null,
  policies: [],
  products: null,
  insuranceCompanies: null,
  receipts: {
    list: null,
    isSending: false,
    result: null
  },
  nps: {
    show: false,
    redirect: null
  },
  help: null,
  ngos: [],
  credit: null,
  referral: null,
  prevPath: null,
  claims: [],
  claim: null
};

const reducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case SAVE_PLATFORM:
      return {
        ...state,
        platform: payload
      };
    case SAVE_FORCE_UPDATE_APP:
      return {
        ...state,
        forceUpdateApp: payload
      };
    case GET_NGOS:
    case GET_USER_POLICIES:
    case GET_USER_PROPOSALS:
    case GET_RECEIPTS:
    case GET_REFERRAL_INFO:
    case WE_CALL:
    case SEND_REFERRAL:
    case START_IMPERSONATING:
    case STOP_IMPERSONATING:
    case GET_INSURANCE_COMPANIES:
      return {
        ...state,
        isLoading: state.isLoading + 1
      };
    case SEND_RECEIPT:
      return {
        ...state,
        receipts: {
          ...state.receipts,
          isSending: true
        }
      };
    case RESET_RECEIPTS:
      return {
        ...state,
        receipts: {
          ...initialState.receipts
        }
      };
    case `${GET_NGOS}${SUCCESS}`:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        ngos: payload.data
      };
    case `${SEND_RECEIPT}${SUCCESS}`:
      return {
        ...state,
        receipts: {
          ...state.receipts,
          isSending: false,
          result: 'success'
        }
      };
    case `${SEND_RECEIPT}${FAIL}`:
      return {
        ...state,
        receipts: {
          ...state.receipts,
          isSending: false,
          result: 'fail'
        }
      };
    case `${GET_RECEIPTS}${SUCCESS}`:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        receipts: {
          ...state.receipts,
          list: formatReceipts(payload.data)
        }
      };
    case `${GET_USER_POLICIES}${SUCCESS}`:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        policies: formatPolicies(payload.data)
      };
    case `${GET_USER_PROPOSALS}${SUCCESS}`:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        policies: state.policies.concat(
          formatPolicies(payload.data.map(proposal => ({ ...proposal, status: POLICY_STATUS.NEW })))
        )
      };
    case 'GET_PRODUCTS_SUCCESS':
      return {
        ...state,
        products: payload.data
      };
    case `${GET_REFERRAL_INFO}${SUCCESS}`:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        referral: payload.data
      };
    case `${GET_INSURANCE_COMPANIES}${SUCCESS}`:
    case `${GET_INSURANCE_COMPANIES}${FAIL}`:
      const companies = payload?.data || null;
      return {
        ...state,
        isLoading: state.isLoading - 1,
        insuranceCompanies: companies
      };
    case `${WE_CALL}${SUCCESS}`:
    case `${SEND_REFERRAL}${SUCCESS}`:
    case `${START_IMPERSONATING}${SUCCESS}`:
    case `${STOP_IMPERSONATING}${SUCCESS}`:
      return {
        ...state,
        isLoading: state.isLoading - 1
      };
    case `${GET_NGOS}${FAIL}`:
    case `${GET_USER_POLICIES}${FAIL}`:
    case `${GET_USER_PROPOSALS}${FAIL}`:
    case 'GET_PRODUCTS_FAIL':
    case `${GET_RECEIPTS}${FAIL}`:
    case `${GET_REFERRAL_INFO}${FAIL}`:
    case `${WE_CALL}${FAIL}`:
    case `${SEND_REFERRAL}${FAIL}`:
    case `${START_IMPERSONATING}${FAIL}`:
    case `${STOP_IMPERSONATING}${FAIL}`:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        error: getErrorMessage(error)
      };
    case UPDATE_LANGUAGE:
      return {
        ...state,
        language: payload.language
      };
    case UPDATE_PREV_PATH:
      return {
        ...state,
        prevPath: payload.path
      };
    case NPS:
      return {
        ...state,
        nps: payload
      };
    case HELP:
      return {
        ...state,
        help: payload
      };
    case FORCE_IMPERSONATE:
      return {
        ...state,
        forceImpersonateValue: payload
      };
    case CLEAN_ERROR:
      return {
        ...state,
        error: null
      };
    case RESET_POLICIES:
      return {
        ...state,
        policies: initialState.policies
      };
    case RESET:
      return {
        ...initialState,
        platform: state.platform,
        isApp: state.isApp,
        language: state.language,
        forceUpdateApp: state.forceUpdateApp
      };
    default:
      return state;
  }
};

export default reducer;
