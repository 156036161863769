import { appendTagToHead } from './appendTagToHead';

export const livechatEventsQueue = (events = []) => {
  if (typeof LLMChatbot !== 'undefined') {
    livechatEvents(events);
  } else {
    window.LLMChatbotEvents = window.LLMChatbotEvents || [];
    window.LLMChatbotEvents = [...window.LLMChatbotEvents, ...events];
  }
};

const livechatEvents = (events = []) => {
  const chatbot = document.getElementById('llm-chatbot-container');
  events.forEach(event => {
    switch (event) {
      case 'init':
        window.LLMChatbotEvents = window.LLMChatbotEvents || [];
        break;
      case 'hide':
        chatbot.style.display = 'none';
        break;
      case 'show':
        chatbot.style.display = 'block';
        break;
      case 'open':
        const bubble = chatbot.querySelector('.llm-chatbot-bubble-minimized');
        bubble && bubble.click();
        const header = chatbot.querySelector('.llm-chatbot-header');
        if (header) {
          header.addEventListener('click', () => livechatEventsQueue(['close', 'hide']));
        }
        break;
      case 'close':
        const form = chatbot.querySelector(
          '.llm-chat-form .llm-chatbot-header .llm-chatbot-close-icon'
        );
        form && form.click();
        break;
      default:
        break;
    }
  });
};

export const initializeLivechat = (email, phone, name) => {
  if (window.LIVECHAT) {
    appendTagToHead('script', {
      id: 'livechat',
      src: `${window.LIVECHAT.DOMAIN}/dist/chatbot.js?t=1715848183`,
      async: 1,
      onload: () => {
        window.LLMChatbot.init({
          id: window.LIVECHAT.ID,
          save_conversation: true,
          variables: { email, phone, name },
          domain: window.LIVECHAT.DOMAIN.replace(/https?:\/\//, ''),
          is_secure: true
        });

        while (window.LLMChatbotEvents.length) {
          const event = window.LLMChatbotEvents.shift();
          livechatEventsQueue([event]);
        }

        const initInterval = setInterval(() => {
          const initialized = document.getElementById('llm-chatbot-container').innerHTML !== '';
          if (initialized) {
            clearInterval(initInterval);
            openLivechat({ email, phone, name });
          }
        }, 100);
      }
    });
  }
};

export const openLivechat = ({ email, phone, name }) => {
  if (typeof window.LLMChatbot === 'undefined') {
    initializeLivechat(email, phone, name);
    return;
  }

  livechatEventsQueue(['show', 'open']);
};
