import axios from 'axios';
import { HOME_OWNERSHIP, PAYMENT_PERIODS, POLICY_STATUS } from '../constants';

export const SEND_ADTRACTION_REQUEST = 'SEND_ADTRACTION_REQUEST';

export const sendAdtractionRequest = (orderId, price, homeOwnership, status, period) => {
  const monthlyPrice = period === PAYMENT_PERIODS.ANNUAL ? (price / 12).toFixed(2) : price;
  const isRenter = homeOwnership === HOME_OWNERSHIP.TENANT;
  const isNew = status === POLICY_STATUS.NEW;
  const at_gd = localStorage.getItem('at_gd');
  if (window.OPTIONAL_FEATURES?.ADTRACTION && at_gd) {
    const domain = isRenter ? 'https://cnv.adt603.net/t/t?' : 'https://cnv.adt690.com/t/t?';
    const otherParmas = '&tk=1&c=EUR&trt=4&ss=1';
    const ap = isRenter ? 1664214440 : 1664213689;
    const am = isNew ? 0 : monthlyPrice;
    const t = isNew ? 4 : 3;
    const ti = isNew ? orderId : 'V-' + orderId;
    const tp = isNew
      ? isRenter
        ? 1664940533
        : 1664214526
      : isRenter
      ? (monthlyPrice < 5 && 1664943189) ||
        (monthlyPrice <= 12 && 1664943544) ||
        (monthlyPrice > 12 && 1664946880)
      : (monthlyPrice < 5 && 1664214623) ||
        (monthlyPrice <= 12 && 1664214716) ||
        (monthlyPrice > 12 && 1664214765);

    axios.get(`${domain}t=${t}${otherParmas}&am=${am}&ti=${ti}&tp=${tp}&ap=${ap}&at_gd=${at_gd}`);
  }
};
