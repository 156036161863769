import Drawer from 'react-drag-drawer';
import styled from 'styled-components/macro';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 85vh;
  min-height: 40vh;
  padding: ${({ theme }) => theme.spacings.small4};
`;

const StyledHeader = styled.div`
  text-align: center;
  width: 100%;
  line-height: 0;

  & > h3 {
    margin: ${({ theme, withImage }) =>
        withImage ? theme.spacings.small3 : theme.spacings.medium1}
      0 0 0;
  }
`;

const StyledClose = styled.i`
  width: 40px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.gray4};
  display: block;
  border-radius: ${({ theme }) => theme.shapes.borderRadiusMedium};
  margin: calc(6px - ${({ theme }) => theme.spacings.small4}) auto
    ${({ theme }) => theme.spacings.small3};
`;

const StyledWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium1};
`;

const StyledActions = styled.div`
  margin: ${({ theme }) => theme.spacings.medium2} 0 0 0;
  min-height: 114px;
  width: 100%;
  & > * {
    width: 100% !important;
    margin: 0;
  }
`;

const CustomDrawer = ({
  headerImage,
  title,
  isOpen = false,
  onRequestClose,
  allowClose = true,
  actions,
  children,
  ...rest
}) => {
  return (
    <Drawer
      open={isOpen}
      onRequestClose={allowClose ? onRequestClose : null}
      modalElementClass="modal-content"
      containerElementClass="modal-container"
      {...rest}
    >
      <StyledContent>
        <div>
          <StyledHeader allowClose={allowClose} withImage={headerImage}>
            {allowClose && <StyledClose />}
            {headerImage}
            {title && <h3>{title}</h3>}
          </StyledHeader>
          <StyledWrapper>{children}</StyledWrapper>
        </div>
        {actions ? <StyledActions>{actions}</StyledActions> : null}
      </StyledContent>
    </Drawer>
  );
};

export default CustomDrawer;
