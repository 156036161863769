import { ID_TYPES } from '../constants';

export const isNifValid = (value, type) => {
  if (!value || !type) return false;

  if (type === ID_TYPES.PASSPORT) return true;

  const str = value.toString().toUpperCase();
  const dniRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
  const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
  const nifRexp = /^[ABCDEFGHJKLMNPQRSUVW]\d{7}[\dA-J]$/;

  if (type === ID_TYPES.NIF && nifRexp.test(str)) {
    const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    const digits = str.substr(1, str.length - 2);
    const letter = str.substr(0, 1);
    const control = str.substr(str.length - 1);
    let sum = 0;
    let digit;

    if (!letter.match(/[A-Z]/)) return false;

    for (let i = 0; i < digits.length; ++i) {
      digit = parseInt(digits[i]);

      if (isNaN(digit)) return false;

      if (i % 2 === 0) {
        digit *= 2;
        if (digit > 9) digit = parseInt(digit / 10) + (digit % 10);
        sum += digit;
      } else {
        sum += digit;
      }
    }

    sum %= 10;
    if (sum !== 0) {
      digit = 10 - sum;
    } else {
      digit = sum;
    }

    if (letter.match(/[ABEH]/)) {
      return String(digit) === control;
    }
    if (letter.match(/[NPQRSW]/)) {
      return letters[digit] === control;
    }

    return String(digit) === control || letters[digit] === control;
  }

  if (
    (type === ID_TYPES.DNI && dniRexp.test(str)) ||
    (type === ID_TYPES.NIE && nieRexp.test(str))
  ) {
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const dni = str.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2');

    const dniControlLetter = str.substr(-1);
    const charIndex = parseInt(dni.substr(0, 8)) % 23;

    return validChars.charAt(charIndex) === dniControlLetter;
  }

  return false;
};
