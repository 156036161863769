import Modal from 'react-modal';
import styled, { useTheme } from 'styled-components/macro';
import { ReactComponent as X } from '../assets/icons/x.svg';

import { useBreakpoint } from '../utils/hooks/useBreakpoint';

const StyledClose = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

const StyledContainer = styled.div`
  position: relative;
`;

const StyledHeaderContainer = styled.div`
  margin: 0 0 ${({ theme }) => theme.spacings.small4};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin: 0 0 ${({ theme }) => theme.spacings.medium1};
  }
`;

const StyledHeader = styled.h3`
  font-weight: ${({ theme }) => theme.global.fontWeightBold};
  text-transform: uppercase;
`;

const StyledActions = styled.div`
  margin: ${({ theme }) => theme.spacings.medium2} 0 0 0;
  & button {
    width: 100%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    & button {
      width: auto;
      min-width: ${({ theme }) => theme.global.maxDesktopWidth};
    }
  }
`;

const sizes = {
  small: '410px',
  medium: '680px',
  large: '1020px'
};

const CustomModal = ({
  allowClose,
  headerImage,
  title,
  children,
  actions,
  hidden,
  size = 'medium',
  ...rest
}) => {
  const screenSize = useBreakpoint();
  const theme = useTheme();

  return (
    <Modal
      ariaHideApp={false}
      style={{
        content: {
          visibility: hidden ? 'hidden' : 'visible',
          borderRadius: '12px',
          width: screenSize === 's' ? 'auto' : sizes[size] || '680px',
          margin: screenSize === 's' ? 'auto 20px' : 'auto',
          padding: screenSize === 's' ? theme.spacings.small3 : theme.spacings.medium1,
          position: 'static',
          maxHeight: '90vh'
        },
        overlay: {
          visibility: hidden ? 'hidden' : 'visible',
          backgroundColor: 'rgba(45, 88, 108, 0.85)',
          display: 'flex',
          flexDirection: 'column',
          zIndex: 9999
        }
      }}
      testId={rest['data-testid']}
      {...rest}
    >
      <StyledContainer>
        {allowClose && (
          <StyledClose onClick={() => rest.onRequestClose()}>
            <X />
          </StyledClose>
        )}
        {(headerImage || title) && (
          <StyledHeaderContainer>
            {headerImage}
            {title && <StyledHeader>{title}</StyledHeader>}
          </StyledHeaderContainer>
        )}
        <div>{children}</div>
        {actions && <StyledActions>{actions}</StyledActions>}
      </StyledContainer>
    </Modal>
  );
};

export default CustomModal;
