export function deleteParamUrl(name) {
  let params = new URLSearchParams(window.location.search);
  params.delete(name);
  params = params.toString();
  window.history.replaceState(
    {},
    '',
    decodeURIComponent(`${window.location.pathname}${params ? `?${params}` : ''}`)
  );
}
