import { getLocalStorage, setLocalStorage } from '../utils';
import { history } from '../App';
import { checkUserIsLogged } from '../Access/actions/access';
import { CANCEL_ACTION_REQUESTS, CANCEL_ALL_ACTION_REQUESTS, PLATFORM_TYPE, POLICY_STATUS } from '../constants';
import { resetPolicyState } from '../Policy/actions/policy';
import PATHS from '../Routes/paths';

export const SUCCESS = '_SUCCESS';
export const FAIL = '_FAIL';
export const RESET = '@@ALL/RESET';

export const BREAKDOWN = '@@APP/BREAKDOWN';
export const GET_NGOS = '@@APP/GET_NGOS';
export const GET_USER_POLICIES = '@@APP/GET_USER_POLICIES';
export const GET_USER_PROPOSALS = '@@APP/GET_USER_PROPOSALS';
export const CHECK_USER_POLICIES = '@@APP/CHECK_USER_POLICIES';
export const CHECK_USER_PROPOSALS = '@@APP/CHECK_USER_PROPOSALS';
export const GET_RECEIPTS = '@@APP/GET_RECEIPTS';
export const RESET_RECEIPTS = '@@APP/RESET_RECEIPTS';
export const GET_REFERRAL_INFO = '@@APP/GET_REFERRAL_INFO';
export const SAVE_PLATFORM = '@@APP/SAVE_PLATFORM';
export const SAVE_FORCE_UPDATE_APP = '@@APP/SAVE_FORCE_UPDATE_APP';
export const SELECT_NGO = '@@APP/SELECT_NGO';
export const SEND_RECEIPT = '@@APP/SEND_RECEIPT';
export const SEND_REFERRAL = '@@APP/SEND_REFERRAL';
export const START_IMPERSONATING = '@@APP/START_IMPERSONATING';
export const STOP_IMPERSONATING = '@@APP/STOP_IMPERSONATING';
export const FORCE_IMPERSONATE = '@@APP/FORCE_IMPERSONATE';
export const UPDATE_LANGUAGE = '@@APP/UPDATE_LANGUAGE';
export const UPDATE_PREV_PATH = '@@APP/UPDATE_PREV_PATH';
export const WE_CALL = '@@APP/WE_CALL';
export const CLEAN_ERROR = 'CLEAN_ERROR';
export const NPS = '@@APP/NPS';
export const HELP = '@@APP/HELP';
export const GET_INSURANCE_COMPANIES = 'GET_INSURANCE_COMPANIES';
export const RESET_POLICIES = '@@APP/RESET_POLICIES';

/**
 * @param {null} redirect - close
 * @param {String} redirect - PATHS.xxx to close and redirect
 */
export const showNPS = (show = true, redirect = null) => ({
  type: NPS,
  payload: { show, redirect }
});

export const showHelp = (show = true) => ({
  type: HELP,
  payload: show
});

export const initApplication = () => async dispatch => {
  dispatch(checkUserIsLogged());
};

export const savePlatform = (payload = PLATFORM_TYPE.WEB) => ({
  type: SAVE_PLATFORM,
  payload
});

export const saveForceUpdateApp = (payload = false) => ({
  type: SAVE_FORCE_UPDATE_APP,
  payload
});

export const getInsuranceCompanies = () => ({
  type: GET_INSURANCE_COMPANIES,
  payload: {
    request: {
      method: 'GET',
      url: '/insurance'
    }
  }
});

export const getUserPolicies = state => ({
  type: state ? GET_USER_POLICIES : CHECK_USER_POLICIES,
  payload: {
    request: {
      method: 'GET',
      url: '/v2/policy'
    }
  }
});

export const getUserProposals = state => ({
  type: state ? GET_USER_PROPOSALS : CHECK_USER_PROPOSALS,
  payload: {
    request: {
      method: 'GET',
      url: '/v1/proposal'
    }
  }
});

/**
 * @param {state} state: if false not modify the state (used in useHasPolicies hook)
 * @returns object
 */
export const onGetUserPolicies =
  (getProposals = true, state = true) =>
  async dispatch => {
    let response = [];
    const policies = await dispatch(getUserPolicies(state));
    if (policies) {
      if (policies?.payload?.data?.length > 0) {
        response = policies?.payload?.data;
      }
      if (getProposals) {
        const proposals = await dispatch(getUserProposals(state));
        if (proposals.payload?.data?.length > 0) {
          response.push(
            ...proposals.payload.data.map(proposal => {
              return { ...proposal, status: POLICY_STATUS.NEW };
            })
          );
        }
      }
    }
    return response;
  };

export const getReceipts = (customerId = null, policyId = null) => ({
  type: GET_RECEIPTS,
  payload: {
    request: {
      method: 'GET',
      url: customerId ? `/v2/receipts/${customerId}` : '/v2/receipts',
      params: { policyId }
    }
  }
});

export const sendReceipt = (customerId, serial) => ({
  type: SEND_RECEIPT,
  payload: {
    request: {
      method: 'POST',
      url: `/v2/receipts/${customerId}/collectionGroup/${serial}/email`
    }
  }
});

export const resetReceipts = () => ({
  type: RESET_RECEIPTS,
  payload: null
});

export const resetPolicies = () => ({
  type: RESET_POLICIES,
  payload: null
});

export const getNGOs = () => ({
  type: GET_NGOS,
  payload: {
    request: {
      method: 'GET',
      url: '/ngo'
    }
  }
});

export const selectNGO = ngo => ({
  type: SELECT_NGO,
  payload: {
    request: {
      method: 'POST',
      url: '/v2/users/changeNgo',
      params: {
        ngo
      }
    }
  }
});

export const onSelectNGO = ngo => async dispatch => {
  const action = await dispatch(selectNGO(ngo));
  if (action.type === `${SELECT_NGO}${SUCCESS}`) {
    history.push(PATHS.home);
  }
};

export const getReferralInfo = () => ({
  type: GET_REFERRAL_INFO,
  payload: {
    request: {
      method: 'GET',
      url: '/mgm/referral'
    }
  }
});

export const onSendReferral =
  (data, callback, updateReferralInfo = false) =>
  async dispatch => {
    const action = await dispatch(sendReferral(data));
    if (action.type === `${SEND_REFERRAL}${SUCCESS}`) {
      updateReferralInfo && dispatch(getReferralInfo());
      callback && callback();
      return true;
    }
    return false;
  };

export const sendReferral = data => ({
  type: SEND_REFERRAL,
  payload: {
    request: {
      method: 'POST',
      url: '/mgm/referral',
      data
    }
  }
});

export const emergencyBreakdown = () => async dispatch => {
  const action = await dispatch({
    type: BREAKDOWN,
    payload: {
      request: {
        method: 'POST',
        url: '/users/emergencyBreakdown'
      }
    }
  });
  return action.type === `${BREAKDOWN}${SUCCESS}`;
};

export const onUpdateLanguage =
  (lang, resetPolicy = false) =>
  async dispatch => {
    if (lang && lang !== getLocalStorage('ulang')) {
      await dispatch(updateLanguage(lang));
      setLocalStorage('ulang', lang);
      resetPolicy && (await dispatch(resetPolicyState()));
    }
  };

export const updateLanguage = language => ({
  type: UPDATE_LANGUAGE,
  payload: {
    language
  }
});

export const updatePrevPath = path => ({
  type: UPDATE_PREV_PATH,
  payload: {
    path
  }
});

export const reset = () => ({
  type: RESET
});

export const onStartImpersonating = phone => async dispatch => {
  const action = await dispatch(startImpersonating(phone));
  if (action.type === `${START_IMPERSONATING}${SUCCESS}`) {
    await dispatch(reset());
    await dispatch(checkUserIsLogged());
    return true;
  }
  return false;
};

export const startImpersonating = phone => ({
  type: START_IMPERSONATING,
  payload: {
    request: {
      method: 'POST',
      url: '/v2/users/impersonate/start',
      params: {
        phone
      }
    }
  }
});

export const onStopImpersonating = () => async dispatch => {
  const action = await dispatch(stopImpersonating());
  if (action.type === `${STOP_IMPERSONATING}${SUCCESS}`) {
    await dispatch(reset());
    await dispatch(checkUserIsLogged());
    return true;
  }
  return false;
};

export const stopImpersonating = () => ({
  type: STOP_IMPERSONATING,
  payload: {
    request: {
      method: 'POST',
      url: '/users/impersonate/stop'
    }
  }
});

export const forceImpersonate = value => ({
  type: FORCE_IMPERSONATE,
  payload: value
});

export const cancelActionRequest = actionType => ({ type: CANCEL_ACTION_REQUESTS, actionType });

export const cancelAllActionRequests = () => ({ type: CANCEL_ALL_ACTION_REQUESTS });

export const cleanError = () => ({
  type: CLEAN_ERROR,
  payload: null
});
