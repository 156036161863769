export const GET_VIRTUAL_VET_CREDENTIALS = '@@VIRTUAL_VET/GET_VIRTUAL_VET_CREDENTIALS';
export const CLEAN_ERROR = '@@VIRTUAL_VET/CLEAN_ERROR';
export const MEETING_DOCTORS_INIT_ERROR = '@@VIRTUAL_VET/MEETING_DOCTORS_INIT_ERROR';

export const getVirtualVetCredentials = () => {
  return {
    type: GET_VIRTUAL_VET_CREDENTIALS,
    payload: {
      request: {
        method: 'GET',
        url: '/v1/external/meeting-doctors/credentials'
      }
    }
  };
};

export const cleanError = () => ({
  type: CLEAN_ERROR,
  payload: null
});
