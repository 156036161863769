import {
  PAYMENT_PERIODS,
  USES_DESCRIPTION,
  FLAMMABLE_MATERIALS,
  PRODUCT_TYPES,
  SPORT_LEVELS,
  PRODUCT_TYPE_LABELS,
  PRODUCT_OPTIONS,
  CUSTOMER_TYPE,
  PETS
} from '../../constants';
import { getDateAsString, getValidFromDate } from '../../utils';
import { objectBooleansToArray } from '../../utils/objectBooleansToArray';

export const removeAddressesWithInvalidCodVia = data =>
  (data || []).filter(d => Number.isInteger(Number(d.codVia)));

export const getFullAddress = data => {
  const fullAddress = {};

  !!data.tipoVia && (fullAddress.streetType = data.tipoVia);
  !!data.codVia && (fullAddress.streetCode = data.codVia);
  !!data.via && (fullAddress.street = data.via);
  !!data.cp && (fullAddress.postalCode = data.cp);
  !!data.codIne && (fullAddress.ineCode = data.codIne);
  !!data.codMun && (fullAddress.munCode = data.codMun);
  !!data.municipio && (fullAddress.municipality = data.municipio);
  !!data.codProv && (fullAddress.provCode = data.codProv);
  !!data.provincia && (fullAddress.province = data.provincia);
  !!data.referenciaCatastral && (fullAddress.cadastralRef = data.referenciaCatastral);

  if (!!data.coordenadas) {
    const [lat, lng] = data.coordenadas.split(', ');
    fullAddress.latitude = Number(lat);
    fullAddress.longitude = Number(lng);
  }

  return fullAddress;
};

export const getCreateUserData = (data, language) => ({
  first_name: data.firstName,
  last_name: data.lastName,
  email: data.email,
  phone: data.phone,
  lang: language,
  birth_date: data.birthdate,
  document_type: data.documentType,
  document_number: data.documentNumber,
  commercial_consent: true,
  communications_consent: true
});

export const getCreateCustomerData = data => {
  const type = data.customerType.toLowerCase();
  const isPerson = data.customerType === CUSTOMER_TYPE.PERSON;

  const payload = {
    email: data.email,
    phone: data.phone,
    [type]: {
      [isPerson ? 'first_name' : 'company_name']: isPerson ? data.firstName : data?.companyName,
      [isPerson ? 'last_name' : 'company_type']: isPerson ? data?.lastName : data?.companyType,
      birth_date: isPerson ? data?.birthdate : undefined,
      [`document_${type}_type`]: data?.documentType
    },
    document_number: data.documentNumber,
    commercial_consent: true,
    communications_consent: true,
    force_create_new_customer: true
  };

  // Customer type = PERSON
  !!data.firstName && (payload.first_name = data.firstName);
  !!data.lastName && (payload.last_name = data.lastName);
  !!data.birthdate && (payload.birth_date = data.birthdate);

  // Customer type = COMPANY
  !!data.companyName && (payload.company_name = data.companyName);
  !!data.companyType && (payload.company_type = data.companyType);

  // Document (only from fast track)
  !!data.documentType && (payload.document_type = data.documentType);
  !!data.documentNumber && (payload.document_number = data.documentNumber);

  return payload;
};

const getBeneficiaryList = (beneficiaries = []) => {
  return beneficiaries?.map(beneficiary => {
    const { btype, email = null, phone = null, ...data } = beneficiary;
    return {
      email,
      phone,
      [btype.toLowerCase()]: data
    };
  });
};

const getCovers = (data, fastPolicy) => {
  const productData = data[data.product];
  if (
    data.product === PRODUCT_TYPE_LABELS[PRODUCT_TYPES.HOME] &&
    fastPolicy &&
    fastPolicy.home_ownership !== USES_DESCRIPTION[productData.use].ownership
  ) {
    // If the user changes the data that affects the covers, we reset them
    productData.covers = null;
  }

  return productData?.covers || [];
};

export const getHomeProposalData = (data, fastPolicy) => {
  if (
    fastPolicy &&
    (fastPolicy.surface !== data.area ||
      fastPolicy.address.province_code !== data.fullAddress.provCode.toString() ||
      fastPolicy.housing_type !== data.housingType ||
      fastPolicy.home_ownership !== USES_DESCRIPTION[data.use].ownership)
  ) {
    // If the user changes the data that affects the capital limits, we reset the capitals
    data.capitalContinent = null;
    data.capitalContent = null;
  }

  return {
    apartment: data.letter || '',
    building: data.block || '',
    cadastre_ref: data.cadastralRef,
    content: data.capitalContent || null,
    continent: data.capitalContinent || null,
    liability: data.civilLiability || null,
    construction_year: +data.constructionYear,
    floor_number: data.floor || '',
    home_ownership: USES_DESCRIPTION[data.use].ownership,
    house_utilization: USES_DESCRIPTION[data.use].utilization,
    housing_construction_material: FLAMMABLE_MATERIALS[data.isFlammable],
    housing_type: data.housingType,
    latitude: data.fullAddress.latitude,
    longitude: data.fullAddress.longitude,
    municipality_name: data.fullAddress.municipality,
    municipality_code: data.fullAddress.munCode.toString(),
    number: data.number,
    postal_code: data.fullAddress.postalCode,
    province_code: data.fullAddress.provCode.toString(),
    securities: objectBooleansToArray(data.securities || {}),
    stairway: data.stairway || '',
    street: data.fullAddress.street,
    street_type: data.fullAddress.streetType,
    surface: +data.area
  };
};

const getLifeProposalData = data => {
  const sports = data?.sports || [];
  const [profession] = data.profession;
  return {
    height: data.height,
    weight: data.weight,
    sex: data.gender,
    profession,
    beneficiaries: getBeneficiaryList(data?.beneficiaries),
    sports: sports.map(sport => ({ sport, level: SPORT_LEVELS.AMATEUR })),
    first_name: data.firstName,
    last_name: data.lastName,
    birthdate: data.birthdate,
    life_capital: data.insuredCapital,
    document_type: data.documentType,
    document_number: data.documentNumber,
    motorbike_use: data.drive,
    smoking_habits: data.smokingHabit,
    question_medicated: !!data.medicalTreatments,
    question_disability: !!data.disability,
    question_tests: !!data.pendingMedicalExam,
    question_cancer: !!data.cancer,
    question_sickness: !!data.diseases,
    question_psychiatric: !!data.psychiatricCare,
    question_senses: !!data.visualSkills || !!data.auditorySkills,
    question_drugs: !!data.drugUse,
    job_status: data.employment,
    previous_insurance: data.insuranceStatus,
    risky_activities: data?.riskyActivities || [],
    fiscal_residence_country: data?.taxResidence ? 'ES' : '',
    automatic_capital_increase: true
  };
};

export const getPetsProposalData = data => {
  const breedField = `${data.type?.toLowerCase()}_breed${data.type === PETS.TYPE.DOG ? 's' : ''}`;
  const birthdate = new Date();
  birthdate.setMonth(birthdate.getMonth() - data.months);
  birthdate.setFullYear(birthdate.getFullYear() - data.years);
  return {
    ...(data.type && { type: data.type }),
    ...(data.sex && { sex: data.sex }),
    ...(data.name && { name: data.name }),
    ...(data.months && data.years && { birth_date: getDateAsString(birthdate, '-', true) }),
    ...(data.type && { [breedField]: data.type === PETS.TYPE.DOG ? data.breed : data.breed[0] }),
    ...(data.type === PETS.TYPE.DOG && {
      dangerous: data.dangerous === 'true',
      mixed_breed:
        data.mixedBreed === PETS.MIXED_BREED.YES || data.mixedBreed === PETS.MIXED_BREED.UNKNOWN,
      size: PETS.SIZE[data.size]
    }),
    ...(data.chip && { chip_code: data.chip })
  };
};

export const getCreateProposalData = (customer, data, fastPolicy, customers = []) => {
  let proposalData = {};
  let product_option = null;

  switch (data.product) {
    case PRODUCT_TYPE_LABELS[PRODUCT_TYPES.HOME]:
      product_option = PRODUCT_OPTIONS[data.product][data.home.policyType][data.home.use];
      proposalData = getHomeProposalData(data.home, fastPolicy);
      break;
    case PRODUCT_TYPE_LABELS[PRODUCT_TYPES.LIFE]:
      const quoteCustomer = customers.find(({ id }) => id === data?.customerId);

      product_option = PRODUCT_OPTIONS[data.product][data.life.policyType];
      if (quoteCustomer) {
        !customer.birthdate && (customer.birthdate = quoteCustomer.birthdate);
        !customer.documentType && (customer.documentType = quoteCustomer.documentType);
        !customer.documentNumber && (customer.documentNumber = quoteCustomer.documentNumber);
      }

      proposalData = getLifeProposalData({ ...customer, ...data.life });
      break;
    case PRODUCT_TYPE_LABELS[PRODUCT_TYPES.PETS]:
      product_option = PRODUCT_OPTIONS[data.product][data.pets.policyType];
      proposalData = getPetsProposalData({ ...data.pets });
      break;
    default:
      break;
  }

  const payload = {
    interval: data?.interval ?? PAYMENT_PERIODS.MONTHLY,
    covers: getCovers(data, fastPolicy),
    customer_id: customer.id,
    product_option,
    valid_from: getValidFromDate(),
    [data.product]: {
      [data[data.product].policyType]: proposalData
    }
  };

  return payload;
};

export const getPopulation = data => {
  const population = [];

  data.forEach(mun => {
    const { cod_municipality, municipality, cod_province, province_name: province } = mun;

    if (!population.find(p => p.cod_province === cod_province)) {
      population.push({ cod_province, province, municipalities: [] });
    }

    population
      .find(p => p.cod_province === cod_province)
      .municipalities.push({ cod_municipality, municipality });
  });

  return population;
};

export const extractPopulationAddress = population => {
  let address = null;
  if (population && population.length === 1) {
    address = {
      provCode: population[0].cod_province,
      province: population[0].province
    };
    if (population[0].municipalities.length === 1) {
      address.munCode = population[0].municipalities[0].cod_municipality;
      address.municipality = population[0].municipalities[0].municipality;
    }
  }
  return address;
};

export const getFastPolicy = data => {
  const product = Object.values(PRODUCT_TYPE_LABELS).find(product => data[product.toLowerCase()]);
  const policyInfo = Object.values(data[product]).find(option => option);

  const fastPolicy = {
    isLoading: false,
    ...data,
    ...policyInfo,
    cover_groups: (data?.cover_groups || []).filter(cover => !cover.pack)
  };

  delete fastPolicy[product];
  return fastPolicy;
};
