import { CUSTOMER_TYPE } from '../constants';
import { getBusinessTypeString } from './getBusinessTypeString';

export const getCustomerFullName = (intl, customer) => {
  if (!customer) return '';

  return customer.customerType === CUSTOMER_TYPE.PERSON
    ? `${customer.firstName} ${customer.lastName}`
    : `${customer.companyName} ${intl.formatMessage({
        id: getBusinessTypeString(customer.companyType)
      })}`;
};
