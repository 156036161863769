import { getCustomerInfo } from '../../Access/reducers/apiIntern';
import { getHomeProposalData, getPetsProposalData } from '../../Estimate/reducers/apiIntern';
import {
  CAPITAL_CODES,
  FLAMMABLE,
  HOME_OWNERSHIP,
  HOUSE_UTILIZATION,
  LIFE_COVERS,
  PAYMENT_PERIODS,
  PETS,
  POLICY_STATUS,
  PRODUCTS,
  PRODUCT_LABELS,
  PRODUCT_OPTIONS,
  PRODUCT_TYPES,
  PRODUCT_TYPE_LABELS,
  SECURITY,
  USES,
  VALUE_TYPE
} from '../../constants';
import { getStringAsDate, getValidFromDate, isEmptyObject } from '../../utils';

export const getPolicyPrice = policy => ({
  prices: {
    [PAYMENT_PERIODS.MONTHLY]:
      policy.period === PAYMENT_PERIODS.ANNUAL
        ? Math.round((policy.price / 12) * 100) / 100
        : Math.round(policy.price * 100) / 100,
    [PAYMENT_PERIODS.ANNUAL]:
      policy.period === PAYMENT_PERIODS.MONTHLY
        ? Math.round(policy.price * 12 * 100) / 100
        : Math.round(policy.price * 100) / 100
  }
});

export const isPolicyEditable = policy => (
  policy.status !== POLICY_STATUS.INACTIVE &&
  policy.status !== POLICY_STATUS.SUSPENDED &&
  policy.product !== PRODUCTS.MURIMAR &&
  !PRODUCT_OPTIONS.MIGRATED.includes(policy.product_option)
);

export const isPolicyValid = policy => {
  const now = Date.now();
  return (
    policy.status === POLICY_STATUS.ACTIVE ||
    policy.status === POLICY_STATUS.SUSPENDED ||
    (policy.status === POLICY_STATUS.INACTIVE &&
      now < getStringAsDate(policy.valid_to).getTime() + 86400000)
  );
};

export const mapValuableItems = categories =>
  categories.map(category => {
    if (!category.values) {
      return { ...category, values: [] };
    }

    const values = category.values.map(item => ({ ...item, type: category.code }));

    return { ...category, values };
  });

export const mapHouseUse = (ownership, utilization) => {
  switch (ownership) {
    case HOME_OWNERSHIP.TENANT:
      return USES.RENTER;
    case HOME_OWNERSHIP.MINE_RENTED:
      return USES.OWNER_RENT;
    case HOME_OWNERSHIP.MINE:
      return utilization === HOUSE_UTILIZATION.SECONDARY
        ? USES.OWNER_TEMPORARY
        : USES.OWNER_PERMANENT;
    default:
      return USES.OWNER_PERMANENT;
  }
};

export const getPolicyAttributes = policy => {
  const extraCoverConfig = [
    { code: 'VALUABLES', order: 1 },
    { code: 'ADD_INHABITANTS', order: 2, title: true },
    { code: 'BURGLARY_EXTENDED', order: 3, title: true },
    { code: 'COMP_ACC_DAMAGE', order: 4 }
  ];

  const isHome = policy.business_line === PRODUCT_TYPES.HOME;
  const isLife = policy.business_line === PRODUCT_TYPES.LIFE;
  const isPets = policy.business_line === PRODUCT_TYPES.PETS;

  const product = PRODUCT_TYPE_LABELS[policy.business_line];
  const policyInfo = Object.values(policy[product]).find(option => option);

  const securities = Object.keys(SECURITY).reduce((obj, measure) => {
    obj[SECURITY[measure]] = policyInfo?.securities?.includes(SECURITY[measure]) ? true : false;
    return obj;
  }, {});
  let newPolicy = {
    ...policy,
    ...policyInfo,
    ...getPolicyPrice(policy),
    id: policy.id,
    editable: isPolicyEditable(policy),
    valid: isPolicyValid(policy),
    customer: getCustomerInfo(policy.customer),
    capitals: [],
    covers: policy.cover_groups
      .map((cover) => {
        cover.label = cover.global_alias;
        cover.description = cover.global_description;
        cover.image = null;
        cover.more_info = false;
        cover.isContent = false;
        delete cover.global_alias;
        delete cover.global_description;
        if ((!cover.label || !cover.description || !cover.image) && cover?.content?.length > 0) {
          cover.content = cover.content.map((content, it) => {
            const newContent = {
              ...content,
              code:
                content.code ||
                `${cover.code}_${
                  content.alias ? content.alias.toUpperCase().replaceAll(' ', '_') : it
                }`,
              label: content.label || content.alias,
              image:
                typeof content.image === 'string' && content.image.startsWith('{')
                  ? JSON.parse(content.image)
                  : content.image,
              isContent: true
            };
            delete newContent.alias;
            newContent.more_info =
              newContent.covered.length > 0 || newContent.not_covered.length > 0;
            if (newContent.more_info) {
              cover.more_info = true;
            }
            return newContent;
          });
          cover.label = cover.label || cover.content[0].label || '';
          cover.description = cover.description || cover.content[0].description || '';
          cover.image = cover.image || cover.content[0].image || '';
        }

        const extraConfig = extraCoverConfig.find(element => element.code === cover.code);
        cover.order = extraConfig?.order
          ? extraCoverConfig.order
          : cover.id + extraCoverConfig.length;
        cover.title = extraConfig?.title ? `policy.extra_cover_title.${cover.code}` : null;
        return cover;
      })
      .sort((a, b) => {
        if (a.order === b.order) {
          return 0;
        }
        return a.order < b.order ? -1 : 1;
      })
  };

  if (isHome) {
    newPolicy = {
      ...newPolicy,
      address: policy.policy_description.join(' '),
      quote: {
        fullAddress: {
          streetType: policyInfo?.address?.street_type || '',
          street: policyInfo?.address?.street_name || '',
          municipality: policyInfo?.address?.municipality || '',
          munCode: policyInfo?.address?.municipality_code || '',
          province: policyInfo?.address?.province || '',
          provCode: policyInfo?.address?.province_code || '',
          postalCode: policyInfo?.address?.zip_code || ''
        },
        number: policyInfo?.address?.number || '',
        block: policyInfo?.address.building || '',
        floor: policyInfo?.address?.floor || '',
        letter: policyInfo?.address?.apartment || '',
        stairway: policyInfo.address.stairway || '',
        constructionYear: policyInfo?.construction_year?.toString() || '',
        area: policyInfo?.address?.surface?.toString() || '',
        housingType: policyInfo?.housing_type,
        homeOwnership: policyInfo?.home_ownership,
        use: mapHouseUse(policyInfo?.home_ownership, policyInfo?.house_utilization),
        securities,
        isFlammable: FLAMMABLE.NOT
      }
    };
  }

  if (isLife) {
    newPolicy = {
      ...newPolicy,
      quote: {
        ...policyInfo
      }
    };
  }

  if (isPets) {
    const diffDate = new Date(Date.now() - new Date(policyInfo.birth_date));

    newPolicy = {
      ...newPolicy,
      quote: {
        type: policyInfo.type || '',
        sex: policyInfo.sex || '',
        years: diffDate.getFullYear() - 1970,
        months: diffDate.getMonth(),
        breed: policyInfo.type === PETS.TYPE.DOG ? policyInfo.dog_breeds : [policyInfo.cat_breed],
        mixed_breed: policyInfo.mixed_breed || '',
        size: policyInfo.size || '',
        name: policyInfo.name || '',
        dangerous: policyInfo.dangerous || false,
        chip: policyInfo.chip_code || ''
      }
    };
  }

  if (policyInfo?.capitals?.length > 0) {
    newPolicy.capitals = [...policyInfo.capitals];
  }
  if (policyInfo?.liability) {
    newPolicy.capitals.push(policyInfo.liability);
  }
  newPolicy.capitals.sort((a, b) => {
    if (a.code === b.code) return 0;
    return a.code > b.code ? 1 : -1;
  });

  if (policyInfo?.valuable_items) {
    newPolicy.valuable_items.code = 'VALUABLES';
    newPolicy.valuable_items.values = mapValuableItems(policyInfo.valuable_items.values);
  }
  if (policyInfo?.additional_inhabitants) {
    newPolicy.additional_inhabitants.code = 'ADD_INHABITANTS';
  }

  delete newPolicy.cover_groups;
  delete newPolicy[product];
  return newPolicy;
};

export const getPolicyChanges = (policy, data = {}) => {
  const { covers, ...policyData } = data;
  let petsData = {};
  if (policy.business_line === PRODUCT_TYPES.PETS) {
    const breedField = `${policy.type?.toLowerCase()}_breed${
      policy.type === PETS.TYPE.DOG ? 's' : ''
    }`;
    const { name, type, sex, birth_date, dangerous, mixed_breed, size } = policy;
    const breedValue =
      type === PETS.TYPE.DOG ? policy[breedField]?.map(b => b.code) : policy[breedField]?.code;
    petsData = {
      name,
      type,
      sex,
      birth_date,
      dangerous,
      [breedField]: breedValue,
      mixed_breed,
      size
    };
    const newData = !isEmptyObject(data) && getPetsProposalData(data);
    petsData = { ...petsData, ...newData };
  }
  let policyChanges = {
    [PRODUCT_TYPE_LABELS[policy.business_line]]: {
      [PRODUCT_LABELS[policy.product]]: {
        content:
          policy.capitals.find(capital => capital.code === CAPITAL_CODES.CONTENT)?.amount || null,
        continent:
          policy.capitals.find(capital => capital.code === CAPITAL_CODES.CONTINENT)?.amount || null,
        liability:
          policy.capitals.find(capital => capital.code === CAPITAL_CODES.LIABILITY)?.amount || null,
        life_capital:
          policy.capitals.find(capital => capital.code === CAPITAL_CODES.LIFE)?.amount || null,
        additional_inhabitants: policy.additional_inhabitants
          ? policy.additional_inhabitants.values
          : null,
        valuable_items: policy.valuable_items
          ? policy.valuable_items.values.reduce((acc, item) => {
              if (item.values.length) {
                return [...acc, ...item.values];
              }
              return acc;
            }, [])
          : null,
        ...policyData,
        ...petsData,
        automatic_capital_increase:
          typeof policyData?.automatic_capital_increase === VALUE_TYPE.BOOLEAN
            ? policyData?.automatic_capital_increase
            : policy.automatic_capital_increase,
        beneficiaries: policyData?.beneficiaries || policy.beneficiaries
      }
    },
    interval: data?.interval || policy.period,
    product_option: data?.product_option,
    valid_from: data?.valid_from
  };

  if (covers !== undefined) {
    policyChanges.covers = covers.filter(c => c !== LIFE_COVERS.V2023_CAPITAL_REVALUATION);
  } else {
    policyChanges.covers = policy.covers
      .filter(cover => cover.extra && cover.active)
      .map(cover => cover.code);
  }

  if (policy.business_line === PRODUCT_TYPES.PETS && petsData.type === PETS.TYPE.CAT) {
    delete policyChanges[PRODUCT_TYPE_LABELS[policy.business_line]][PRODUCT_LABELS[policy.product]]
      .dangerous;
  }

  // Remove temporal ids
  ['additional_inhabitants', 'valuable_items'].forEach(key => {
    if (policyChanges[key]?.length) {
      policyChanges[key].forEach((item, i) => {
        if (typeof item.id === 'string' && item.id.startsWith('temp_')) {
          delete policyChanges[key][i].id;
        }
      });
    }
  });

  return policyChanges;
};

export const getPolicyData = policy => {
  const businessLine = PRODUCT_TYPE_LABELS[policy.business_line];
  const product = PRODUCT_LABELS[policy.product];

  // This is currently only mapping home policies because life policies aren't requoted
  return {
    interval: policy.period,
    covers: policy.covers.filter(cover => cover.extra && cover.active).map(cover => cover.code),
    customer_id: policy.customer.id,
    product_option: policy.product_option,
    valid_from: getValidFromDate(),
    [businessLine]: {
      [product]: {
        ...policy,
        ...getHomeProposalData(policy.quote),
        content: policy.capitals.find(capital => capital.code === CAPITAL_CODES.CONTENT)?.amount,
        continent: policy.capitals.find(capital => capital.code === CAPITAL_CODES.CONTINENT)
          ?.amount,
        liability: policy.capitals.find(capital => capital.code === CAPITAL_CODES.LIABILITY)
          ?.amount,
        valuable_items: policy.valuable_items.values.reduce((acc, item) => {
          if (item.values.length) {
            return [...acc, ...item.values];
          }
          return acc;
        }, []),
        additional_inhabitants: policy.additional_inhabitants.values
      }
    }
  };
};

export const mapDocuments = documents =>
  documents.map(({ file_name: name, ...data }) => ({ ...data, name }));
