import { useState, useEffect } from 'react';

const getDeviceConfig = width => {
  if (width <= 767) {
    return 's'; // mobile
  } else if (width >= 768 && width <= 1023) {
    return 'm'; // tablet vertical
  } else if (width >= 1024 && width <= 1535) {
    return 'l'; // tablet horizontal - small screen
  } else if (width >= 1536 && width <= 1919) {
    return 'xl'; // desktop
  } else if (width >= 1920) {
    return 'xxl'; // desktop retina ++
  }
};

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() => getDeviceConfig(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = () => {
      setBreakpoint(getDeviceConfig(window.innerWidth));
    };
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return breakpoint;
};
