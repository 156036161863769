export const padTo2Digits = num => num.toString().padStart(2, '0');

export const getDateAsString = (date = new Date(), separator = '/', inverseOrder = false) => {
  if (inverseOrder) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate())
    ].join(separator);
  } else {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear()
    ].join(separator);
  }
};

export const getDateFormatted = value => {
  const date = new Date(value);
  return !value || Number.isNaN(date.getTime())
    ? null
    : `${value.split('-')[0]}-${value.split('-')[1]}-${value.split('-')[2].substring(0, 2)}`;
};

export const getStringAsDate = string => {
  const time = Date.parse(string);
  return Number.isNaN(time) ? null : new Date(time);
};
