import { CLAIM_DOC_SUBTYPES, CLAIM_STATUS, CLAIM_SUB_STATUS } from '../../constants';
import { getDateFormatted } from '../../utils';

export const getClaimStatus = (status, subStatus) => {
  switch (true) {
    case subStatus === CLAIM_SUB_STATUS.COLLECTING_INFO:
      return CLAIM_SUB_STATUS.COLLECTING_INFO;
    case subStatus === CLAIM_SUB_STATUS.PENDING_CLIENT_INFORMATION:
      return CLAIM_SUB_STATUS.PENDING_CLIENT_INFORMATION;
    case status === CLAIM_STATUS.NEW ||
      status === CLAIM_STATUS.PENDING_PROCESSING ||
      status === CLAIM_STATUS.IN_PROGRESS:
      return CLAIM_STATUS.IN_PROGRESS;
    case status === CLAIM_STATUS.PENDING_CLOSURE ||
      status === CLAIM_STATUS.CLOSED ||
      status === CLAIM_STATUS.REFUSED:
      return CLAIM_STATUS.CLOSED;
    default:
      return '';
  }
};

export const getClaimData = claim => {
  return {
    id: claim.id,
    status: getClaimStatus(claim.status, claim.sub_status),
    type: claim.category,
    damageType: claim.damage_type,
    date: claim.effect_date,
    honestSigned: !!claim.documents.find(
      doc => doc.document_sub_type === CLAIM_DOC_SUBTYPES.HONESTITY_SIGNATURE
    ),
    documents:
      claim.documents
        .filter(doc => doc.document_sub_type !== CLAIM_DOC_SUBTYPES.HONESTITY_SIGNATURE)
        .map(({ original_filename, document_type, document_sub_type, ...doc }) => ({
          ...doc,
          name: original_filename,
          type: document_type,
          subtype: document_sub_type
        })) || [],
    abroad: !!claim.documents.find(
      doc => doc.document_sub_type === CLAIM_DOC_SUBTYPES.PET_TRAVEL_RECEIPT
    ),
    description: claim.client_comments,
    policyId: claim.policy_id,
    houseOccupation: claim.policy_info.house_occupation,
    policyStartDate: claim.policy_info.policy_valid_from,
    createdDate: claim.statement_date,
    contact: claim.contact
  };
};

export const getClaimPayload = claim => {
  return {
    id: claim.id,
    category: claim.type,
    effect_date: claim.date,
    damage_type: claim.damageType,
    client_comments: claim.description
  };
};

export const getCleanClaims = data =>
  data.items
    .filter(d => d.damage_type)
    .map(d => ({
      isOpen:
        [CLAIM_STATUS.NEW, CLAIM_STATUS.PENDING_PROCESSING, CLAIM_STATUS.IN_PROGRESS].includes(
          d.status
        ) || d.sub_status === CLAIM_SUB_STATUS.PENDING_CLIENT_INFORMATION,
      id: d.id,
      tag: d.id,
      date: d.effect_date,
      damageType: d.damage_type,
      status: getClaimStatus(d.status, d.sub_status),
      policyId: d.policy_id,
      createdDate: getDateFormatted(d.created_at)
    }));
