import { SUCCESS } from '../../actions/app';
import { history } from '../../App';
import { CLAIM_SUB_STATUS, PRODUCT_TYPES } from '../../constants';
import { getClaimPayload } from '../reducers/apiIntern';
import PATHS from '../../Routes/paths';

export const CLEAN_ERROR = '@@CLAIM/CLEAN_ERROR';
export const CREATE_CLAIM = '@@CLAIM/CREATE_CLAIM';
export const DELETE_CLAIM_FILE = '@@CLAIM/DELETE_CLAIM_FILE';
export const DISCARD_CLAIM = '@@CLAIM/DISCARD_CLAIM';
export const GET_USER_CLAIMS = '@@CLAIM/GET_USER_CLAIMS';
export const GET_CLAIM = '@@CLAIM/GET_CLAIM';
export const LOADING_STEP = '@@CLAIM/LOADING_STEP';
export const NEED_CALL_REPORT = '@@CLAIM/NEED_CALL_REPORT';
export const RESET_CLAIM = '@@CLAIM/RESET_CLAIM';
export const SEND_CLAIM = '@@CLAIM/SEND_CLAIM';
export const UPDATE_CLAIM = '@@CLAIM/UPDATE_CLAIM';
export const UPDATE_CLAIM_PROPERTY = '@@CLAIM/UPDATE_CLAIM_PROPERTY';
export const UPLOAD_CLAIM_FILE = '@@CLAIM/UPLOAD_CLAIM_FILE';

export const getClaim = id => ({
  type: GET_CLAIM,
  payload: {
    request: {
      method: 'GET',
      url: `/v3/claim/${id}`
    }
  }
});

export const onDiscardClaim = id => async dispatch => {
  const action = await dispatch({
    type: DISCARD_CLAIM,
    payload: {
      request: {
        method: 'DELETE',
        url: `/v3/claim/${id}`
      }
    }
  });

  if (action.type === `${DISCARD_CLAIM}${SUCCESS}`) {
    history.push(PATHS.home);
  }
};

export const getUserClaims = () => ({
  type: GET_USER_CLAIMS,
  payload: {
    request: {
      method: 'GET',
      url: '/v3/claim'
    }
  }
});

export const resetClaim = data => ({
  type: RESET_CLAIM,
  payload: data
});

export const updateClaimProperty = payload => ({
  type: UPDATE_CLAIM_PROPERTY,
  payload
});

export const setLoadingStep = step => ({
  type: LOADING_STEP,
  payload: step
});

export const updateClaim = data => async dispatch => {
  if (data.status !== CLAIM_SUB_STATUS.COLLECTING_INFO) {
    return false;
  }

  const response = await dispatch({
    type: UPDATE_CLAIM,
    payload: {
      request: {
        method: 'PUT',
        url: `/v3/claim/${data.id}`,
        data: getClaimPayload(data)
      }
    }
  });

  return response.type === `${UPDATE_CLAIM}${SUCCESS}`;
};

export const onUploadFile = (claimId, document, type) => async dispatch => {
  let formData = new FormData();
  formData.append('file', document);

  const action = await dispatch({
    type: UPLOAD_CLAIM_FILE,
    payload: {
      request: {
        method: 'POST',
        url: `/v3/claim/${claimId}/document`,
        data: formData,
        params: { sub_type: type }
      }
    }
  });

  return action.type === `${UPLOAD_CLAIM_FILE}${SUCCESS}`;
};

export const onDeleteFile = (claimId, fileId) => async (dispatch, getState) => {
  const action = await dispatch({
    type: DELETE_CLAIM_FILE,
    payload: {
      request: {
        method: 'DELETE',
        url: `/v3/claim/${claimId}/document/${fileId}`
      }
    }
  });

  if (action.type === `${DELETE_CLAIM_FILE}${SUCCESS}`) {
    const { claim } = getState().claim;
    const newDocuments = claim.documents.filter(doc => doc.id !== fileId);
    dispatch(updateClaimProperty({ ...claim, documents: newDocuments }));
  }
};

export const onSendClaim = id => async dispatch => {
  const action = await dispatch({
    type: SEND_CLAIM,
    payload: {
      request: {
        method: 'PUT',
        url: `/v3/claim/${id}/finish`
      }
    }
  });

  if (action.type === `${SEND_CLAIM}${SUCCESS}`) {
    return true;
  }
  return false;
};

export const onCreateClaim = data => async (dispatch, getState) => {
  const { policy } = getState().policy;
  const action = await dispatch({
    type: CREATE_CLAIM,
    payload: {
      request: {
        method: 'POST',
        url: '/v3/claim',
        data
      }
    }
  });

  if (action.type === `${CREATE_CLAIM}${SUCCESS}`) {
    let nextStep = PATHS.claimDetailSteps.calendar;
    switch (policy.business_line) {
      case PRODUCT_TYPES.LIFE:
        nextStep = PATHS.claimDetailSteps.honest;
        break;
      default:
        nextStep = PATHS.claimDetailSteps.calendar;
        break;
    }
    history.push(PATHS.setClaimDetailStep(action.payload.data.id, nextStep));
  }
};

export const cleanError = () => ({
  type: CLEAN_ERROR,
  payload: null
});
