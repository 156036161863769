import { matchPath } from 'react-router-dom';

import PATHS from '../Routes/paths';

export const isPublicPath = path => {
  const publicPaths = [
    PATHS.help,
    PATHS.base,
    PATHS.welcome,
    PATHS.access,
    PATHS.otp,
    PATHS.updateApp,
    PATHS.maintenance
  ];
  return (
    publicPaths.includes(path) ||
    matchPath({ path: PATHS.estimate }, path) ||
    matchPath({ path: PATHS.mainEstimate }, path) ||
    matchPath({ path: PATHS.oldEstimate }, path) ||
    matchPath({ path: PATHS.fastTrack }, path) ||
    matchPath({ path: PATHS.policySign }, path) ||
    matchPath({ path: PATHS.accessRedirect }, path)
  );
};
