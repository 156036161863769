import { FAIL, RESET, SELECT_NGO, SUCCESS } from '../../actions/app';
import { STATUS_CODE } from '../../constants';
import { getErrorMessage } from '../../utils/getErrorMessage';
import {
  ADD_CUSTOMER,
  LOG_OUT,
  RESET_STATE,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_INFO,
  UPDATE_USER_INFO,
  UPDATE_USER_PHONE,
  CLEAN_ERROR,
  GET_SESSION,
  GET_USER_OTP,
  INIT_PHONE_CHANGE,
  CONFIRM_PHONE_CHANGE,
  SIGN_IN,
  UPDATE_TAGS
} from '../actions/access';
import {
  getCustomerInfo,
  getCustomersInfo,
  getUserInfo,
  getUserInfoFromCustomer
} from './apiIntern';

export const initialState = {
  isLoading: false,
  isAuthenticated: null,
  error: null,
  user: {
    ngo: null
  },
  customers: []
};

const reducer = (state = initialState, { type, payload, error }) => {
  let errorMessage = null;
  switch (type) {
    case GET_SESSION:
    case SIGN_IN:
    case UPDATE_CUSTOMER_INFO:
    case UPDATE_USER_INFO:
    case LOG_OUT:
    case INIT_PHONE_CHANGE:
      return {
        ...state,
        isLoading: true
      };
    case CONFIRM_PHONE_CHANGE:
      return {
        ...state,
        isLoading: true,
        user: {
          ...state.user,
          newPhone: payload?.request?.data?.phone
        }
      };
    case GET_USER_OTP:
      return {
        ...state,
        isLoading: true,
        user: {
          ...state.user,
          phone: payload?.data?.phone
        }
      };
    case `${SIGN_IN}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        user: getUserInfo(payload.data, state.user)
      };
    case `${GET_SESSION}${SUCCESS}`:
      const newState = {
        ...state,
        isLoading: false,
        customers: getCustomersInfo(payload.data.customers)
      };
      if (!newState.isAuthenticated || newState.user?.id !== payload.data?.id) {
        newState.isAuthenticated = true;
        newState.user = getUserInfo(payload.data, state.user);
      }
      return newState;
    case `${SELECT_NGO}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: {
          ...state.user,
          ngo: payload.data.ngo
        }
      };
    case `${UPDATE_CUSTOMER_INFO}${SUCCESS}`:
      const index = state.customers.findIndex(customer => customer.id === payload.data.id);
      const updatedState = {
        ...state,
        isLoading: false,
        customers: [
          ...state.customers.slice(0, index),
          getCustomerInfo(payload.data),
          ...state.customers.slice(index + 1)
        ]
      };
      // Update user info if the main customer is updated
      if (state.user.mainCustomerId === payload.data.id) {
        updatedState.user = { ...state.user, ...getUserInfoFromCustomer(payload.data) };
      }
      return updatedState;
    case `${GET_USER_OTP}${SUCCESS}`:
    case `${INIT_PHONE_CHANGE}${SUCCESS}`:
      return {
        ...state,
        isLoading: false
      };
    case `${CONFIRM_PHONE_CHANGE}${SUCCESS}`:
      const mainCustomerIndex = state.customers.findIndex(customer => customer.mainCustomer);
      const updatedPhone = state.user?.newPhone || state.user.phone;

      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          phone: updatedPhone,
          newPhone: null
        },
        customers: [
          ...state.customers.slice(0, mainCustomerIndex),
          {
            ...state.customers[mainCustomerIndex],
            phone: updatedPhone
          },
          ...state.customers.slice(mainCustomerIndex + 1)
        ]
      };
    case `${UPDATE_USER_INFO}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        user: getUserInfo(payload.data, state.user)
      };
    case `${LOG_OUT}${SUCCESS}`:
    case `${GET_SESSION}${FAIL}`:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false
      };
    case `${GET_USER_OTP}${FAIL}`:
    case `${INIT_PHONE_CHANGE}${FAIL}`:
      errorMessage = getErrorMessage(error);
      return {
        ...state,
        isLoading: false,
        error:
          errorMessage.code === STATUS_CODE.UNAUTHORIZED
            ? { ...errorMessage, message: { id: 'access.invalid_phone' } }
            : errorMessage
      };
    case `${SIGN_IN}${FAIL}`:
      errorMessage = getErrorMessage(error);
      return {
        ...state,
        isLoading: false,
        error:
          errorMessage.code === STATUS_CODE.UNAUTHORIZED
            ? { ...errorMessage, message: { id: 'otp.invalid_otp' } }
            : errorMessage
      };
    case `${CONFIRM_PHONE_CHANGE}${FAIL}`:
      errorMessage = getErrorMessage(error);
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          newPhone: null
        },
        error:
          errorMessage.code === STATUS_CODE.UNAUTHORIZED
            ? { ...errorMessage, message: { id: 'settings.invalid_otp' } }
            : errorMessage
      };
    case `${UPDATE_CUSTOMER_INFO}${FAIL}`:
    case `${UPDATE_USER_INFO}${FAIL}`:
    case `${LOG_OUT}${FAIL}`:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(error)
      };
    case ADD_CUSTOMER:
      return {
        ...state,
        customers: [...state.customers, getCustomerInfo(payload.data)]
      };
    case UPDATE_USER_PHONE:
      return {
        ...state,
        user: {
          ...state.user,
          phone: payload.phone
        }
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        user: {
          ...state.user,
          customerId: payload.customerId
        }
      };
    case UPDATE_TAGS:
      return {
        ...state,
        user: {
          ...state.user,
          tags: payload.tags
        }
      };
    case CLEAN_ERROR:
      return {
        ...state,
        error: null
      };
    case RESET:
    case RESET_STATE:
      return {
        ...initialState,
        isAuthenticated: state.isAuthenticated
      };
    default:
      return state;
  }
};

export default reducer;
