import { getAvailableProductList } from './utils/getAvailableProductList';

// wiki: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
export const STATUS_CODE = {
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  TOO_MANY_REQUESTS: 429,
  NETWORK: 'network'
};

export const PLATFORM_TYPE = {
  WEB: 'web',
  IOS: 'iOS',
  ANDROID: 'Android'
};

export const APP = {
  [PLATFORM_TYPE.IOS]: {
    version: '1.0.5',
    link: 'https://apps.apple.com/es/app/tuio/id1585657815'
  },
  [PLATFORM_TYPE.ANDROID]: {
    version: '1.0.10',
    link: 'https://play.google.com/store/apps/details?id=app.tuio'
  }
};

export const ENVIRONMENT_LIST = {
  PRE: 'PRE',
  PRO: 'PRO'
};

export const ENVIRONMENT =
  window.SENTRY_ENVIRONMENT === 'production' ? ENVIRONMENT_LIST.PRO : ENVIRONMENT_LIST.PRE;

export const LOCAL_FONTS = ['Poppins'];

export const CANCEL_ACTION_REQUESTS = 'CANCEL_ACTION_REQUESTS';
export const CANCEL_ALL_ACTION_REQUESTS = 'CANCEL_ALL_ACTION_REQUESTS';
export const CANCEL_DATA = { cancelled: true };

export const VIMEO_APP_ID = '224234';

export const LINKS = {
  PRIVACY: 'https://tuio.app/terminos-legales',
  LUKO_WARNING: 'https://tuio.com/ld/ciclo-de-pago',
  SURVEY_TIME: 'https://tuio.com/ld/quiero-contratar-mas-adelante',
  ZENDESK: 'https://help.tuio.com/',
  WHATSAPP: 'https://wa.me/947080518'
};

export const CUSTOMER_TYPE = {
  PERSON: 'PERSON',
  COMPANY: 'COMPANY'
};

export const POLICY_TYPE = {
  GES: 'ges',
  MURIMAR: 'murimar',
  IPTIQ: 'iptiq',
  ELEMENT: 'element'
};

export const PRODUCTS = {
  PETS: 'Pets Element',
  LIFE: 'Vida Riesgo GES',
  VEHICLE: 'vehicle',
  MURIMAR: 'Hogar Murimar',
  WAKAM_LUKO: 'Hogar Wakam Luko',
  WAKAM_TUIO: 'Hogar Wakam Tuio',
  IPTIQ: 'Hogar Iptiq'
};

export const PRODUCT_LABELS = {
  [PRODUCTS.MURIMAR]: 'murimar',
  [PRODUCTS.WAKAM_LUKO]: 'wakam_luko',
  [PRODUCTS.IPTIQ]: 'iptiq',
  [PRODUCTS.LIFE]: 'ges',
  [PRODUCTS.PETS]: 'element'
};

export const SECURITY = {
  ALARM: 'alarm',
  SECURE_DOOR: 'secureDoor',
  WINDOW_BARS: 'windowBars'
};

export const HOME_OWNERSHIP = {
  MINE: 'Mine',
  MINE_RENTED: 'MineRented',
  TENANT: 'Tenant'
};

export const HOUSE_UTILIZATION = {
  MAIN: 'Main',
  SECONDARY: 'Secondary'
};

// business_line
export const PRODUCT_TYPES = {
  HOME: 'Multirriesgo Hogar',
  PETS: 'Mascotas',
  LIFE: 'Vida Riesgo',
  AUTO: 'Coche'
};

export const PRODUCT_TYPE_LABELS = {
  [PRODUCT_TYPES.HOME]: 'home',
  [PRODUCT_TYPES.PETS]: 'pets',
  [PRODUCT_TYPES.LIFE]: 'life',
  [PRODUCT_TYPES.AUTO]: 'auto'
};

export const SECOND_HOME = 'second_home';

const AVAILABLE_PRODUCTS_PARAM = 'availableProducts';
const defaultList = window.AVAILABLE_PRODUCTS || [PRODUCT_TYPE_LABELS[PRODUCT_TYPES.HOME]];
const defaultProductList = defaultList.every(p => Object.values(PRODUCT_TYPES).includes(p))
  ? defaultList
  : getAvailableProductList(defaultList);
let customProducts = new URLSearchParams(window.location.search).get(AVAILABLE_PRODUCTS_PARAM);
(customProducts && localStorage.setItem(AVAILABLE_PRODUCTS_PARAM, customProducts)) ||
  (customProducts = localStorage.getItem(AVAILABLE_PRODUCTS_PARAM));
const customProductList =
  ENVIRONMENT === ENVIRONMENT_LIST.PRE &&
  customProducts &&
  getAvailableProductList(customProducts.split(','));

export const AVAILABLE_PRODUCTS_WITH_PACKS = customProductList || defaultProductList;
export const AVAILABLE_PRODUCTS = AVAILABLE_PRODUCTS_WITH_PACKS.filter(p => typeof p === 'string');
export const AVAILABLE_PRODUCTS_LABELS = AVAILABLE_PRODUCTS.map(p => PRODUCT_TYPE_LABELS[p]);

export const TUIO_HOME = 'Tuio';

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE'
};

export const EMPLOYMENT = {
  SELF_EMPLOYED: 'SELF_EMPLOYED',
  EMPLOYEE: 'EMPLOYEE',
  UNEMPLOYED: 'UNEMPLOYED',
  RETIRED: 'RETIRED',
  DISABILITY_PENSION: 'DISABILITY_PENSION',
  STUDENT: 'STUDENT',
  HOUSEKEEPER: 'HOUSEKEEPER'
};

export const INSURED_CAPITAL = {
  MAX: 200000,
  MIN: 6000,
  [EMPLOYMENT.STUDENT]: 50000,
  [EMPLOYMENT.DISABILITY_PENSION]: 50000,
  [EMPLOYMENT.UNEMPLOYED]: 50000,
  [EMPLOYMENT.RETIRED]: 50000,
  [EMPLOYMENT.HOUSEKEEPER]: 100000
};

export const LIFE_DISABILITY_LIMIT_AGE = 67;

export const LIFE_COVERS = {
  V2023_FALLECIMIENTO: 'V2023_FALLECIMIENTO',
  V2023_INVALIDEZ_PERMANENTE_ABSOLUTA: 'V2023_INVALIDEZ_PERMANENTE_ABSOLUTA',
  V2023_CAPITAL_REVALUATION: 'V2023_CAPITAL_REVALUATION'
};

export const PETS_COVERS = {
  AESTHETIC_TREATMENTS: 'AESTHETIC_TREATMENTS',
  TRANSPLANTS: 'TRANSPLANTS',
  LACK_OF_CARE: 'LACK_OF_CARE',
  TRANSPORTATION: 'TRANSPORTATION',
  CHRONIC_DISEASES: 'CHRONIC_DISEASES',
  STERILIZATION: 'STERILIZATION',
  CONGENITAL_DISEASES: 'CONGENITAL_DISEASES',
  PREGNANCY: 'PREGNANCY',
  ERADICATED_DISEASES: 'ERADICATED_DISEASES'
};

export const HOME_COVERS = {
  ROBO_FUERA_DE_CASA: 'V2023_ROBO_FUERA_DE_CASA',
  OTRAS_ESTRUCTURAS: 'V2023_OTRAS_ESTRUCTURAS',
  DEFENSA_LEGAL: 'V2023_DEFENSA_LEGAL',
  VALOR_DE_REPOSICION: 'V2023_VALOR_DE_REPOSICION',
  ROBO: 'V2023_ROBO',
  FUEGO_Y_HUMO: 'V2023_FUEGO_Y_HUMO',
  DAÑOS_POR_CLIMA: 'V2023_DAÑOS_POR_CLIMA',
  TRA: 'V2023_TRA',
  PAZ_MENTAL_PROPIETARIO: 'V2023_PAZ_MENTAL_PROPIETARIO',
  REPARACIONES_DE_EMERGENCIA: 'V2023_REPARACIONES_DE_EMERGENCIA',
  CERRAJERIA: 'V2023_CERRAJERIA',
  DAÑO_POR_AGUA: 'V2023_DAÑO_POR_AGUA',
  RESPONSABILIDAD_CIVIL: 'V2023_RESPONSABILIDAD_CIVIL',
  TU_CASA: 'V2023_TU_CASA',
  MANITAS: 'V2023_MANITAS',
  ALOJAMIENTO_TEMPORAL: 'V2023_ALOJAMIENTO_TEMPORAL',
  PROTECCION_INQUILINOS: 'V2023_PROTECCION_INQUILINOS',
  PROTECCION_CASEROS: 'V2023_PROTECCION_CASEROS'
};

export const INSURANCE_STATUS = {
  NO: 'NO',
  REJECTED: 'REJECTED',
  INSURED: 'INSURED'
};

export const SMOKING_HABIT = {
  NO: 'NO',
  LESS_THAN_5: 'LESS_THAN_5',
  LESS_THAN_20: 'LESS_THAN_20',
  LESS_THAN_40: 'LESS_THAN_40',
  MORE_THAN_40: 'MORE_THAN_40'
};

export const DRIVE = {
  NO: 'NO',
  MOPED: 'MOPED',
  ROAD: 'ROAD',
  CUSTOM_SCOOTER: 'CUSTOM_SCOOTER'
};

export const SPORT_ACTIVITIES = {
  FOOTBALL: 'FOOTBALL',
  BASKETBALL: 'BASKETBALL',
  PADDLE: 'PADDLE',
  TENNIS: 'TENNIS',
  CYCLING: 'CYCLING',
  CYCLING_BMX: 'CYCLING_BMX',
  CYCLING_ALL_TERRAIN: 'CYCLING_ALL_TERRAIN',
  SKIING_BOBSLEIGH: 'SKIING_BOBSLEIGH',
  SKIING_COMPETITION: 'SKIING_COMPETITION',
  SKIING_ACROBATICS: 'SKIING_ACROBATICS',
  SKIING_CROSS_COUNTRY: 'SKIING_CROSS_COUNTRY',
  SKIING_JUMPING: 'SKIING_JUMPING',
  SKIING_SNOWBOARD: 'SKIING_SNOWBOARD',
  SKIING: 'SKIING',
  SWIMMING_POOL: 'SWIMMING_POOL',
  SWIMMING_OPEN_WATER: 'SWIMMING_OPEN_WATER',
  SURFING_WINDSURFING_COMPETITION: 'SURFING_WINDSURFING_COMPETITION',
  SURFING_WINDSURFING: 'SURFING_WINDSURFING',
  KITESURFING: 'KITESURFING',
  RUNNING: 'RUNNING',
  TRIATHLON: 'TRIATHLON',
  SAILING: 'SAILING',
  HUNTING_SMALL_GAME: 'HUNTING_SMALL_GAME'
};

export const PROFESSIONS = {
  PSICOLOGO: 'PSICOLOGO',
  ABOGADO: 'ABOGADO',
  ACTOR: 'ACTOR',
  ACTUARIO: 'ACTUARIO',
  ECONOMISTA: 'ECONOMISTA',
  ADMINISTRATIVO: 'ADMINISTRATIVO',
  INFORMATICO: 'INFORMATICO',
  AGENTE_DE_SEGUROS: 'AGENTE_DE_SEGUROS',
  AGRICULTOR: 'AGRICULTOR',
  GANADERO: 'GANADERO',
  ALBAÑIL: 'ALBAÑIL',
  ESCAYOLISTA: 'ESCAYOLISTA',
  ENCOFRADOR: 'ENCOFRADOR',
  AMA_DE_CASA: 'AMA_DE_CASA',
  EMPLEADA_DE_HOGAR: 'EMPLEADA_DE_HOGAR',
  ANTENISTA: 'ANTENISTA',
  APAREJADOR: 'APAREJADOR',
  ARQUITECTO: 'ARQUITECTO',
  ASCENSORISTA: 'ASCENSORISTA',
  CONSULTOR: 'CONSULTOR',
  MODISTA: 'MODISTA',
  BAILARIN: 'BAILARIN',
  CAMARERO: 'CAMARERO',
  BIBLIOTECARIO: 'BIBLIOTECARIO',
  BIOLOGO: 'BIOLOGO',
  CAJERO: 'CAJERO',
  PODOLOGO: 'PODOLOGO',
  CARNICERO: 'CARNICERO',
  BIOQUIMICO: 'BIOQUIMICO',
  CHARCUTERO: 'CHARCUTERO',
  CARTERO: 'CARTERO',
  CERRAJERO: 'CERRAJERO',
  COCINERO: 'COCINERO',
  CRISTALERO: 'CRISTALERO',
  DECORADOR: 'DECORADOR',
  DISEÑADOR: 'DISEÑADOR',
  DENTISTA: 'DENTISTA',
  DEPENDIENTE_COMERC: 'DEPENDIENTE_COMERC',
  FISIOTERAPEUTA: 'FISIOTERAPEUTA',
  DIPLOMATICO: 'DIPLOMATICO',
  GERENTE: 'GERENTE',
  DIRECTOR_DE_AGENCIAS: 'DIRECTOR_DE_AGENCIAS',
  ESTUDIANTE: 'ESTUDIANTE',
  FARMACEUTICO: 'FARMACEUTICO',
  FONTANERO: 'FONTANERO',
  FRUTERO: 'FRUTERO',
  GUIA_TURISTICO: 'GUIA_TURISTICO',
  JARDINERO: 'JARDINERO',
  JOYERO: 'JOYERO',
  RELOJERO: 'RELOJERO',
  JUEZ: 'JUEZ',
  MECANICO: 'MECANICO',
  MEDICO: 'MEDICO',
  MODELO: 'MODELO',
  MUSICO: 'MUSICO',
  NOTARIO: 'NOTARIO',
  PROCURADOR: 'PROCURADOR',
  REGISTRADOR: 'REGISTRADOR',
  OPTICO: 'OPTICO',
  PROTESICO: 'PROTESICO',
  PANADERO: 'PANADERO',
  PELUQUERO: 'PELUQUERO',
  PENSIONISTA: 'PENSIONISTA',
  PERIODISTA: 'PERIODISTA',
  PERITO: 'PERITO',
  PESCADERO: 'PESCADERO',
  PINTOR_ARTISTA: 'PINTOR_ARTISTA',
  PINTOR_EDIFICIOS: 'PINTOR_EDIFICIOS',
  SASTRERIA: 'SASTRERIA',
  SOLDADOR: 'SOLDADOR',
  TAXISTA: 'TAXISTA',
  VETERINARIO: 'VETERINARIO',
  ZAPATERO_REPARAC: 'ZAPATERO_REPARAC',
  SIN_PROFESION_ESPECIFICADA: 'SIN_PROFESION_ESPECIFICADA',
  DIRECTIVO: 'DIRECTIVO',
  ASESOR_DE_IMAGEN: 'ASESOR_DE_IMAGEN',
  BLOGUERO: 'BLOGUERO',
  TELEOPERADOR: 'TELEOPERADOR',
  ADMINISTRADOR_FINCAS: 'ADMINISTRADOR_FINCAS',
  ELECT_BAJA_TENSION: 'ELECT_BAJA_TENSION',
  CARPINTERO: 'CARPINTERO',
  CARPINTERO_METALICO: 'CARPINTERO_METALICO',
  PROFESOR_AUTOESCUELA: 'PROFESOR_AUTOESCUELA',
  TRANSPORTE_NO_PELIGR: 'TRANSPORTE_NO_PELIGR',
  CONDUCTOR_AMBULANCIA: 'CONDUCTOR_AMBULANCIA',
  CONDUCTOR_REPARTO: 'CONDUCTOR_REPARTO',
  SOCORRISTA_PISCINA: 'SOCORRISTA_PISCINA',
  VIGILANTE_SIN_ARMA: 'VIGILANTE_SIN_ARMA',
  TATUADOR: 'TATUADOR',
  COMERCIAL: 'COMERCIAL',
  RECEPCIONISTA: 'RECEPCIONISTA',
  PARQUETISTA: 'PARQUETISTA',
  INGENIERO: 'INGENIERO',
  PROF_EDUCACION_FISIC: 'PROF_EDUCACION_FISIC',
  CREATIVO_PUBLICIDAD: 'CREATIVO_PUBLICIDAD',
  PERSONAL_DE_LIMPIEZA: 'PERSONAL_DE_LIMPIEZA',
  OPERARIO_ALMACEN: 'OPERARIO_ALMACEN',
  ENFERMERO: 'ENFERMERO',
  CONSERJE: 'CONSERJE',
  CUERPOS_DE_SEGURIDAD: 'CUERPOS_DE_SEGURIDAD',
  DISEÑADOR_GRAFICO: 'DISEÑADOR_GRAFICO',
  PROFESOR: 'PROFESOR'
};

export const SPORT_LEVELS = {
  AMATEUR: 'AMATEUR',
  FEDERATED: 'FEDERATED',
  PROFESSIONAL: 'PROFESSIONAL'
};

export const BENEFICIARY_TYPE = {
  PERSON: 'PERSON',
  COMPANY: 'COMPANY'
};

export const USES = {
  RENTER: 'RENTER',
  OWNER_PERMANENT: 'OWNER_PERMANENT',
  OWNER_RENT: 'OWNER_RENT',
  OWNER_TEMPORARY: 'OWNER_TEMPORARY'
};

export const PRODUCT_OPTIONS = {
  MIGRATED: ['Propietario IPTIQ formerly Wakam', 'Inquilino IPTIQ formerly Wakam'],
  [PRODUCT_TYPE_LABELS[PRODUCT_TYPES.LIFE]]: {
    [POLICY_TYPE.GES]: 'GES Vida 2023'
  },
  [PRODUCT_TYPE_LABELS[PRODUCT_TYPES.PETS]]: {
    [POLICY_TYPE.ELEMENT]: 'Mascotas 2024'
  },
  [PRODUCT_TYPE_LABELS[PRODUCT_TYPES.HOME]]: {
    [POLICY_TYPE.MURIMAR]: {
      [USES.RENTER]: 'Inquilino 2022',
      [USES.OWNER_PERMANENT]: 'Propietario 2022',
      [USES.OWNER_RENT]: 'Casero 2022',
      [USES.OWNER_TEMPORARY]: 'Propietario 2022'
    },
    [POLICY_TYPE.IPTIQ]: {
      [USES.RENTER]: 'Inquilino IPTIQ',
      [USES.OWNER_PERMANENT]: 'Propietario IPTIQ',
      [USES.OWNER_RENT]: 'Casero IPTIQ',
      [USES.OWNER_TEMPORARY]: 'Propietario IPTIQ'
    }
  }
};

export const USES_DESCRIPTION = {
  [USES.RENTER]: {
    ownership: HOME_OWNERSHIP.TENANT,
    utilization: HOUSE_UTILIZATION.MAIN
  },
  [USES.OWNER_PERMANENT]: {
    ownership: HOME_OWNERSHIP.MINE,
    utilization: HOUSE_UTILIZATION.MAIN
  },
  [USES.OWNER_RENT]: {
    ownership: HOME_OWNERSHIP.MINE_RENTED,
    utilization: HOUSE_UTILIZATION.MAIN
  },
  [USES.OWNER_TEMPORARY]: {
    ownership: HOME_OWNERSHIP.MINE,
    utilization: HOUSE_UTILIZATION.SECONDARY
  }
};

export const FLAMMABLE = {
  NOT: 'NOT_FLAMMABLE',
  SEMI: 'SEMI_FLAMMABLE',
  YES: 'IS_FLAMMABLE'
};

export const FLAMMABLE_MATERIALS = {
  [FLAMMABLE.NOT]: 'FireproofMaterials',
  [FLAMMABLE.SEMI]: 'FireproofMaterials',
  [FLAMMABLE.YES]: 'FlammableMaterials'
};

export const FLOORS = {
  TOP_FLOOR: 'FlatTopFloor',
  GROUND_FLOOR: 'FlatGroundFloor',
  FIRST_FLOOR: 'FlatFirstFloor',
  MIDDLE_FLOOR: 'FlatMiddleFloor'
};

export const HOUSE_TYPES = {
  APARTMENT: 'apartamento',
  DETACHED_HOUSE: 'IndependentCottage',
  SEMIDETACHED_ONE: 'TwinCottage',
  SEMIDETACHED_TWO: 'FixedCottage'
};

export const POLICY_STATUS = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  NEW: 'PROPOSAL',
  SUSPENDED: 'SUSPENDED'
};

export const RECEIPT_STATUS = {
  FAILED: 'FAILED',
  ISSUED: 'ISSUED',
  PENDING: 'PENDING',
  DEFAULTED: 'DEFAULTED',
  FULLY_PAID: 'FULLY_PAID'
};

export const CONDITIONS = {
  [PRODUCT_TYPE_LABELS[PRODUCT_TYPES.HOME]]: {
    UNINHABITED_AREA: 'UNINHABITED_AREA',
    VACATION_OR_ROOM_RENTAL: 'VACATION_OR_ROOM_RENTAL',
    SQUATTERS: 'SQUATTERS',
    MULTIPLE_INSURANCE: 'MULTIPLE_INSURANCE',
    TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS'
  },
  [PRODUCT_TYPE_LABELS[PRODUCT_TYPES.LIFE]]: {
    CONFIRM_LIFE_ANSWERS: 'CONFIRM_LIFE_ANSWERS',
    CONFIRM_LIFE_COVERS: 'CONFIRM_LIFE_COVERS',
    REVIEW_AND_ACCEPT: 'REVIEW_AND_ACCEPT'
  },
  [PRODUCT_TYPE_LABELS[PRODUCT_TYPES.PETS]]: {
    PREVIOUSLY_EXPELLED: 'PREVIOUSLY_EXPELLED',
    PET_HEALTH: 'PET_HEALTH',
    PET_HEALTH_CHECK: 'PET_HEALTH_CHECK',
    PET_ACTIVITY: 'PET_ACTIVITY',
    CONFIRM_ANSWERS: 'CONFIRM_ANSWERS'
  }
};

export const AGE_CONDITIONS = {
  LEGAL_AGE: 18,
  ABOVE_AGE: 120
};

export const MTA_CONDITIONS = {
  [PRODUCT_TYPE_LABELS[PRODUCT_TYPES.LIFE]]: {
    CONFIRM_MTA: 'CONFIRM_MTA'
  }
};

export const PETS = {
  TYPE: {
    DOG: 'DOG',
    CAT: 'CAT'
  },
  SEX: {
    MALE: 'MALE',
    FEMALE: 'FEMALE'
  },
  SIZE: {
    _0_5_KG: '_0_5_KG',
    _5_10_KG: '_5_10_KG',
    _10_20_KG: '_10_20_KG',
    _20_40_KG: '_20_40_KG',
    MORE_THAN_40_KG: 'MORE_THAN_40_KG'
  },
  MIXED_BREED: {
    NO: 'NO',
    YES: 'YES',
    UNKNOWN: 'UNKNOWN'
  }
};

export const DISCARD_REASON_TIME = 'time';
export const DISCARD_REASON_OLD = 'old';

export const DISCARD_REASONS = {
  PROPOSAL: ['price', 'covers', 'doubt', 'app', DISCARD_REASON_TIME],
  HOME_POLICY: ['moving', 'cheaper', 'bad_experience', 'not_convince'],
  LIFE_POLICY: ['mortgage', 'cheaper', 'bad_experience', 'not_convince'],
  REFLECTION_PERIOD: [DISCARD_REASON_OLD, 'insufficient', 'accidentally_hired', 'price'],
  PETS_POLICY: ['mortgage', 'cheaper', 'bad_experience', 'not_convince']
};

export const KEY = {
  ID: 'id'
};

export const BUSINESS_TYPE = {
  SL: 'SL',
  SA: 'SA',
  NON_PROFIT: 'SIN_ANIMO_DE_LUCRO',
  PARTNERSHIP: 'COLECTIVA',
  LIMITED: 'COMANDITARIA',
  COMMONHOLD: 'COMUNIDAD_DE_BIENES',
  COOP: 'COOPERATIVA'
};

export const PAYMENT_METHODS = {
  STRIPE: 'STRIPE',
  SEPA: 'SEPA'
};

export const ID_TYPES = {
  DNI: 'DNI',
  NIE: 'NIE',
  PASSPORT: 'PASSPORT',
  NIF: 'NIF'
};

export const PAYMENT_PERIODS = {
  ANNUAL: 'Annual',
  HALF_YEARLY: 'HalfYearly',
  QUARTERLY: 'Quarterly',
  MONTHLY: 'Monthly'
};

export const TELEPHONE = {
  HELP: '91 949 64 70',
  CLAIMS: {
    urgent: '91 901 03 13',
    handyman: '900 533 704'
  },
  VIRTUAL_VET: '961258105'
};

export const VIRTUAL_VET_HOURS = {
  START: 10,
  END: 22
};

export const DOCUMENT_TYPE = {
  PARTICULAR: 'PARTICULAR',
  IPID: 'IPID',
  GENERAL: 'GENERAL',
  GDPR: 'GDPR'
};

export const CAPITAL_CODES = {
  CONTINENT: 'CONTINENT',
  CONTENT: 'CONTENT',
  LIABILITY: 'LIABILITY',
  LIFE: 'LIFE'
};

export const CLAIM_STATUS = {
  NEW: 'NEW',
  PENDING_PROCESSING: 'PENDING_PROCESSING',
  IN_PROGRESS: 'IN_PROGRESS',
  PENDING_CLOSURE: 'PENDING_CLOSURE',
  CLOSED: 'CLOSED',
  REFUSED: 'REFUSED'
};

export const CLAIM_SUB_STATUS = {
  COLLECTING_INFO: 'COLLECTING_INFO',
  PENDING_CLIENT_INFORMATION: 'PENDING_CLIENT_INFORMATION'
};

export const CLAIM_CATEGORY = {
  REPORT_INDEMNIFICATION: 'REPORT_INDEMNIFICATION',
  URGENT_BREAKDOWN: 'URGENT_BREAKDOWN',
  HANDYMAN: 'HANDYMAN',
  DISABILITY: 'DISABILITY',
  PET_VACCINATIONS_CHECKUPS: 'PET_VACCINATIONS_CHECKUPS',
  PET_OTHER_HEALTH_ISSUES: 'PET_OTHER_HEALTH_ISSUES',
  PET_THEFT_LOSS: 'PET_THEFT_LOSS',
  PET_DEATH: 'PET_DEATH'
};

export const CLAIM_TYPE = {
  [PRODUCT_TYPES.LIFE]: {
    [PRODUCTS.LIFE]: {
      [CLAIM_CATEGORY.DISABILITY]: CLAIM_CATEGORY.DISABILITY
    }
  },
  [PRODUCT_TYPES.HOME]: {
    [PRODUCTS.MURIMAR]: {
      [CLAIM_CATEGORY.REPORT_INDEMNIFICATION]: CLAIM_CATEGORY.REPORT_INDEMNIFICATION,
      [CLAIM_CATEGORY.URGENT_BREAKDOWN]: CLAIM_CATEGORY.URGENT_BREAKDOWN
    },
    [PRODUCTS.IPTIQ]: {
      [CLAIM_CATEGORY.REPORT_INDEMNIFICATION]: CLAIM_CATEGORY.REPORT_INDEMNIFICATION,
      [CLAIM_CATEGORY.URGENT_BREAKDOWN]: CLAIM_CATEGORY.URGENT_BREAKDOWN,
      [CLAIM_CATEGORY.HANDYMAN]: CLAIM_CATEGORY.HANDYMAN
    },
    [PRODUCTS.WAKAM_LUKO]: {
      [CLAIM_CATEGORY.REPORT_INDEMNIFICATION]: CLAIM_CATEGORY.REPORT_INDEMNIFICATION,
      [CLAIM_CATEGORY.URGENT_BREAKDOWN]: CLAIM_CATEGORY.URGENT_BREAKDOWN,
      [CLAIM_CATEGORY.HANDYMAN]: CLAIM_CATEGORY.HANDYMAN
    }
  },
  [PRODUCT_TYPES.PETS]: {
    [PRODUCTS.PETS]: {
      [CLAIM_CATEGORY.PET_VACCINATIONS_CHECKUPS]: CLAIM_CATEGORY.PET_VACCINATIONS_CHECKUPS,
      [CLAIM_CATEGORY.PET_OTHER_HEALTH_ISSUES]: CLAIM_CATEGORY.PET_OTHER_HEALTH_ISSUES,
      [CLAIM_CATEGORY.PET_THEFT_LOSS]: CLAIM_CATEGORY.PET_THEFT_LOSS,
      [CLAIM_CATEGORY.PET_DEATH]: CLAIM_CATEGORY.PET_DEATH
    }
  }
};

export const CLAIM_URGENT = {
  luces: 'lights',
  locksmith: 'locksmith',
  danger_increasing: 'danger_increasing',
  security: 'security',
  other: 'other'
};

export const CLAIM_CALL_TYPE = {
  urgent: 'urgent',
  handyman: 'handyman'
};

export const CLAIM_CAUSE = {
  DISABILITY: 'DISABILITY',
  FIRE_SMOKE: 'FIRE_SMOKE',
  WATER: 'WATER',
  CLIMATE: 'CLIMATE',
  BURGLARY_HOME: 'BURGLARY_HOME',
  BURGLARY_OUTSIDE_HOME: 'BURGLARY_OUTSIDE_HOME',
  GLASS: 'GLASS',
  BREAKAGE: 'BREAKAGE',
  OTHER: 'OTHER',
  PET_VACCINATIONS_CHECKUPS: 'PET_VACCINATIONS_CHECKUPS',
  PET_OTHER_HEALTH_ISSUES: 'PET_OTHER_HEALTH_ISSUES',
  PET_THEFT_LOSS: 'PET_THEFT_LOSS',
  PET_DEATH: 'PET_DEATH'
};

export const PRODUCT_CLAIM_CAUSE = {
  [PRODUCT_TYPES.LIFE]: {
    [CLAIM_CAUSE.DISABILITY]: CLAIM_CAUSE.DISABILITY
  },
  [PRODUCT_TYPES.HOME]: {
    [CLAIM_CAUSE.FIRE_SMOKE]: CLAIM_CAUSE.FIRE_SMOKE,
    [CLAIM_CAUSE.WATER]: CLAIM_CAUSE.WATER,
    [CLAIM_CAUSE.CLIMATE]: CLAIM_CAUSE.CLIMATE,
    [CLAIM_CAUSE.BURGLARY_HOME]: CLAIM_CAUSE.BURGLARY_HOME,
    [CLAIM_CAUSE.BURGLARY_OUTSIDE_HOME]: CLAIM_CAUSE.BURGLARY_OUTSIDE_HOME,
    [CLAIM_CAUSE.GLASS]: CLAIM_CAUSE.GLASS,
    [CLAIM_CAUSE.BREAKAGE]: CLAIM_CAUSE.BREAKAGE,
    [CLAIM_CAUSE.OTHER]: CLAIM_CAUSE.OTHER
  }
};

export const CLAIM_DOC_TYPES = {
  DOCUMENT: 'DOCUMENT',
  VIMEO: 'VIMEO'
};

export const CLAIM_DOC_SUBTYPES = {
  HONESTITY_SIGNATURE: 'HONESTITY_SIGNATURE',
  PET_RECEIPT: 'PET_RECEIPT',
  PET_POLICE_REPORT: 'PET_POLICE_REPORT',
  PET_MICROCHIP_NOTIFICATION: 'PET_MICROCHIP_NOTIFICATION',
  PET_LOSS_ADVERTISEMENT: 'PET_LOSS_ADVERTISEMENT',
  PET_DEATH_CERTIFICATE: 'PET_DEATH_CERTIFICATE',
  PET_TRAVEL_RECEIPT: 'PET_TRAVEL_RECEIPT'
};

export const MAX_FILE_SIZE = 104857600; // 100MB

export const VIDEO_TIME_LIMIT = 180000; // 3 minutes

export const CANCEL_ASAP_LIMIT = 1296000000; // 15 days

export const TIMES = {
  ONE_YEAR: 'ONE_YEAR',
  SIX_MONTHS: 'SIX_MONTHS'
};

export const TIMES_IN_MS = {
  [TIMES.ONE_YEAR]: 31536000000,
  [TIMES.SIX_MONTHS]: 15724800000
};

export const POLICY_START_LIMIT = {
  [PRODUCT_TYPES.LIFE]: TIMES.SIX_MONTHS,
  [PRODUCT_TYPES.HOME]: TIMES.ONE_YEAR,
  [PRODUCT_TYPES.PETS]: TIMES.ONE_YEAR
};

export const TAGS = {
  SEPA_PROBLEMS: 'sepa_problems',
  MONTHLY_PAYMENT_DISABLED: 'monthly_payment_disabled',
  PROVEN_FRAUDSTER: 'proven_fraudster'
};

export const LUKO_MIGRATION_DAY = '2023-04-12';

export const VALUABLE_ITEMS_LIMIT = {
  LUKO: 6000,
  TUIO: 2000
};

export const SOURCE = {
  NEW_CLIENT: 'NEW_CLIENT',
  LUKO_MIGRATION: 'LUKO_MIGRATION'
};

export const PAYMENT_DAY_LIMIT = 28;
export const MINIMUM_MONTHLY_PAYMENT = 5.01;

export const VALUE_TYPE = {
  ARRAY: 'array',
  OBJECT: 'object',
  BOOLEAN: 'boolean',
  STRING: 'string',
  NUMBER: 'number'
};

export const DEFAULT_STEP = 'default';

export const SIGN_STATUS = {
  UNSIGNED: 'UNSIGNED',
  PAST: 'PAST',
  CURRENT: 'CURRENT'
};

export const CHECKOUT_PAYMENT_METHODS = {
  CARD: 'card',
  BANK: 'bank',
  SEPA: 'sepa'
};

export const PAYMENT_METHOD_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export const ERROR_SUBCODES = {
  MONTHLY_DISABLED_BACK: 12,
  POPULATION_NOT_FOUND: 22,
  DUPLICATED_IBAN: 440,
  MONTHLY_DISABLED_JARVIS: 520,
  PREMIUM_BELOW_MINIMUM: 1001,
  MEDICAL_QUESTIONNAIRE: 1121,
  IMC: 1321,
  FLAMMABLE_MATERIALS: 1411,
  PREVIOUS_INSURANCE: 1421,
  EARLY_RETIREMENT: 1621,
  EXCLUDED_SPORTS: 2021,
  HEIGHT: 2121,
  WEIGHT: 2221,
  SMOKING_HABIT: 3021,
  WEIGHT_2: 3121,
  DISABILITY_PENSION: 3221,
  PET_TOO_YOUNG: 1031,
  PET_TOO_OLD: 1131,
  DEPOPULATED_AREA: 1811,
  TENANT_CONTINENT_NOT_ALLOWED: 1911
};

export const LIFE_ERROR_SUBCODES = [
  ERROR_SUBCODES.PREMIUM_BELOW_MINIMUM,
  ERROR_SUBCODES.MEDICAL_QUESTIONNAIRE,
  ERROR_SUBCODES.IMC,
  ERROR_SUBCODES.PREVIOUS_INSURANCE,
  ERROR_SUBCODES.EARLY_RETIREMENT,
  ERROR_SUBCODES.EXCLUDED_SPORTS,
  ERROR_SUBCODES.HEIGHT,
  ERROR_SUBCODES.WEIGHT,
  ERROR_SUBCODES.SMOKING_HABIT,
  ERROR_SUBCODES.WEIGHT_2,
  ERROR_SUBCODES.DISABILITY_PENSION
];

export const PETS_ERROR_SUBCODES = [ERROR_SUBCODES.PET_TOO_YOUNG, ERROR_SUBCODES.PET_TOO_OLD];

export const IOS_DATE_PLACEHOLDER = 'DD/MM/YYYY';

export const TIMEOUT = {
  ERROR_FILE_TYPE: 5000
};

export const DANGEROUS_DOGS_LIST = [
  'Akita Inu',
  'American Pit Bull Terrier',
  'American Staffordshire Terrier',
  'Bull Terrier',
  'Dóberman',
  'Dóberman Pinscher',
  'Dogo Argentino',
  'Dogo Canario',
  'Dogo Mallorquín',
  'Dogo de Burdeos',
  'Fila Brasileiro',
  'Mastín Napolitano',
  'Mastín/dogo Tibetano',
  'Perro de Presa Canario',
  'Perro de Presa Mallorquín',
  'Pit Bull Terrier',
  'Rottweiler',
  'Staffordshire Bull Terrier',
  'Tora Inu',
  'Tosa Inu'
];
