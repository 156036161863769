import {
  FLAMMABLE,
  MINIMUM_MONTHLY_PAYMENT,
  PAYMENT_PERIODS,
  PRODUCT_TYPES,
  PRODUCT_TYPE_LABELS,
  ERROR_SUBCODES
} from '../../constants';
import { history } from '../../App';
import {
  extractPopulationAddress,
  getCreateCustomerData,
  getCreateProposalData,
  getCreateUserData
} from '../reducers/apiIntern';
import {
  addCustomer,
  identifyUser,
  updateTags,
  updateUserPhone
} from '../../Access/actions/access';
import { FAIL, SUCCESS } from '../../actions/app';
import { getFloorType } from '../../utils';
import { sendAdtractionRequest } from '../../utils/sendAdtractionRequest';
import PATHS from '../../Routes/paths';

export const CREATE_CUSTOMER = '@@ESTIMATE/CREATE_CUSTOMER';
export const CREATE_USER = '@@ESTIMATE/CREATE_USER';
export const GET_POPULATION_BY_POSTAL_CODE = '@@ESTIMATE/GET_POPULATION_BY_POSTAL_CODE';
export const POST_PROPOSAL_DATA = '@@ESTIMATE/POST_PROPOSAL_DATA';
export const UPDATE_PROPOSAL_INTERVAL = '@@ESTIMATE/UPDATE_PROPOSAL_INTERVAL';
export const RESET_STATE = '@@ESTIMATE/RESET_STATE';
export const SAVE_QUOTE_DATA = '@@ESTIMATE/SAVE_QUOTE_DATA';
export const SET_FAST_TRACK = '@@ESTIMATE/SET_FAST_TRACK';
export const GET_MUNICIPALITIES = '@@ESTIMATE/GET_MUNICIPALITIES';
export const SET_MUNICIPALITIES = '@@ESTIMATE/SET_MUNICIPALITIES';
export const SET_PRODUCTS = '@@ESTIMATE/SET_PRODUCTS';
export const SET_CURRENT_PRODUCT = '@@ESTIMATE/SET_CURRENT_PRODUCT';
export const GET_PROVINCES = '@@ESTIMATE/GET_PROVINCES';
export const SET_PROVINCES = '@@ESTIMATE/SET_PROVINCES';
export const UPDATE_CADASTRE_OPTIONS_STAIRWAY = '@@ESTIMATE/UPDATE_CADASTRE_OPTIONS_STAIRWAY';
export const UPDATE_CADASTRE_OPTIONS_FLOOR = '@@ESTIMATE/UPDATE_CADASTRE_OPTIONS_FLOOR';
export const UPDATE_CADASTRE_OPTIONS_LETTER = '@@ESTIMATE/UPDATE_CADASTRE_OPTIONS_LETTER';
export const VALIDATE_CADASTRAL_REFERENCE = '@@ESTIMATE/VALIDATE_CADASTRAL_REFERENCE';
export const GET_CADASTRE_INFO = '@@ESTIMATE/GET_CADASTRE_INFO';
export const VALIDATE_ADDRESS = '@@ESTIMATE/VALIDATE_ADDRESS';
export const SAVE_REFERRAL = '@@ESTIMATE/SAVE_REFERRAL';
export const GET_FAST_POLICY = '@@ESTIMATE/GET_FAST_POLICY';
export const GET_PET_BREEDS = '@@ESTIMATE/GET_PET_BREEDS';
export const RESET_SUGGESTIONS = '@@ESTIMATE/RESET_SUGGESTIONS';
export const RESET_MUNICIPALITIES = '@@ESTIMATE/RESET_MUNICIPALITIES';
export const SET_PERSONAL_INFO = '@@ESTIMATE/SET_PERSONAL_INFO';
export const CLEAN_ERROR = 'CLEAN_ERROR';

const homeProduct = PRODUCT_TYPE_LABELS[PRODUCT_TYPES.HOME];
const lifeProduct = PRODUCT_TYPE_LABELS[PRODUCT_TYPES.LIFE];

export const getAddressSuggestions = str => ({
  type: 'GET_ADDRESS_SUGGESTIONS',
  payload: {
    request: {
      method: 'GET',
      url: '/address/direction',
      params: { direction: str }
    }
  }
});

export const resetSuggestions = () => ({
  type: RESET_SUGGESTIONS
});

const getNextFastTrackStep = (quote, isAuthenticated) => {
  let product = homeProduct;
  let nextStep = PATHS.estimateSteps.home.confirmSecurity;
  if (quote.securities && !Object.values(quote.securities).includes(null)) {
    if (quote.isFlammable === FLAMMABLE.NOT) {
      product = null;
      nextStep = isAuthenticated
        ? PATHS.estimateSteps.confirmCustomer
        : PATHS.estimateSteps.personalInformation;
    } else {
      nextStep = PATHS.estimateSteps.home.confirmIsFlammable;
    }
  }
  const nextPath =
    product === null ? PATHS.setEstimate(nextStep) : PATHS.setEstimate(nextStep, product);
  return nextPath;
};

export const onValidateAddress =
  (params, isFastTrack = false) =>
  async (dispatch, getState) => {
    const action = await dispatch(validateAddress(params));
    if (action.type === `${VALIDATE_ADDRESS}${SUCCESS}`) {
      const { home: quote } = getState().estimate.quote;
      const {
        number,
        floor = '',
        letter = '',
        block = null,
        stairway = null,
        fullAddress: { cadastralRef, streetCode }
      } = quote;
      const { isAuthenticated } = getState().access;

      if (cadastralRef && streetCode && number) {
        const cadastreParams = { parcela: cadastralRef, cod_via: streetCode, numero_via: number };
        const actionCadastre = await dispatch(getCadastreInfo(cadastreParams));
        if (actionCadastre.type === `${GET_CADASTRE_INFO}${SUCCESS}`) {
          if (isFastTrack) {
            const cadastre = actionCadastre?.payload?.data?.results || [];
            let nextPath = PATHS.setEstimate(PATHS.estimateSteps.home.addressNotFound, homeProduct);
            if (cadastre.length > 0) {
              const house = cadastre.find(
                ({ piso, puerta, bloque, escalera }) =>
                  piso === floor &&
                  puerta === letter &&
                  (bloque === block || (!bloque && !block)) &&
                  (escalera === stairway || (!escalera && !stairway))
              );
              if (house) {
                nextPath = getNextFastTrackStep(quote, isAuthenticated);
                const topFloor = Math.max.apply(
                  Math,
                  cadastre.map(({ piso }) => piso)
                );
                dispatch(
                  saveHomeQuoteData({
                    cadastralRef: house.referenciaCatastral,
                    area: (house.supUtil || house.supConstruida).toString(),
                    constructionYear: house.annoConstruccion,
                    housingType: quote.housingType || getFloorType(floor, topFloor)
                  })
                );
              } else {
                quote.housingType && dispatch(saveHomeQuoteData({ housingType: null }));
                nextPath = PATHS.setEstimate(PATHS.estimateSteps.home.confirmAddress, homeProduct);
              }
            }

            history.push(nextPath + window.location.search);
            return;
          }
          // el housingType se setea en el confirmAddress
          quote.housingType && dispatch(saveHomeQuoteData({ housingType: null }));
          history.push(
            PATHS.setEstimate(PATHS.estimateSteps.home.confirmAddress, homeProduct) +
              window.location.search
          );
          return;
        }
      }
    }
    history.push(
      PATHS.setEstimate(PATHS.estimateSteps.home.addressNotFound, homeProduct) +
        window.location.search
    );
  };

export const validateAddress = params => ({
  type: VALIDATE_ADDRESS,
  payload: {
    request: {
      method: 'GET',
      url: '/address/validation',
      params
    }
  }
});

export const getCadastreInfo = params => ({
  type: GET_CADASTRE_INFO,
  payload: {
    request: {
      method: 'GET',
      url: '/address/catastro',
      params
    }
  }
});

export const onValidateCadastralReference =
  (ref, isFastTrack = false) =>
  async (dispatch, getState) => {
    const action = await dispatch(validateCadastralReference(ref));
    let nextPath = PATHS.setEstimate(PATHS.estimateSteps.home.addressNotFound, homeProduct);
    if (action.type === `${VALIDATE_CADASTRAL_REFERENCE}${SUCCESS}`) {
      if (isFastTrack) {
        const { home: quote } = getState().estimate.quote;
        const { isAuthenticated } = getState().access;
        nextPath = getNextFastTrackStep(quote, isAuthenticated);
      } else {
        nextPath = PATHS.setEstimate(PATHS.estimateSteps.home.confirmSecurity, homeProduct);
      }
    }
    history.push(nextPath + window.location.search);
  };

export const validateCadastralReference = ref => ({
  type: VALIDATE_CADASTRAL_REFERENCE,
  payload: {
    request: {
      method: 'GET',
      url: `/address/catastro/${ref}`
    }
  }
});

export const onCreateUser = (info, language) => async dispatch => {
  return dispatch(createUser(getCreateUserData(info, language))).then(async action => {
    if (action.type === `${CREATE_USER}${SUCCESS}`) {
      await dispatch(updateUserPhone(action.payload.data.phone));
      await dispatch(identifyUser(action.payload.data));
      return true;
    }
  });
};

export const createUser = data => ({
  type: CREATE_USER,
  payload: {
    request: {
      method: 'POST',
      url: '/v2/users/signup',
      data
    }
  }
});

export const onCreateCustomer = customerInfo => async dispatch => {
  const payload = getCreateCustomerData(customerInfo);

  return dispatch(createCustomer(payload)).then(async action => {
    if (action.type === `${CREATE_CUSTOMER}${SUCCESS}`) {
      dispatch(addCustomer(action.payload.data));
      return action.payload?.data?.id;
    }
  });
};

export const createCustomer = data => ({
  type: CREATE_CUSTOMER,
  payload: {
    request: {
      method: 'POST',
      url: '/v5/customer',
      data
    }
  }
});

export const saveQuoteData = (payload, productType) => ({
  type: SAVE_QUOTE_DATA,
  payload,
  productType
});

export const saveHomeQuoteData = payload =>
  saveQuoteData(payload, PRODUCT_TYPE_LABELS[PRODUCT_TYPES.HOME]);

export const setFastTrack = payload => ({
  type: SET_FAST_TRACK,
  payload
});

export const setProvinces = payload => ({
  type: SET_PROVINCES,
  payload
});

export const getProvinces = () => ({
  type: GET_PROVINCES,
  payload: {
    request: {
      method: 'GET',
      url: '/population/v2/provinces'
    }
  }
});

export const getPopulationByPostalCode = postalCode => async dispatch => {
  const action = await dispatch({
    type: GET_POPULATION_BY_POSTAL_CODE,
    payload: {
      request: {
        method: 'GET',
        url: '/population/v2/municipalitiesbyPostalCode',
        params: { postal_code: postalCode }
      }
    }
  });

  return action.type === `${GET_POPULATION_BY_POSTAL_CODE}${SUCCESS}`;
};

export const setMunicipalities = payload => ({
  type: SET_MUNICIPALITIES,
  payload
});

export const getMunicipalities = (province, municipality) => ({
  type: GET_MUNICIPALITIES,
  payload: {
    request: {
      method: 'GET',
      url: '/population/v2/municipalities',
      params: { cod_prov: province, substr: municipality }
    }
  }
});

export const resetMunicipalities = () => ({
  type: RESET_MUNICIPALITIES
});

export const setProducts = payload => ({
  type: SET_PRODUCTS,
  payload
});

export const setCurrentProduct = payload => ({
  type: SET_CURRENT_PRODUCT,
  payload
});

export const updateCadastreOptionsStairway = block => ({
  type: UPDATE_CADASTRE_OPTIONS_STAIRWAY,
  payload: {
    block
  }
});

export const updateCadastreOptionsFloor = (block, stairway) => ({
  type: UPDATE_CADASTRE_OPTIONS_FLOOR,
  payload: {
    block,
    stairway
  }
});

export const updateCadastreOptionsLetter = (block, stairway, floor) => ({
  type: UPDATE_CADASTRE_OPTIONS_LETTER,
  payload: {
    block,
    stairway,
    floor
  }
});

export const saveReferral = (referral, campaigns) => async dispatch => {
  let auxReferral = false;
  if (referral) {
    auxReferral = referral.split('=');
    auxReferral.length !== 2 && (auxReferral = false);
  }
  dispatch({
    type: SAVE_REFERRAL,
    payload: {
      id: auxReferral ? auxReferral[1] : null,
      type: auxReferral ? auxReferral[0] : null,
      campaigns: campaigns ? campaigns.split(';') : null
    }
  });
};

export const getFastPolicy = id => async (dispatch, getState) => {
  const action = await dispatch({
    type: GET_FAST_POLICY,
    payload: {
      request: {
        method: 'GET',
        url: `/v4/policy/fastpolicy/${id}`
      },
      data: id
    }
  });
  if (action.type === `${GET_FAST_POLICY}${SUCCESS}`) {
    const quote = getState().estimate.quote.home;
    let newAddress = {};
    if (action.payload?.data?.postal_code) {
      const success = await dispatch(getPopulationByPostalCode(action.payload?.data?.postal_code));
      if (success) {
        newAddress = extractPopulationAddress(getState().estimate.population);
      }
    } else if (action.payload?.data?.province) {
      const provinces = await dispatch(getProvinces());
      if (provinces?.payload?.data) {
        const province = provinces?.payload?.data.find(
          prov => prov?.cod_province === action.payload?.data?.province
        );
        if (province?.province) {
          newAddress.province = province.province;
        }
      }
    }
    if (newAddress) {
      dispatch(
        saveHomeQuoteData({
          ...quote,
          fullAddress: { ...quote.fullAddress, ...newAddress }
        })
      );
    }
    return true;
  }
  return false;
};

export function canRetryCreateProposal(error, payload = {}, params = {}) {
  if (params?.retry === true) {
    return false;
  }
  switch (error?.response?.data?.sub_status) {
    case ERROR_SUBCODES.PREMIUM_BELOW_MINIMUM:
    case ERROR_SUBCODES.MONTHLY_DISABLED_BACK:
    case ERROR_SUBCODES.MONTHLY_DISABLED_JARVIS:
      return payload?.interval === PAYMENT_PERIODS.MONTHLY;
    default:
      return false;
  }
}

export const onCreateProposal = () => async (dispatch, getState) => {
  const { quote, referral, fastPolicy } = getState().estimate;
  const { user, customers } = getState().access;
  const customerId =
    quote.product === lifeProduct && user.mainCustomerId ? user.mainCustomerId : quote?.customerId;

  const customer = customers.find(({ id }) => id === customerId);
  const agency = getState().agency;
  const payload = {
    ...getCreateProposalData(customer, quote, fastPolicy, customers),
    by_fast_track: !!fastPolicy
  };
  const params = {
    agency_id: agency?.id || null,
    agent_id: agency?.agent || null,
    link_parameter: referral.id ? `${referral.type}=${referral.id}` : null
  };

  let action = await dispatch(createProposal(payload, params));
  if (
    action.type === `${POST_PROPOSAL_DATA}${FAIL}` &&
    canRetryCreateProposal(action?.error, payload)
  ) {
    switch (action.error.response.data.sub_status) {
      case ERROR_SUBCODES.PREMIUM_BELOW_MINIMUM:
      case ERROR_SUBCODES.MONTHLY_DISABLED_BACK:
      case ERROR_SUBCODES.MONTHLY_DISABLED_JARVIS:
        action = await dispatch(
          createProposal(
            { ...payload, interval: PAYMENT_PERIODS.ANNUAL },
            { ...params, retry: true }
          )
        );
        break;
      default:
        break;
    }
  }

  if (action.type === `${POST_PROPOSAL_DATA}${SUCCESS}`) {
    const policy = action.payload.data;
    if (policy.period === PAYMENT_PERIODS.MONTHLY && policy.price < MINIMUM_MONTHLY_PAYMENT) {
      await dispatch(updateProposalInterval(policy.id, PAYMENT_PERIODS.ANNUAL));
      policy.period = PAYMENT_PERIODS.ANNUAL;
    }

    dispatch(updateTags(policy.user?.tags));
    sendAdtractionRequest(
      policy.id,
      policy.price,
      policy.home_ownership,
      policy.status,
      policy.period
    );
    return policy;
  } else {
    return false;
  }
};

export const createProposal = (data, params) => ({
  type: POST_PROPOSAL_DATA,
  payload: {
    request: {
      method: 'POST',
      url: '/v4/proposal',
      data,
      params
    }
  }
});

export const updateProposalInterval = (proposalId, interval) => ({
  type: UPDATE_PROPOSAL_INTERVAL,
  payload: {
    request: {
      method: 'PUT',
      url: `/v4/proposal/${proposalId}`,
      data: { interval }
    }
  }
});

export const getPetBreeds = type => ({
  type: GET_PET_BREEDS,
  payload: {
    request: {
      method: 'GET',
      url: '/v1/pets/breeds',
      params: { type }
    }
  }
});

export const resetState = () => ({
  type: RESET_STATE
});

export const cleanError = () => ({
  type: CLEAN_ERROR,
  payload: null
});

export const savePersonalInfo = payload => ({
  type: SET_PERSONAL_INFO,
  payload
});
