import { FAIL, SUCCESS } from '../../actions/app';
import {
  CLEAN_ERROR,
  GET_VIRTUAL_VET_CREDENTIALS,
  MEETING_DOCTORS_INIT_ERROR
} from '../actions/virtualVet';

export const initialState = {
  isLoading: false,
  error: null,
  credentials: {
    jwt: null,
    session_token: null,
    user_hash: null
  }
};

const reducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case GET_VIRTUAL_VET_CREDENTIALS:
      return {
        ...state,
        isLoading: true
      };
    case `${GET_VIRTUAL_VET_CREDENTIALS}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        credentials: payload.data,
        error: null
      };
    case `${GET_VIRTUAL_VET_CREDENTIALS}${FAIL}`:
      return {
        ...state,
        isLoading: false,
        error
      };
    case CLEAN_ERROR:
      return {
        ...state,
        error: null
      };
    case MEETING_DOCTORS_INIT_ERROR:
      return {
        ...state,
        error: true
      };
    default:
      return state;
  }
};

export default reducer;
