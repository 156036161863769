import { CUSTOMER_TYPE } from '../../constants';

export const getUserInfo = (data, currentInfo) => {
  const mainCustomer = data.customers.find(customer => customer.main_customer) || {};

  return {
    id: data.id,
    uuid: data.uuid,
    jarvisUserId: data.jarvis_user_id,
    role: data.role,
    phone: data.phone,
    impersonate: data.impersonate,
    ngo: data.ngo,
    lang: data.lang,
    firstName: data.firstname,
    lastName: data.lastname,
    created_date: data.created_at,
    email: data.email,
    birthdate: mainCustomer.person.birth_date,
    documentType: data.document_type,
    documentNumber: data.document_number,
    pendingSpentReferred: data.pending_spent_referred,
    tags: data.tags,
    mainCustomerId: data.main_customer,
    customerId: currentInfo?.customerId || data.main_customer
  };
};

export const getUserInfoFromCustomer = customer => ({
  phone: customer.phone,
  firstName: customer.person.first_name,
  lastName: customer.person.last_name,
  email: customer.email,
  birthdate: customer.person.birth_date,
  documentType: customer.person.document_person_type,
  documentNumber: customer.document_number
});

export const getCustomerInfo = customer => ({
  id: customer.id,
  customerType: customer.person ? CUSTOMER_TYPE.PERSON : CUSTOMER_TYPE.COMPANY,
  firstName: customer.person?.first_name,
  lastName: customer.person?.last_name,
  companyName: customer.company?.company_name,
  companyType: customer.company?.company_type,
  birthdate: customer.person?.birth_date,
  documentType: customer.person
    ? customer.person.document_person_type
    : customer.company.document_company_type,
  documentNumber: customer.document_number && customer.document_number.replace(' ', ''),
  email: customer.email,
  phone: customer.phone,
  address: customer.address,
  source: customer.source,
  mainCustomer: customer.main_customer
});

export const getCustomersInfo = data => {
  return data.map(customer => getCustomerInfo(customer));
};
