import { PRODUCT_TYPES } from '../constants';

export const getAvailableProductList = list => {
  const finalList = list.map(item => {
    const productName = PRODUCT_TYPES[item.toUpperCase()];
    const packName = item.split('-').map(p => PRODUCT_TYPES[p.toUpperCase()]);
    return productName || (packName.every(p => p !== undefined) ? packName : undefined);
  });
  return finalList.every(i => i !== undefined) && finalList;
};
