const colors = {
  base: '#1CC6D0', // Fluio
  baseLight: '#77DDE3', // Fluio_light
  baseLighter: '#BAEEF1', // Fluio_lighter
  baseLightest: '#E8F9FA', // Fluio_lightest
  secondary: '#2E566C', // Bluio
  secondaryLight: '#829AA7', // Bluio_light
  secondaryLighter: '#C0CCD3', // Bluio_lighter
  secondaryLightest: '#D9D9D9', // Bluio_lightest
  error: '#FF817E', // Piggio
  errorLight: '#FFB3B2', // Piggio_light
  errorLigther: '#FFD9D8', // Piggio_lighter
  errorLightest: '#FFF2F2', // Piggio_lightest
  errorText: '#D73E3B',
  gray1: '#f1fcfc',
  gray2: '#E4F4F5',
  gray3: '#ADBCC7',
  gray4: '#DBE5EB',
  gray5: '#008293',
  gray6: '#C4C4C4',
  gray7: '#EAEEF0',
  gray8: '#C4C4C480',
  gray9: '#D5DDE2',
  gray10: '#F1F1F3',
  gray11: '#878787',
  gray12: '#697885',
  text: '#2E566C',
  active: '#ECF4E4',
  activeDark: '#8ED16F',
  activeText: '#409200',
  black: '#102635',
  white: '#FFFFFF'
};

const shapes = {
  borderRadiusLarge: '6px',
  borderRadiusMedium: '4px',
  borderRadiusSmall: '2px',
  borderRadiusFull: '50%',
  borderWidthMedium: '2px',
  borderWidthSmall: '1px'
};

const spacings = {
  small1: '4px',
  small2: '8px',
  small3: '16px',
  small4: '24px',
  medium1: '32px',
  medium2: '40px',
  medium3: '48px',
  medium4: '56px',
  medium5: '64px',
  large1: '80px',
  large2: '96px',
  large3: '128px',
  large4: '200px'
};

const fontCallback = 'Arial, sans-serif';

const breakpoints = {
  small: '768px',
  medium: '1024px',
  large: '1536px',
  extralarge: '1920px'
};

const global = {
  maxDesktopWidth: '340px',
  maxContainerWidth: '1320px',
  maxInnerContainerWidth: '500px',
  bgColor: colors.gray1,
  bgColorHeader: colors.white,
  fontColor: colors.text,
  fontFamily: `"Poppins", ${fontCallback}`,
  fontSize: '14px',
  desktopFontSize: '16px',
  lineHeight: '1.5',
  transitionM: 'all 250ms ease-in-out',
  transitionS: 'all 120ms ease-in-out',
  boxShadowM: '0px 8px 24px rgba(54, 60, 75, 0.05)',
  boxShadowS: '0px 1px 1px rgba(46, 86, 108, 0.15), 0px 1px 2px rgba(46, 86, 108, 0.15)',
  boxShadow2S: '0px 1px 2px rgba(0, 0, 0, 0.25)',
  fontWeightRegular: 300,
  fontWeightNormal: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600
};

const containers = {
  main: {
    maxWidth: '1320px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: `0 ${spacings.small4} ${spacings.small4} ${spacings.small4}`,
    [`@media (min-width: ${breakpoints.small})`]: {
      padding: `0 ${spacings.medium1} ${spacings.medium1} ${spacings.medium1}`
    }
  },
  fullHeight: {
    minHeight: `calc(var(--viewport-height) - var(--header-height) - env(safe-area-inset-top) - ${spacings.small4})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [`@media (min-width: ${breakpoints.small})`]: {
      minHeight: 'unset',
      justifyContent: 'flex-start'
    }
  },
  actions: {
    margin: `${spacings.medium2} 0 calc(${spacings.small2} + 57px)`,
    [`@media (min-width: ${breakpoints.small})`]: {
      margin: `${spacings.medium4} 0`
    }
  },
  actionsDouble: {
    margin: `${spacings.medium2} 0 0 0`,
    'button:first-child': {
      marginBottom: spacings.small2
    },
    [`@media (min-width: ${breakpoints.small})`]: {
      margin: `${spacings.medium4} 0 ${spacings.medium2} 0`
    }
  },
  actionsModal: {
    margin: `${spacings.medium1} ${spacings.small3} 0`
  }
};

const headings = {
  h1: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightBold,
    fontSize: '30px',
    lineHeight: global.lineHeight,
    marginBottom: spacings.small3,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '36px',
      lineHeight: '40px',
      marginBottom: spacings.small3
    }
  },
  h2: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightMedium,
    fontSize: '24px',
    lineHeight: global.lineHeight,
    marginBottom: spacings.small3,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '30px',
      lineHeight: global.lineHeight,
      marginBottom: spacings.small3
    }
  },
  h3: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightMedium,
    fontSize: '20px',
    lineHeight: global.lineHeight,
    marginBottom: spacings.small3,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '24px',
      lineHeight: global.lineHeight,
      marginBottom: spacings.small3
    }
  },
  h4: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightMedium,
    fontSize: '18px',
    lineHeight: global.lineHeight,
    marginBottom: spacings.small3,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '20px',
      lineHeight: global.lineHeight,
      marginBottom: spacings.small3
    }
  },
  h5: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightMedium,
    fontSize: '16px',
    lineHeight: global.lineHeight,
    marginBottom: spacings.small3,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '18px',
      lineHeight: global.lineHeight,
      marginBottom: spacings.small3
    }
  }
};

const texts = {
  p1: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightRegular,
    fontSize: global.fontSize,
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: global.desktopFontSize
    }
  },
  p1n: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightNormal,
    fontSize: global.fontSize,
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: global.desktopFontSize
    }
  },
  p1m: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightMedium,
    fontSize: global.fontSize,
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: global.desktopFontSize
    }
  },
  p1b: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightBold,
    fontSize: global.fontSize,
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: global.desktopFontSize
    }
  },
  p2: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightRegular,
    fontSize: '13px',
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '15px'
    }
  },
  p2n: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightNormal,
    fontSize: '13px',
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '15px'
    }
  },
  p2m: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightMedium,
    fontSize: '13px',
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '15px'
    }
  },
  p2b: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightBold,
    fontSize: '13px',
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '15px'
    }
  },
  p3: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightRegular,
    fontSize: '12px',
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '14px'
    }
  },
  p3n: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightNormal,
    fontSize: '12px',
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '14px'
    }
  },
  p3m: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightMedium,
    fontSize: '12px',
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '14px'
    }
  },
  p3b: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightBold,
    fontSize: '12px',
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '14px'
    }
  },
  p4: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightRegular,
    fontSize: '11px',
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '12px'
    }
  },
  p4n: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightNormal,
    fontSize: '11px',
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '12px'
    }
  },
  p4m: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightMedium,
    fontSize: '11px',
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '12px'
    }
  },
  p4b: {
    fontFamily: global.fontFamily,
    fontWeight: global.fontWeightBold,
    fontSize: '11px',
    color: colors.text,
    lineHeight: global.lineHeight,
    [`@media (min-width: ${breakpoints.small})`]: {
      fontSize: '12px'
    }
  }
};

const buttons = {
  primary: {
    color: 'white',
    background: colors.base,
    backgroundHover: colors.text,
    borderColor: 'transparent'
  },
  secondary: {
    color: colors.base,
    background: 'transparent',
    borderColor: 'transparent'
  }
};

const theme = {
  breakpoints,
  colors,
  global,
  containers,
  spacings,
  shapes,
  headings,
  texts,
  buttons
};

export default theme;
