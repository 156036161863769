import EN from './EN.json';
import ES from './ES.json';
import CA from './CA.json';
import { getLocalStorage } from '../utils';

export const translations = {
  en: EN,
  es: ES,
  ca: CA
};

export const isLanguageSupported = lang => (lang in translations ? true : false);

export function getLanguage() {
  const defaultLang = getLocalStorage('ulang') || navigator.language.split(/[-_]/)[0];
  return isLanguageSupported(defaultLang) ? defaultLang : 'es';
}

export const locale = getLanguage();

export function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }
    return messages;
  }, {});
}
