import { ERROR_SUBCODES } from '../constants';

export const getErrorMessage = error => {
  const info = {
    code: error?.response?.data?.status || error?.response?.status || null,
    subcode: error?.response?.data?.sub_status || null,
    message: { id: 'common.error_unhandled' }
  };
  if (
    error?.response?.data?.sub_status &&
    error?.response?.data?.sub_status === ERROR_SUBCODES.PREMIUM_BELOW_MINIMUM
  ) {
    info.message = { id: 'errors.not_insurable.low_premium' };
  } else if (
    error?.response?.data?.sub_status &&
    error?.response?.data?.sub_status === ERROR_SUBCODES.FLAMMABLE_MATERIALS
  ) {
    info.message = { id: 'errors.home.flammable_materials' };
  } else if (
    error?.response?.data?.sub_status &&
    error?.response?.data?.sub_status === ERROR_SUBCODES.POPULATION_NOT_FOUND
  ) {
    info.message = { id: 'errors.home.population_not_found' };
  } else if (
    error?.response?.data?.sub_status &&
    (error?.response?.data?.sub_status === ERROR_SUBCODES.MONTHLY_DISABLED_BACK ||
      error?.response?.data?.sub_status === ERROR_SUBCODES.MONTHLY_DISABLED_JARVIS)
  ) {
    info.message = { id: 'errors.checkout.monthly_payment_disabled' };
  } else if (error?.response?.data?.message) {
    info.message = error.response.data.message;
  } else if (typeof error?.response?.data === 'string' && error?.response?.data !== '') {
    info.message = error.response.data;
  }

  return info;
};
