import { FAIL, RESET, SUCCESS } from '../../actions/app';
import {
  FLAMMABLE,
  USES_DESCRIPTION,
  STATUS_CODE,
  SECURITY,
  POLICY_TYPE,
  PRODUCT_TYPE_LABELS,
  FLAMMABLE_MATERIALS,
  PRODUCT_TYPES,
  PAYMENT_PERIODS,
  CUSTOMER_TYPE
} from '../../constants';
import { getErrorMessage } from '../../utils/getErrorMessage';
import {
  UPDATE_CADASTRE_OPTIONS_STAIRWAY,
  UPDATE_CADASTRE_OPTIONS_FLOOR,
  UPDATE_CADASTRE_OPTIONS_LETTER,
  VALIDATE_CADASTRAL_REFERENCE,
  SAVE_QUOTE_DATA,
  SET_FAST_TRACK,
  POST_PROPOSAL_DATA,
  UPDATE_PROPOSAL_INTERVAL,
  RESET_STATE,
  CLEAN_ERROR,
  SAVE_REFERRAL,
  GET_FAST_POLICY,
  CREATE_CUSTOMER,
  CREATE_USER,
  GET_POPULATION_BY_POSTAL_CODE,
  SET_CURRENT_PRODUCT,
  SET_PROVINCES,
  SET_MUNICIPALITIES,
  SET_PERSONAL_INFO,
  SET_PRODUCTS,
  VALIDATE_ADDRESS,
  GET_CADASTRE_INFO,
  RESET_SUGGESTIONS,
  RESET_MUNICIPALITIES,
  GET_MUNICIPALITIES,
  GET_PROVINCES,
  GET_PET_BREEDS,
  canRetryCreateProposal
} from '../actions/estimate';
import { getFloorType } from '../../utils';
import {
  getFastPolicy,
  getFullAddress,
  getPopulation,
  removeAddressesWithInvalidCodVia
} from './apiIntern';

export const initialState = {
  suggestions: [],
  provinces: [],
  municipalities: [],
  population: [],
  provincesFromPostalCode: [],
  municipalitiesFromPostalCode: [],
  isLoading: false,
  error: null,
  cadastre: null,
  quote: {},
  fastTrack: null,
  referral: {},
  personalInfo: {},
  fastPolicy: null,
  products: [],
  currentProduct: null
};

const reducer = (state = initialState, { type, payload, productType, error, ...action }) => {
  switch (type) {
    case RESET_SUGGESTIONS:
      return {
        ...state,
        suggestions: []
      };
    case RESET_MUNICIPALITIES:
      return {
        ...state,
        municipalities: []
      };
    case SET_PROVINCES:
      return {
        ...state,
        provincesFromPostalCode: payload
      };
    case SET_MUNICIPALITIES:
      return {
        ...state,
        municipalitiesFromPostalCode: payload
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: payload,
        currentProduct: payload[0]
      };
    case SET_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: payload
      };
    case SAVE_QUOTE_DATA:
      return {
        ...state,
        quote: productType
          ? {
              ...state.quote,
              [productType]: {
                ...state.quote[productType],
                ...payload
              }
            }
          : {
              ...state.quote,
              ...payload
            }
      };
    case SET_PERSONAL_INFO:
      return {
        ...state,
        personalInfo: payload
      };
    case SET_FAST_TRACK:
      return {
        ...state,
        fastTrack: {
          ...state.fastTrack,
          ...payload
        }
      };
    case GET_FAST_POLICY:
      return {
        ...state,
        isLoading: true,
        fastPolicy: {
          id: payload.data,
          isLoading: true
        }
      };
    case 'GET_ADDRESS_SUGGESTIONS':
    case VALIDATE_ADDRESS:
    case GET_CADASTRE_INFO:
    case GET_PROVINCES:
    case GET_PET_BREEDS:
    case GET_MUNICIPALITIES:
    case GET_POPULATION_BY_POSTAL_CODE:
    case VALIDATE_CADASTRAL_REFERENCE:
    case CREATE_CUSTOMER:
    case CREATE_USER:
    case POST_PROPOSAL_DATA:
    case UPDATE_PROPOSAL_INTERVAL:
      return {
        ...state,
        isLoading: true
      };
    case `${CREATE_CUSTOMER}${SUCCESS}`:
    case `${CREATE_USER}${SUCCESS}`:
      return {
        ...state,
        userAlreadyExists: payload.data.exists,
        isLoading: false
      };
    case `${POST_PROPOSAL_DATA}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        quote: {},
        personalInfo: {},
        fastTrack: null,
        fastPolicy: null
      };
    case `${UPDATE_PROPOSAL_INTERVAL}${SUCCESS}`:
      return {
        ...state,
        isLoading: false
      };
    case 'GET_ADDRESS_SUGGESTIONS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        suggestions: removeAddressesWithInvalidCodVia(payload.data.results)
      };
    case `${VALIDATE_ADDRESS}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        quote: {
          ...state.quote,
          home: {
            ...state.quote?.home,
            fullAddress: {
              ...state.quote?.home?.fullAddress,
              ...getFullAddress(payload.data.results[0])
            }
          }
        }
      };
    case `${GET_CADASTRE_INFO}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        cadastre: {
          results: payload.data.results,
          optionsBlock: getOptions('bloque', payload.data.results),
          optionsStairway: getOptions('escalera', payload.data.results),
          optionsFloor: getOptions('piso', payload.data.results),
          optionsLetter: getOptions('puerta', payload.data.results)
        }
      };
    case `${GET_PROVINCES}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        provinces: payload.data
      };
    case `${GET_MUNICIPALITIES}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        municipalities: payload.data
      };
    case `${GET_POPULATION_BY_POSTAL_CODE}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        population: getPopulation(payload.data),
        provincesFromPostalCode: [],
        municipalitiesFromPostalCode: []
      };
    case `${GET_FAST_POLICY}${SUCCESS}`:
      let personalInfo = null;
      if (payload?.data?.customer) {
        personalInfo = {
          customerType: CUSTOMER_TYPE.PERSON,
          firstName: payload?.data?.customer?.first_name || null,
          lastName: payload?.data?.customer?.last_name || null,
          email: payload?.data?.customer?.email || null,
          phone: payload?.data?.customer?.phone || null,
          birthdate: payload?.data?.customer?.birth_date || null,
          documentType: payload?.data?.customer?.document_type || null,
          documentNumber: payload?.data?.customer?.document_number || null
        };
      }
      const quote = getFastPolicyQuote(payload.data, state.quote);
      return {
        ...state,
        isLoading: false,
        quote,
        fastPolicy: {
          ...getFastPolicy(payload.data),
          use: quote.home?.use
        },
        personalInfo
      };
    case `${GET_PET_BREEDS}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        breeds: {
          ...state.breeds,
          [payload.config.params.type]: payload.data
        }
      };
    case `${VALIDATE_CADASTRAL_REFERENCE}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        quote: {
          ...state.quote,
          home: {
            ...state.quote?.home,
            fullAddress: {
              ...state.quote?.home?.fullAddress,
              ...getFullAddress(payload.data.validation)
            },
            number: payload.data.validation.numeroVia,
            block: payload.data.catastro.bloque,
            stairway: payload.data.catastro.escalera,
            floor: payload.data.catastro.piso,
            letter: payload.data.catastro.puerta,
            constructionYear: payload.data.catastro.annoConstruccion,
            area: payload.data.catastro.supUtil,
            housingType: payload.data.home_type || getFloorType(payload.data.catastro.piso || '0')
          }
        }
      };
    case UPDATE_CADASTRE_OPTIONS_STAIRWAY:
      return {
        ...state,
        cadastre: {
          ...state.cadastre,
          optionsStairway: getOptions(
            'escalera',
            getOptionsByBlock(state.cadastre.results, payload.block)
          )
        }
      };
    case UPDATE_CADASTRE_OPTIONS_FLOOR:
      return {
        ...state,
        cadastre: {
          ...state.cadastre,
          optionsFloor: getOptions(
            'piso',
            getOptionsByBlockAndStairway(state.cadastre.results, payload.block, payload.stairway)
          )
        }
      };
    case UPDATE_CADASTRE_OPTIONS_LETTER:
      return {
        ...state,
        cadastre: {
          ...state.cadastre,
          optionsLetter: getOptions(
            'puerta',
            getOptionsByBlockStairwayAndFloor(
              state.cadastre.results,
              payload.block,
              payload.stairway,
              payload.floor
            )
          )
        }
      };
    case SAVE_REFERRAL:
      return {
        ...state,
        referral: {
          id: payload.id,
          type: payload.type,
          campaigns: payload.campaigns
        }
      };
    case `${GET_PROVINCES}${FAIL}`:
    case `${GET_MUNICIPALITIES}${FAIL}`:
    case `${GET_POPULATION_BY_POSTAL_CODE}${FAIL}`:
    case `${GET_PET_BREEDS}${FAIL}`:
    case `${CREATE_CUSTOMER}${FAIL}`:
    case `${CREATE_USER}${FAIL}`:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(error)
      };
    case `${POST_PROPOSAL_DATA}${FAIL}`:
      const prevRequest = action?.meta?.previousAction?.payload?.request || {};
      return {
        ...state,
        isLoading: false,
        error: canRetryCreateProposal(error, prevRequest?.data, prevRequest?.params)
          ? null
          : getErrorMessage(error)
      };
    case `${UPDATE_PROPOSAL_INTERVAL}${FAIL}`:
      return {
        ...state,
        isLoading: false
      };
    case `${VALIDATE_ADDRESS}${FAIL}`:
    case `${GET_CADASTRE_INFO}${FAIL}`:
    case 'GET_ADDRESS_SUGGESTIONS_FAIL':
    case `${VALIDATE_CADASTRAL_REFERENCE}${FAIL}`:
      return {
        ...state,
        isLoading: false
      };
    case `${GET_FAST_POLICY}${FAIL}`:
      const errorMessage = getErrorMessage(error);
      return {
        ...state,
        isLoading: false,
        fastPolicy: null,
        error:
          errorMessage.code === STATUS_CODE.NOT_FOUND
            ? { ...errorMessage, message: { id: 'estimate.fast_policy_not_found' } }
            : errorMessage
      };
    case CLEAN_ERROR:
      return {
        ...state,
        error: null
      };
    case RESET_STATE:
      return {
        ...initialState,
        referral: state.referral || null
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

const getFastPolicyQuote = (data, initialData) => {
  if (!data) {
    return {};
  }

  const product = Object.values(PRODUCT_TYPE_LABELS).find(product => data[product.toLowerCase()]);
  const policyInfo = Object.values(data[product]).find(option => option);

  const houseUse = Object.keys(USES_DESCRIPTION).find(
    use => USES_DESCRIPTION[use].ownership === policyInfo?.home_ownership
  );

  const securities = Object.keys(SECURITY).reduce((obj, measure) => {
    const auxKey = measure.toLowerCase();
    if (
      policyInfo?.securities &&
      typeof policyInfo?.securities === 'object' &&
      policyInfo.securities[auxKey] !== null
    ) {
      obj[SECURITY[measure]] = policyInfo.securities[auxKey];
    } else {
      obj[SECURITY[measure]] = null;
    }
    return obj;
  }, {});

  const isFlammable =
    policyInfo?.construction_material === FLAMMABLE_MATERIALS[FLAMMABLE.YES]
      ? FLAMMABLE.YES
      : FLAMMABLE.NOT;

  return {
    // If the quote already has an interval it means it's a quote from an agency
    // with a default payment frequency and we don't want to override that interval
    product: PRODUCT_TYPE_LABELS[PRODUCT_TYPES.HOME],
    home: {
      interval: initialData?.interval || data?.interval || PAYMENT_PERIODS.MONTHLY,
      price: data?.price || null,
      use: initialData?.home?.use || houseUse || null,
      isFlammable: initialData?.home?.isFlammable || isFlammable || null,
      housingType: initialData?.home?.housingType || policyInfo?.housing_type || null,
      securities: initialData?.home?.securities || securities,
      constructionYear:
        initialData?.home?.constructionYear || policyInfo?.construction_year || null,
      fullAddress: {
        postalCode:
          initialData?.home?.fullAddress?.postalCode || policyInfo?.address?.postal_code || null,
        provCode:
          initialData?.home?.fullAddress?.provCode || policyInfo?.address?.province_code || null,
        province: initialData?.home?.fullAddress?.province || policyInfo?.address?.province || null,
        munCode:
          initialData?.home?.fullAddress?.munCode || policyInfo?.address?.municipality_code || null,
        municipality:
          initialData?.home?.fullAddress?.municipality || policyInfo?.address?.municipality || null,
        street: initialData?.home?.fullAddress?.street || policyInfo?.address?.street || null,
        streetType:
          initialData?.home?.fullAddress?.streetType || policyInfo?.address?.street_type || null,
        latitude: initialData?.home?.fullAddress?.latitude || policyInfo?.address?.latitude || null,
        longitude:
          initialData?.home?.fullAddress?.longitude || policyInfo?.address?.longitude || null
      },
      letter: initialData?.home?.letter || policyInfo?.address?.apartment || null,
      block: initialData?.home?.block || policyInfo?.address?.building || null,
      floor: initialData?.home?.floor || policyInfo?.address?.floor_number || null,
      cadastralRef: initialData?.home?.cadastralRef || policyInfo?.address?.cadastre_ref || null,
      stairway: initialData?.home?.stairway || policyInfo?.address?.stairway || null,
      number: initialData?.home?.number || policyInfo?.address?.number || null,
      policyType: POLICY_TYPE.IPTIQ,
      area: initialData?.home?.area || policyInfo?.surface || null,
      capitalContent: policyInfo?.capital_content || null,
      capitalContinent: policyInfo?.capital_continent || null,
      civilLiability: policyInfo?.civil_liability || null,
      covers:
        initialData?.home?.covers ||
        data.cover_groups.reduce((acc, cover) => (cover.active ? [...acc, cover.code] : acc), [])
    }
  };
};

const getOptionsByBlock = (data, block) => data.filter(item => !block || item.bloque === block);

const getOptionsByBlockAndStairway = (data, block, stairway) =>
  data.filter(
    item => (!block || item.bloque === block) && (!stairway || item.escalera === stairway)
  );

const getOptionsByBlockStairwayAndFloor = (data, block, stairway, floor) =>
  data.filter(
    item =>
      (!block || item.bloque === block) &&
      (!stairway || item.escalera === stairway) &&
      (!floor || item.piso === floor)
  );

const getOptions = (key, data) =>
  data
    .filter(item => item[key])
    .map(d => ({ value: d[key], text: d[key] }))
    .filter(
      (value, index, self) =>
        index === self.findIndex(t => t.value === value.value && t.text === value.text)
    );

export default reducer;
